import { AnimatePresence, motion } from "framer-motion";
import { defaultTo, includes } from "ramda";
import React, { useContext } from "react";
import { joinStrings } from "../../utils/util-methods";
import { AccordionContext } from "./Accordion";
import { AccordionItemContext } from "./AccordionItem";

const animationTime = 0.3;

export const AccordionBody = ({ children }) => {
  const { activeItems, allowAllOpen } = useContext(AccordionContext);
  const { name } = useContext(AccordionItemContext);
  return (
    <AnimatePresence>
      {(allowAllOpen
        ? includes(name, defaultTo([], activeItems))
        : activeItems === name) && (
        <motion.div
          animate={{
            height: "auto",
            opacity: 1,
            marginBottom: "1rem",
            transition: { duration: animationTime },
          }}
          initial={{ height: "0", opacity: 0, marginBottom: "0rem" }}
          exit={{
            height: "0rem",
            marginBottom: "0rem",
            transition: {
              delay: animationTime,
              duration: animationTime,
            },
          }}
          className={joinStrings(
            "shadow-md overflow-hidden",
            "transition-shadow duration-300",
            "hover:shadow-xl"
          )}
        >
          <motion.div
            animate={{
              opacity: 1,
              transition: {
                duration: animationTime,
                delay: animationTime,
              },
            }}
            initial={{
              opacity: 0,
            }}
            exit={{
              opacity: 0,
              transition: { duration: animationTime },
            }}
            className="p-5"
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
