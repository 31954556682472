import { useFormik } from "formik";
import React, { useContext, useRef, useState } from "react";
import { RiLockPasswordFill, RiLockPasswordLine } from "react-icons/ri";
import * as yup from "yup";
import { PrimaryButton } from "../components/PrimaryButton";
import { Recaptcha } from "../components/Recaptcha";
import { SecondaryButton } from "../components/SecondaryButton";
import { ShadowInput } from "../components/ShadowInput";
import { LoggedInContext } from "../contexts/LoggedInContext";
import { ToastContext } from "../contexts/ToastContext";
import { mutation1, query1 } from "../services/graphqlService";
import { ToastType } from "../utils/enums/ToastType";
import { joinStrings } from "../utils/util-methods";

export const ResetPassword = ({ match, history }) => {
  //state
  const [loading, setLoading] = useState(false);
  const forgotPasswordCode = match?.params?.code;
  const [isRecaptchaAccepted, setIsRecaptchaAccepted] = useState(false);
  const recaptchaRef = useRef(null);

  //context
  const { addToast } = useContext(ToastContext);
  const { isLoggedIn, logout } = useContext(LoggedInContext);

  const resetPasswordForm = useFormik({
    initialValues: {
      newPassword: "",
      newPasswordConfirm: "",
    },
    validationSchema: yup.object({
      newPassword: yup
        .string()
        .required("Your new password is required")
        .min(8, "Your new password must be at least 8 characters long."),
      newPasswordConfirm: yup
        .string()
        .required("Your new password confirmation is required")
        .test(
          "passwords-match",
          "Your new passwords must match",
          function (newPasswordConfirm) {
            return newPasswordConfirm === this.parent.newPassword;
          }
        ),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const {
          data: { userByForgotPasswordCode },
        } = await query1(
          `userByForgotPasswordCode(code: "${forgotPasswordCode}")`
        );

        if (userByForgotPasswordCode === "Success") {
          const {
            data: { resetPassword },
          } = await mutation1(
            `resetPassword(code: "${forgotPasswordCode}", newPassword: "${values.newPassword}")`
          );

          if (resetPassword === "Success") {
            addToast(
              ToastType.Success,
              "Your password has been reset. Please try logging in again."
            );
            if (isLoggedIn) {
              logout();
            }
            setLoading(false);
            history.push("/");
          }
        } else if (userByForgotPasswordCode === "Failure") {
          addToast(
            ToastType.Error,
            "You have not requested a reset to your password"
          );
          setLoading(false);
          setIsRecaptchaAccepted(false);
          recaptchaRef.current.reset();
        }
      } catch (err) {
        addToast(ToastType.Error, "Something went wrong");
        setLoading(false);
        setIsRecaptchaAccepted(false);
        recaptchaRef.current.reset();
      }
    },
  });

  return (
    <div className="my-24 max-w-3xl mx-auto">
      <div
        className={joinStrings(
          "flex flex-col space-y-10 justify-center items-center shadow-md px-10 py-14 bg-white rounded-lg",
          "transition-shadow duration-300",
          "hover:shadow-2xl"
        )}
      >
        <div className="flex flex-col w-full space-y-1">
          <h2 className="text-cyan-900 font-semibold">Reset your password</h2>
          <p className="text-gray-400">
            Type in your new password and you'll be able to log back in within
            no time
          </p>
        </div>

        <div className="flex flex-col space-y-3 w-full">
          <ShadowInput
            id="newPassword"
            name="newPassword"
            placeHolder="New Password"
            onChange={resetPasswordForm.handleChange}
            onBlur={resetPasswordForm.handleBlur}
            error={resetPasswordForm.errors.newPassword}
            value={resetPasswordForm.values.newPassword}
            touched={resetPasswordForm.touched.newPassword}
            icon={<RiLockPasswordLine className="text-2xl text-gray-400" />}
            type="password"
          />
          <ShadowInput
            id="newPasswordConfirm"
            name="newPasswordConfirm"
            placeHolder="Confirm New Password"
            onChange={resetPasswordForm.handleChange}
            onBlur={resetPasswordForm.handleBlur}
            error={resetPasswordForm.errors.newPasswordConfirm}
            value={resetPasswordForm.values.newPasswordConfirm}
            touched={resetPasswordForm.touched.newPasswordConfirm}
            icon={<RiLockPasswordFill className="text-2xl text-gray-400" />}
            type="password"
          />
        </div>

        <div
          className={joinStrings(
            "flex flex-col justify-between items-end space-y-5 w-full",
            "sm:flex-row sm:space-y-0"
          )}
        >
          <Recaptcha
            ref={recaptchaRef}
            onChange={(val) => {
              if (!!val) {
                setIsRecaptchaAccepted(true);
              } else {
                setIsRecaptchaAccepted(false);
              }
            }}
            onError={() => addToast(ToastType.Error, "Something went wrong")}
          />

          <div className={joinStrings("flex space-x-3 justify-end items-end")}>
            <SecondaryButton onClick={() => history.push("/")}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              onClick={resetPasswordForm.handleSubmit}
              loading={loading}
              disabled={loading || !isRecaptchaAccepted}
            >
              Submit
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};
