import { path } from "ramda";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { query1 } from "../services/graphqlService";
import {
  getDayOfTheWeek,
  getEnvVariable,
  joinStrings,
} from "../utils/util-methods";
import { AdvertiseCard } from "./AdvertiseCard";
import { ContactUs } from "./ContactUs";
import { FeatureCard1 } from "./FeatureCard1";
import { PrimaryButton } from "./PrimaryButton";
import { UndrawAdvertise } from "./Undraw/UndrawAdvertise";
import { UndrawLaptop } from "./Undraw/UndrawLaptop";
import { UndrawResume } from "./Undraw/UndrawResume";
import { UndrawWebsiteSetup } from "./Undraw/UndrawWebsiteSetup";
import { UndrawWireframing } from "./Undraw/UndrawWireframing";

export const NewHome = ({ history }) => {
  const [loadingBusinesses, setLoadingBusinesses] = useState(false);
  const [businesses, setBusinesses] = useState([{}, {}, {}, {}]);

  const findRandomBusinesses = useCallback(async () => {
    setLoadingBusinesses(true);
    try {
      // console.log('day: ', getDayOfTheWeek());
      const {
        data: { businesses },
      } = await query1(
        `businesses(paginate: {take: 4, skip: 0}) {
          limit
          skip
          total
          data {
            id
            name
            phoneNumber
            images(find: {type: Logo}) {
              url
            }
            operatingTimes(find: {day: ${getDayOfTheWeek()}}) {
              openingTime
              closingTime
            }
            categories {
              name
            }
            avgRating
          }
      }`
      );
      setBusinesses(businesses.data);
    } catch (err) {
    } finally {
      setLoadingBusinesses(false);
    }
  }, []);

  useEffect(() => {
    // window.scrollTo(0, 0);
    findRandomBusinesses();
  }, [findRandomBusinesses]);

  return (
    <>
      <div className={joinStrings("py-10 bg-white")}>
        <div
          className={joinStrings(
            "container mx-auto flex flex-col px-5 rounded-lg items-center",
            "lg:px-10"
          )}
        >
          <h1
            className={joinStrings(
              " font-bold text-cyan-900 text-center",
              "md:text-center"
            )}
          >
            Welcome to syncvine
          </h1>
          <h6
            className={joinStrings(
              "text-gray-500 max-w-xl mt-1 text-center",
              "md:text-center md:mt-5"
            )}
          >
            Our goal is to give local businesses the opportunity to have a
            digital and online platform.
          </h6>
          <PrimaryButton
            className="mt-6 px-3 py-2.5 space-x-3"
            onClick={() => history.push("/advertise")}
          >
            <span>Advertise</span>
            <BsArrowRight className="text-xl" />
          </PrimaryButton>
          <UndrawAdvertise
            className={joinStrings(
              "h-auto mt-14 w-11/12 mx-auto",
              "sm:w-2/3",
              "lg:w-2/5"
            )}
          />
        </div>
      </div>

      <div className="container mx-auto py-20">
        <div className="flex justify-center">
          <h2 className=" px-5 pb-2 font-bold text-cyan-900">
            Featured Businesses
          </h2>
        </div>
        <div
          className={joinStrings(
            "grid grid-row-4 grid-cols-1 gap-10 mt-16",
            "md:grid-cols-2 md:grid-rows-2 md:mt-10"
          )}
        >
          {businesses?.map(
            (
              {
                id,
                name,
                images,
                categories,
                avgRating,
                phoneNumber,
                operatingTimes,
              },
              i
            ) => (
              <Fragment key={"feature-card-" + i}>
                {i % 2 === 0 && (
                  <div
                    className={joinStrings(
                      "flex justify-center items-center",
                      "md:justify-end"
                    )}
                  >
                    <FeatureCard1
                      businessId={id}
                      name={name}
                      phoneNumber={phoneNumber}
                      logo={path([0], images)?.url}
                      categories={categories}
                      operatingTimes={operatingTimes ?? []}
                      rating={Math.round(avgRating)}
                      loading={loadingBusinesses}
                    />
                  </div>
                )}

                {i % 2 !== 0 && (
                  <div
                    className={joinStrings(
                      "flex justify-center items-center",
                      "md:justify-start"
                    )}
                  >
                    <FeatureCard1
                      businessId={id}
                      name={name}
                      phoneNumber={phoneNumber}
                      logo={path([0], images)?.url}
                      categories={categories}
                      operatingTimes={operatingTimes ?? []}
                      rating={Math.round(avgRating)}
                      loading={loadingBusinesses}
                    />
                  </div>
                )}
              </Fragment>
            )
          )}
        </div>
      </div>

      {/* <div
        className={`py-20 bg-gradient-to-l from-blue-600 to-blue-50 via-blue-50 mt-10`}
      >
        <div className="px-4 container mx-auto">
          <motion.div
            animate={{
              translateX: 0,
            }}
            initial={{
              translateX: 500,
            }}
            className="flex flex-col-reverse xl:space-x-20 xl:flex-row xl:items-start items-center"
          >
            <div className="flex flex-col xl:pl-10 sm:px-10 px-5 items-start self-center xl:mt-0 mt-20 flex-1">
              <div className="md:text-4xl text-3xl font-bold text-blue-900 mx-auto xl:mx-0 text-center xl:text-left dark:text-white">
                Advertise through us today
              </div>
              <div className="md:text-base text-base font-poppins text-center xl:text-left mt-1 text-gray-600">
                syncvine was created with the inspiration to let local
                businesses gain an online presence during times that place an
                emphasis on online marketing and having a digital platform
              </div>
              <button className="px-5 py-3 bg-blue-900 text-white focus:outline-none flex space-x-3 mx-auto xl:mx-0 rounded mt-10">
                <div>Advertise</div>
                <motion.div whileHover={{ translateX: 5 }}>&rarr;</motion.div>
              </button>
            </div>

            <UndrawAdvertise className="h-auto w-11/12 sm:w-2/3 md:w-1/2 lg:w-1/3 flex-1" />
          </motion.div>
        </div>
      </div> */}

      <div className="py-32 bg-white">
        <div className="container mx-auto">
          <div
            className={joinStrings(
              "flex flex-col items-center space-x-0 space-y-10",
              "lg:flex-row lg:justify-center lg:space-x-20 lg:space-y-0"
            )}
          >
            <UndrawLaptop className="h-auto w-11/12 sm:w-2/3 lg:w-1/3" />

            <div className="p-10 rounded-lg">
              <h2 className="font-bold text-cyan-900 text-center">
                Getting started?
              </h2>
              <p className="mb-6 text-gray-500 mt-2 text-center">
                Follow these steps and you're good to go.
              </p>
              <ul className="space-y-10">
                <li
                  className={joinStrings(
                    "flex space-x-0 items-center space-x-5",
                    "md:flex-row md:space-x-5"
                  )}
                >
                  <span className="flex justify-center items-center px-3 py-2 text-xs font-semibold bg-blue-200 text-cyan-900 rounded w-min">
                    1
                  </span>
                  <span className="">Select one of our advertising plans</span>
                </li>
                <li
                  className={joinStrings(
                    "flex space-x-0 items-center space-x-5",
                    "md:flex-row md:space-x-5"
                  )}
                >
                  <span className="flex justify-center items-center px-3 py-2 text-xs font-semibold bg-blue-200 text-cyan-900 rounded w-min">
                    2
                  </span>
                  <span className="">Fill in your business information</span>
                </li>
                <li
                  className={joinStrings(
                    "flex space-x-0 items-center space-x-5",
                    "md:flex-row md:space-x-5"
                  )}
                >
                  <span className="flex justify-center items-center px-3 py-2 text-xs font-semibold bg-blue-200 text-cyan-900 rounded w-min">
                    3
                  </span>
                  <span className="">Checkout and view your business</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="h-0.5 bg-gray-200 w-full"></div>

      {/* deals */}
      <div className={joinStrings("bg-white py-10", "md:py-32")}>
        <div className="container mx-auto">
          <h1 className="mx-auto w-max font-bold text-cyan-900">
            Check out our deals
          </h1>
          <div
            className={joinStrings(
              "grid grid-cols-1 gap-y-10 mt-16",
              "md:grid-cols-2",
              "xl:grid-cols-3"
            )}
          >
            <div className="flex justify-center">
              <AdvertiseCard
                heading="Bronze Plan"
                monthly="/month"
                cost={getEnvVariable("BRONZE_AMOUNT")}
                packageId="1"
                undraw={
                  <UndrawWebsiteSetup className="w-full h-auto max-h-40 mx-auto" />
                }
              />
            </div>

            <div className="flex justify-center">
              <AdvertiseCard
                heading="Silver Plan"
                monthly="/3 months"
                cost={getEnvVariable("SILVER_AMOUNT")}
                packageId="2"
                undraw={
                  <UndrawResume className="w-full h-auto max-h-40 mx-auto" />
                }
              />
            </div>

            <div
              className={joinStrings(
                "flex justify-center col-span-1",
                "md:col-span-2",
                "xl:col-span-1"
              )}
            >
              <AdvertiseCard
                heading="Gold Plan"
                monthly="/6 months"
                cost={getEnvVariable("GOLD_AMOUNT")}
                packageId="3"
                undraw={
                  <UndrawWireframing
                    className={joinStrings("w-full h-auto max-h-40 mx-auto")}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="h-0.5 bg-gray-200 w-full"></div>

      <div className="py-32">
        <div
          className={joinStrings(
            "container mx-auto flex justify-center px-5",
            "md:px-0"
          )}
        >
          <ContactUs />
        </div>
      </div>
    </>
  );
};
