import React from "react";
import { joinStrings } from "../../utils/util-methods";

export const ProfileBusinessCardOption = ({ children, className, onClick }) => {
  return (
    <button
      className={joinStrings(
        "flex items-center space-x-3 rounded-md py-2 px-3 text-gray-700 outline-none",
        "transition-all duration-300",
        "hover:shadow-lg",
        "focus:outline-none",
        className
      )}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
};
