import { motion } from "framer-motion";
import React from "react";
import myImg from "../media/Capture.PNG";

const icons = [";", ";", ";", ";"];
export const About = () => {
  return (
    <motion.div
      animate={{ translateY: 0 }}
      initial={{ translateY: 200 }}
      className="mt-32 mx-auto py-28 flex flex-col items-center space-y-16 max-w-7xl bg-gradient-to-b from-blue-200 to-white"
    >
      <div className="font-bold text-5xl text-blue-900 text-center max-w-3xl">
        How GraphQL and REST API can work together?
      </div>
      <div className="flex flex-col-reverse xl:flex-row xl:items-start xl:bg-transparent bg-white rounded-3xl shadow-md xl:shadow-none">
        <div className="flex flex-row space-x-10 justify-center xl:space-x-0 xl:flex-col sticky bottom-0 xl:space-y-10 xl:top-40 transform xl:-translate-x-24 pb-10 xl:w-0 xl:mt-10">
          {icons.map((x, i) => (
            <div
              key={"icon " + i}
              className="rounded-full bg-white py-2 px-5 shadow-xl cursor-pointer transform duration-500 hover:bg-purple-400 hover:scale-125 w-0"
            >
              {x}
            </div>
          ))}
        </div>
        <div className="flex flex-col bg-white rounded-3xl xl:shadow-md p-16 space-y-10 content-center max-w-3xl">
          <div className="flex space-x-5 items-center border-b border-gray-200 pb-7">
            <img
              className="rounded-full h-12 w-12"
              src={myImg}
              alt="banana"
            ></img>
            <div className="text-purple-500 flex-grow">Nicholas Trummer</div>
          </div>
          <div className="text-gray-500 text-lg font-poppins leading-9 text-justify">
            For some years now, the APIs are an integral part of the landscape
            in the developers' communities. During the React-Europe, a new
            challenger called GraphQL, appeared, two years ago. And I am almost
            certain that you have already heard about it. In this post, I will
            try to explain how GraphQL could be a game changer for fetching your
            data and why the API are necessary more than ever.
          </div>
          <img
            src="https://d2zv2ciw0ln4h1.cloudfront.net/uploads/export_graphql-api-3.png_448408a7a1.png"
            className="h-full w-4/5 mx-auto"
            alt={"banana"}
          />
          <div className="text-gray-500 text-lg font-poppins leading-9 text-justify">
            For some years now, the APIs are an integral part of the landscape
            in the developers' communities. During the React-Europe, a new
            challenger called GraphQL, appeared, two years ago. And I am almost
            certain that you have already heard about it. In this post, I will
            try to explain how GraphQL could be a game changer for fetching your
            data and why the API are necessary more than ever.
          </div>
        </div>
      </div>
    </motion.div>
  );
};
