import React, { forwardRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { getEnvVariable } from "../utils/util-methods";

export const Recaptcha = forwardRef(({ onChange, onError }, ref) => (
  <ReCAPTCHA
    ref={ref}
    sitekey={getEnvVariable("GCP_RECAPTCHA_KEY")}
    onChange={onChange}
    onErrored={() => {
      if (!!onError) {
        onError();
      }
    }}
  />
));
