import React, { useContext } from "react";
import { getEnvVariable, joinStrings } from "../utils/util-methods";
import { AdvertiseCard } from "./AdvertiseCard";
import { StepperContext } from "./Stepper/StepperContext";
import { UndrawResume } from "./Undraw/UndrawResume";
import { UndrawWebsiteSetup } from "./Undraw/UndrawWebsiteSetup";
import { UndrawWireframing } from "./Undraw/UndrawWireframing";

export const PackageSelect = () => {
  const { setActiveStep, setState } = useContext(StepperContext);
  return (
    <div
      className={joinStrings(
        "bg-white shadow-2xl rounded-lg p-3",
        "sm:px-20 sm:py-16"
      )}
    >
      <div className={joinStrings("rounded-xl p-3", "md:p-10")}>
        <div className="flex justify-center">
          <h2 className="text-cyan-900 font-semibold">Important</h2>
        </div>

        <ul className="flex flex-col space-y-6 list-disc px-10 mt-10 text-gray-600">
          <li>
            Syncvine was created to help businesses establish their name and
            brand to a wider audience of customers inside the Randpark Ridge
            community.
          </li>
          <li>
            The advertising on the Randpark Ridge Community facebook group must
            be carried out in personal capacity and must follow the posting
            guidelines.
          </li>
          <li>
            If you are a business located outside of Randburg but offer
            goods/services to residents in Randburg then you are also allowed to
            post on the facebook group.
          </li>
          <li>
            You are personally responsible for cancelling your subscription.
          </li>
          <li>
            Billing occurs on the 1<sup>st</sup> of every month regardless of
            when you purchase your business listing.
          </li>
        </ul>
      </div>

      <div
        className={joinStrings(
          "grid grid-cols-1 gap-y-10 mt-16",
          "lg:grid-cols-2",
          "xl:grid-cols-3"
        )}
      >
        <div className="flex justify-center">
          <AdvertiseCard
            heading="Bronze Plan"
            monthly="/month"
            cost={getEnvVariable("BRONZE_AMOUNT")}
            packageId="1"
            undraw={
              <UndrawWebsiteSetup className="w-full h-auto max-h-40 mx-auto" />
            }
            shouldTranslate={false}
            buttonText="Select"
            onClick={() => {
              setState({ subscriptionPlan: 1 });
              setActiveStep(1);
            }}
          />
        </div>

        <div className="flex justify-center">
          <AdvertiseCard
            heading="Silver Plan"
            monthly="/3 months"
            cost={getEnvVariable("SILVER_AMOUNT")}
            packageId="2"
            undraw={<UndrawResume className="w-full h-auto max-h-40 mx-auto" />}
            shouldTranslate={false}
            buttonText="Select"
            onClick={() => {
              setState({ subscriptionPlan: 2 });
              setActiveStep(1);
            }}
          />
        </div>

        <div
          className={joinStrings(
            "flex justify-center col-span-1",
            "lg:col-span-2",
            "xl:col-span-1"
          )}
        >
          <AdvertiseCard
            heading="Gold Plan"
            monthly="/6 months"
            cost={getEnvVariable("GOLD_AMOUNT")}
            packageId="3"
            undraw={
              <UndrawWireframing
                className={joinStrings("w-full h-auto max-h-40 mx-auto")}
              />
            }
            shouldTranslate={false}
            buttonText="Select"
            onClick={() => {
              setState({ subscriptionPlan: 3 });
              setActiveStep(1);
            }}
          />
        </div>
      </div>
    </div>
  );
};
