import { useFormik } from "formik";
import React, { useContext, useRef, useState } from "react";
import * as yup from "yup";
import { Message } from "../components/Message";
import { PrimaryButton } from "../components/PrimaryButton";
import { Recaptcha } from "../components/Recaptcha";
import { SecondaryButton } from "../components/SecondaryButton";
import { ShadowInput } from "../components/ShadowInput";
import { ToastContext } from "../contexts/ToastContext";
import { mutation1, query1 } from "../services/graphqlService";
import { MessageType } from "../utils/enums/MessageType";
import { ToastMessage } from "../utils/enums/ToastMessage";
import { ToastType } from "../utils/enums/ToastType";
import { joinStrings } from "../utils/util-methods";

export const ForgotPassword = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useContext(ToastContext);
  const [incorrectEmail, setIncorrectEmail] = useState(false);
  // const [
  //   isNotAllowedToResetPassword,
  //   setIsNotAllowedToResetPassword,
  // ] = useState(false);
  const [isRecaptchaAccepted, setIsRecaptchaAccepted] = useState(false);

  const recaptchaRef = useRef(null);

  const form = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .required("Your registered email is required")
        .email("Must be a legitimate email"),
    }),
    onSubmit: async ({ email }) => {
      setLoading(true);
      try {
        const {
          data: { userByEmail },
        } = await query1(`userByEmail(email: "${email}")`);

        if (userByEmail === "Success") {
          const {
            data: { sendForgotPassword },
          } = await mutation1(`sendForgotPassword(email: "${email}")`);
          if (sendForgotPassword === "Success") {
            addToast(
              ToastType.Success,
              "We have sent you an email with details on how to reset your password"
            );
            history.push("/");
          } else if (sendForgotPassword === "Not allowed") {
            addToast(
              ToastType.Error,
              "This email is not eligible for a password reset"
            );
            setIsRecaptchaAccepted(false);
            setLoading(false);
            recaptchaRef.current.reset();
          }
        } else if (userByEmail === "Failure") {
          setIncorrectEmail(true);
          setIsRecaptchaAccepted(false);
          setLoading(false);
          recaptchaRef.current.reset();
        }
      } catch (err) {
        addToast(ToastType.Error, ToastMessage.FAILURE);
        setIsRecaptchaAccepted(false);
        setLoading(false);
        recaptchaRef.current.reset();
      }
    },
  });

  return (
    <div className="my-24 max-w-3xl mx-auto">
      <div
        className={joinStrings(
          "flex flex-col space-y-10 justify-center items-center shadow-md px-10 py-14 bg-white rounded-lg",
          "transition-shadow duration-300",
          "hover:shadow-2xl"
        )}
      >
        <div className="flex flex-col w-full space-y-1">
          <h2 className="text-cyan-900 font-semibold">Forgot your password?</h2>
          <p className="text-gray-400">
            No worries, simply enter your registered email address and we'll
            send you a renewal link.
          </p>
        </div>

        <div className="w-full">
          {incorrectEmail && (
            <Message type={MessageType.Error} className="w-full">
              We couldn't find this email.
            </Message>
          )}

          <ShadowInput
            placeHolder="Email"
            id="email"
            name="email"
            type="email"
            value={form.values.email}
            onChange={(e) => {
              if (incorrectEmail) {
                setIncorrectEmail(false);
              }
              form.handleChange(e);
            }}
            onBlur={form.handleBlur}
            error={form.errors.email}
            touched={form.touched.email}
            className="w-full mt-3"
          />
        </div>

        <div
          className={joinStrings(
            "flex flex-col justify-between items-end space-y-5 w-full",
            "sm:flex-row sm:space-y-0"
          )}
        >
          <Recaptcha
            ref={recaptchaRef}
            onChange={(val) => {
              if (!!val) {
                setIsRecaptchaAccepted(true);
              } else {
                setIsRecaptchaAccepted(false);
              }
            }}
            onError={() => addToast(ToastType.Error, "Something went wrong")}
          />

          <div className={joinStrings("flex space-x-3 justify-end items-end")}>
            <SecondaryButton onClick={() => history.push("/")}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              onClick={form.handleSubmit}
              loading={loading}
              disabled={loading || !isRecaptchaAccepted}
            >
              Send Email
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};
