import React from "react";
import { BusinessSubscriptionPackage } from "../../../utils/enums/BusinessSubscriptionPackage";
import { getEnvVariable, joinStrings } from "../../../utils/util-methods";
import { CloseButton } from "../../CloseButton";
import { Modal } from "../../Modal";
import { SimpleAdvertiseCard } from "../../SimpleAdvertiseCard";
import { UndrawResume } from "../../Undraw/UndrawResume";
import { UndrawWebsiteSetup } from "../../Undraw/UndrawWebsiteSetup";
import { UndrawWireframing } from "../../Undraw/UndrawWireframing";

export const RenewSubscriptionModal = ({
  open,
  setOpen,
  businessId,
  userId,
  firstName,
  lastName,
  email,
  businessName,
}) => {
  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="bg-white rounded-xl">
        <div className="flex justify-center items-center h-20 border-b border-gray-200">
          <h2 className="text-cyan-900">Select a package</h2>
        </div>
        <div
          className={joinStrings(
            "grid grid-cols-1 gap-10 p-10",
            "lg:grid-cols-2",
            "xl:grid-cols-3"
          )}
        >
          <div className="flex justify-center w-full">
            <SimpleAdvertiseCard
              heading="Bronze Plan"
              monthly="/month"
              cost={getEnvVariable("BRONZE_AMOUNT")}
              package="1"
              undraw={
                <UndrawWebsiteSetup className="w-80 h-auto max-h-40 mx-auto mt-10" />
              }
              subscriptionPackage={BusinessSubscriptionPackage.BRONZE}
              businessId={businessId}
              userId={userId}
              firstName={firstName}
              lastName={lastName}
              email={email}
              businessName={businessName}
            />
          </div>
          <div className="flex justify-center">
            <SimpleAdvertiseCard
              heading="Silver Plan"
              monthly="/3 months"
              cost={getEnvVariable("SILVER_AMOUNT")}
              package="2"
              undraw={<UndrawResume className="w-80 max-h-40 mx-auto mt-10" />}
              subscriptionPackage={BusinessSubscriptionPackage.SILVER}
              businessId={businessId}
              userId={userId}
              firstName={firstName}
              lastName={lastName}
              email={email}
              businessName={businessName}
            />
          </div>

          <div
            className={joinStrings(
              "flex justify-center",
              "col-span-1",
              "lg:col-span-2",
              "xl:col-span-1"
            )}
          >
            <SimpleAdvertiseCard
              heading="Gold Plan"
              monthly="/6 months"
              cost={getEnvVariable("GOLD_AMOUNT")}
              package="3"
              undraw={
                <UndrawWireframing
                  className={"w-80 h-auto max-h-40 mx-auto mt-10"}
                />
              }
              subscriptionPackage={BusinessSubscriptionPackage.GOLD}
              businessId={businessId}
              userId={userId}
              firstName={firstName}
              lastName={lastName}
              email={email}
              businessName={businessName}
            />
          </div>
        </div>

        <div className="flex justify-end items-center h-20 px-10 space-x-3 border-t border-gray-200 mt-5">
          <CloseButton
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </CloseButton>
        </div>
      </div>
    </Modal>
  );
};
