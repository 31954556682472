import React from "react";
import { FaCheck } from "react-icons/fa";
import { joinStrings } from "../utils/util-methods";

export const FilterCheck = ({ toggled, text, onChange, name }) => {
  return (
    <div
      className="flex space-x-2 items-center w-max cursor-pointer select-none"
      onClick={() => onChange(name)}
    >
      <div
        className={joinStrings(
          "p-0.5 rounded border",
          toggled ? "border-cyan-900 bg-cyan-900" : "border-gray-400"
        )}
      >
        {toggled && <FaCheck className="text-xs text-white" />}
        {!toggled && <FaCheck className="text-xs text-white" />}
      </div>
      <span>{text}</span>
    </div>
  );
};
