/* eslint-disable no-useless-escape */
import {
  filter,
  flatten,
  forEach,
  includes,
  map,
  pipe,
  prop,
  propEq,
  replace,
} from "ramda";
import { BusinessImageType } from "../utils/enums/BusinessImageType";
import { getEnvVariable, indexedForEach } from "../utils/util-methods";
import { createAxios } from "./createAxios";

const graphqlController = createAxios(`${getEnvVariable("API_URL")}/graphql`);

export const query1 = async (arg) => {
  const requestOptions = `query {
      ${arg}
    }`;
  const { data } = await graphqlController.post("", {
    query: requestOptions,
  });
  return data;
};

export const mutation1 = async (arg) => {
  const requestOptions = `mutation {
    ${arg}
  }`;
  const { data } = await graphqlController.post("", { query: requestOptions });
  return data;
};

export const uploadImg = async (img) => {
  const formData = new FormData();
  formData.append(
    "operations",
    JSON.stringify({
      query: `mutation($img: Upload!) { uploadBusinessImg(img: $img) }`,
      variables: { img: null },
    })
  );
  formData.append("map", JSON.stringify({ 0: ["variables.img"] }));
  formData.append(
    "0",
    new File([img], `banana.${img.name.split(".")[1]}`, { type: img.type })
  );
  const { data } = await graphqlController.post("", formData);
  return data?.data;
};

export const createBusinessFromService = async (formValues, userId) => {
  const business = {
    name: formValues.businessName,
    email: formValues.businessEmail,
    phoneNumber: formValues.businessPhone,
    description: formValues.businessDescription,
    address: formValues.businessAddress,
    latitude: formValues.latitude,
    longitude: formValues.longitude,
    userId,
    categoryIds: map(prop("id"), formValues.categories),
    featureIds: map(prop("id"), formValues.features),
    operatingTimes: pipe(
      filter(propEq("isToggled", true)),
      map(({ day, operatingTimes }) =>
        map(
          ({ openingTime, closingTime }) => ({
            day,
            openingTime,
            closingTime,
          }),
          operatingTimes
        )
      ),
      flatten
    )(formValues.daysOfTheWeek),
    socialMedias: pipe(
      filter(({ isToggled }) => isToggled),
      map(({ website, platform }) => ({ website, platform }))
    )(formValues.socialMedias),
  };
  const { logo, cover, gallery } = formValues;

  const formData = new FormData();
  formData.append(
    "operations",
    JSON.stringify({
      query: `mutation($images: [Upload!]!) {
        createBusiness(data: {
          name: "${business.name}",
          email: "${business.email}",
          phoneNumber: "${business.phoneNumber}",
          description: """${replace(/"/g, "'", business.description)}""",
          address: "${business.address}",
          latitude: "${business.latitude}",
          longitude: "${business.longitude}",
          userId: ${business.userId},
          categoryIds: [${business.categoryIds}],
          featureIds: [${business.featureIds}],
          operatingTimes: [${business.operatingTimes.map(
            ({ openingTime, closingTime, day }) =>
              `{ openingTime: \"${openingTime}\", closingTime: \"${closingTime}\", day: ${day} }`
          )}],
          socialMedias: [${map(
            ({ website, platform }) =>
              `{ website: \"${website}\", platform: ${platform} }`,
            business.socialMedias
          )}]
          },
          images: $images) { id } }`,
      variables: { files: [null, null, ...map((_) => null, gallery)] },
    })
  );
  const mapObj = { 0: ["variables.images.0"], 1: ["variables.images.1"] };
  gallery.forEach((_, i) => (mapObj[i + 2] = [`variables.images.${i + 2}`]));

  formData.append("map", JSON.stringify(mapObj));

  const logoMimeType = logo.file.name.split(".")[1].toLowerCase();
  formData.append(
    "0",
    new File([logo.file], `logo.${logoMimeType}`, { type: logo.file.type })
  );

  const coverMimeType = cover.file.name.split(".")[1].toLowerCase();
  formData.append(
    "1",
    new File([cover.file], `cover.${coverMimeType}`, {
      type: cover.file.type,
    })
  );

  gallery.forEach((x, i) => {
    const galleryMimeType = x.file.name.split(".")[1].toLowerCase();
    formData.append(
      `${i + 2}`,
      new File([x.file], `gallery${i}.${galleryMimeType}`, {
        type: x.file.type,
      })
    );
  });

  // console.log("formData: ", [...formData]);

  const { data } = await graphqlController.post("", formData);
  return data;
  // return data?.data;
};

export const updateBusiness = async (formValues, businessToUpdate) => {
  const business = {
    name: formValues.businessName,
    email: formValues.businessEmail,
    phoneNumber: formValues.businessPhone,
    description: formValues.businessDescription,
    address: formValues.businessAddress,
    latitude: formValues.latitude,
    longitude: formValues.longitude,
    categoryIds: map(prop("id"), formValues.categories),
    featureIds: map(prop("id"), formValues.features),
    operatingTimes: pipe(
      filter(propEq("isToggled", true)),
      map(({ day, operatingTimes }) =>
        map(
          ({ openingTime, closingTime }) => ({
            day,
            openingTime,
            closingTime,
          }),
          operatingTimes
        )
      ),
      flatten
    )(formValues.daysOfTheWeek),
    socialMedias: pipe(
      filter(({ isToggled }) => isToggled),
      map(({ website, platform }) => ({ website, platform }))
    )(formValues.socialMedias),
    // removedGalleryImages: formValues
  };
  const { logo, cover, gallery } = formValues;

  let numberOfImages = 0;
  if (!!logo.file) {
    numberOfImages++;
  }
  if (!!cover.file) {
    numberOfImages++;
  }
  const filteredGallery = filter((x) => !!x.file, gallery);
  forEach((_) => numberOfImages++, filteredGallery);

  if (numberOfImages > 1 || numberOfImages === 1) {
    const formData = new FormData();
    formData.append(
      "operations",
      JSON.stringify({
        query: `mutation(${(() => {
          if (numberOfImages > 1) {
            return "$images: [Upload!]!";
          } else if (numberOfImages === 1) {
            return "$image: Upload!";
          }
        })()}) {
        updateBusiness(id: ${businessToUpdate.id},
          data: {
          name: "${business.name}",
          email: "${business.email}",
          phoneNumber: "${business.phoneNumber}",
          description: """${replace(/"/g, "'", business.description)}""",
          address: "${business.address}",
          latitude: "${business.latitude}",
          longitude: "${business.longitude}",
          categoryIds: [${business.categoryIds}],
          featureIds: [${business.featureIds}],
          operatingTimes: [${business.operatingTimes.map(
            ({ openingTime, closingTime, day }) =>
              `{ openingTime: \"${openingTime}\", closingTime: \"${closingTime}\", day: ${day} }`
          )}],
          socialMedias: [${map(
            ({ website, platform }) =>
              `{ website: \"${website}\", platform: ${platform} }`,
            business.socialMedias
          )}],
          removedGalleryImages: [${(() => {
            const galleryImages = pipe(
              filter(propEq("type", BusinessImageType.Gallery)),
              map((x) => ({ src: x.url, file: null }))
            )(businessToUpdate.images);

            const formGallerySrc = map(prop("src"), formValues.gallery);
            const removedGalleryImages = pipe(
              filter((x) => !includes(x.src, formGallerySrc)),
              map(({ src }) => `\"${src}\"`)
            )(galleryImages);
            return removedGalleryImages;
          })()}]
          }${(() => {
            if (numberOfImages > 1) {
              return ", images: $images";
            } else if (numberOfImages === 1) {
              return ", image: $image";
            }
          })()},
          ) { id } }`,
        variables: (() => {
          if (numberOfImages > 1) {
            let obj = { images: [] };
            if (!!logo.file) {
              obj.images.push(null);
            }
            if (!!cover.file) {
              obj.images.push(null);
            }
            obj.images.push(...map(() => null, filteredGallery));
            return obj;
          } else if (numberOfImages === 1) {
            let obj = { image: null };
            return obj;
          }
        })(),
      })
    );

    let mapObj = {};
    if (numberOfImages > 1) {
      let startIndex = 0;
      if (!!logo.file) {
        mapObj[startIndex] = [`variables.images.${startIndex}`];
        startIndex++;
      }
      if (!!cover.file) {
        mapObj[startIndex] = [`variables.images.${startIndex}`];
        startIndex++;
      }
      indexedForEach(
        (_, i) =>
          (mapObj[i + startIndex] = [`variables.images.${i + startIndex}`]),
        filteredGallery
      );
    } else if (numberOfImages === 1) {
      mapObj[0] = [`variables.image`];
    }

    formData.append("map", JSON.stringify(mapObj));

    if (numberOfImages > 1) {
      let startIndex = 0;
      if (!!logo.file) {
        const logoMimeType = logo.file.name.split(".")[1].toLowerCase();
        formData.append(
          `${startIndex}`,
          new File([logo.file], `logo.${logoMimeType}`, {
            type: logo.file.type,
          })
        );
        startIndex++;
      }
      if (!!cover.file) {
        const coverMimeType = cover.file.name.split(".")[1].toLowerCase();
        formData.append(
          `${startIndex}`,
          new File([cover.file], `cover.${coverMimeType}`, {
            type: cover.file.type,
          })
        );
        startIndex++;
      }
      indexedForEach((x, i) => {
        const galleryMimeType = x.file.name.split(".")[1].toLowerCase();
        formData.append(
          `${i + startIndex}`,
          new File([x.file], `gallery${i}.${galleryMimeType}`, {
            type: x.file.type,
          })
        );
      }, filteredGallery);
    } else if (numberOfImages === 1) {
      if (!!logo.file) {
        const logoMimeType = logo.file.name.split(".")[1].toLowerCase();
        formData.append(
          "0",
          new File([logo.file], `logo.${logoMimeType}`, {
            type: logo.file.type,
          })
        );
      }
      if (!!cover.file) {
        const coverMimeType = cover.file.name.split(".")[1].toLowerCase();
        formData.append(
          "0",
          new File([cover.file], `cover.${coverMimeType}`, {
            type: cover.file.type,
          })
        );
      }
      if (!!filteredGallery.length) {
        const galleryMimeType = filteredGallery[0].file.name
          .split(".")[1]
          .toLowerCase();
        formData.append(
          "0",
          new File([filteredGallery[0].file], `gallery0.${galleryMimeType}`, {
            type: filteredGallery[0].file.type,
          })
        );
      }
    }

    // console.log("formData: ", [...formData]);

    await graphqlController.post("", formData);
  } else {
    await mutation1(`updateBusiness(id: ${businessToUpdate.id},
      data: {
      name: "${business.name}",
      email: "${business.email}",
      phoneNumber: "${business.phoneNumber}",
      description: """${replace(/"/g, "'", business.description)}""",
      address: "${business.address}",
      latitude: "${business.latitude}",
      longitude: "${business.longitude}",
      categoryIds: [${business.categoryIds}],
      featureIds: [${business.featureIds}],
      operatingTimes: [${business.operatingTimes.map(
        ({ openingTime, closingTime, day }) =>
          `{ openingTime: "${openingTime}", closingTime: "${closingTime}", day: ${day} }`
      )}],
      socialMedias: [${map(
        ({ website, platform }) =>
          `{ website: "${website}", platform: ${platform} }`,
        business.socialMedias
      )}],
      removedGalleryImages: [${(() => {
        const galleryImages = pipe(
          filter(propEq("type", BusinessImageType.Gallery)),
          map((x) => ({ src: x.url, file: null }))
        )(businessToUpdate.images);

        const formGallerySrc = map(prop("src"), formValues.gallery);
        const removedGalleryImages = pipe(
          filter((x) => !includes(x.src, formGallerySrc)),
          map(({ src }) => `\"${src}\"`)
        )(galleryImages);
        return removedGalleryImages;
      })()}]
      }) {
        id
      }`);
  }
};
