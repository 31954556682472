import { assoc, defaultTo, length, map, pipe } from "ramda";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  HiOutlineMail,
  HiOutlineUserCircle,
  RiLockPasswordFill,
} from "react-icons/all";
import { LogoLoader } from "../components/LogoLoader";
import { Message } from "../components/Message";
import { EmailEditModal } from "../components/Profile/Modals/EmailEditModal";
import { FirstNameEditModal } from "../components/Profile/Modals/FirstNameEditModal";
import { LastNameEditModal } from "../components/Profile/Modals/LastNameEditModal";
import { PasswordEditModal } from "../components/Profile/Modals/PasswordEditModal";
import { mergeUser } from "../components/Profile/Profile.functions";
import { ProfileBusinessCard } from "../components/Profile/ProfileBusinessCard";
import { ProfileEdit } from "../components/Profile/ProfileEdit";
import { SecondaryButton } from "../components/SecondaryButton";
import { LoggedInContext } from "../contexts/LoggedInContext";
import { ToastContext } from "../contexts/ToastContext";
import { query1 } from "../services/graphqlService";
import { BusinessImageType } from "../utils/enums/BusinessImageType";
import { MessageType } from "../utils/enums/MessageType";
import { ToastMessage } from "../utils/enums/ToastMessage";
import { ToastType } from "../utils/enums/ToastType";
import { joinStrings } from "../utils/util-methods";

export const Profile = ({ match, history }) => {
  const userId = match.params.id;
  const [isFirstNameEditModalOpen, setIsFirstNameEditModalOpen] =
    useState(false);
  const [isLastNameEditModalOpen, setIsLastNameEditModalOpen] = useState(false);
  const [isEmailEditModalOpen, setIsEmailEditModalOpen] = useState(false);
  const [isPasswordEditModalOpen, setIsPasswordEditModalOpen] = useState(false);
  const [currentDate] = useState(new Date());

  const [user, setUser] = useState({
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    hasPassword: false,
    businesses: [],
  });
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingBusinesses, setLoadingBusinesses] = useState(false);
  const { isLoggedIn } = useContext(LoggedInContext);
  const { addToast } = useContext(ToastContext);

  const refreshBusinesses = useCallback(async () => {
    setLoadingBusinesses(true);
    try {
      const {
        data: { profile },
      } = await query1(`profile(id: ${userId}) {
        businesses(orderBy: {name: ASC}) {
            id
            name
            createdAt
            activatedAt
            activeUntil
            payfastToken
            images(find: { type: ${BusinessImageType.Logo} }) {
                url
            }
            subscriptionPackage
            hasConfirmedCancellation
            hasOpenCancellation
        }
      }`);

      // addToast(ToastType.Success)
      setUser((prev) => assoc("businesses", profile.businesses, prev));
    } catch (err) {
      addToast(ToastType.Error, ToastMessage.FAILURE);
    } finally {
      setLoadingBusinesses(false);
    }
  }, [addToast, userId]);

  const getProfile = useCallback(async () => {
    const userId = match?.params?.id;

    if (!userId) {
      return;
    }
    setLoadingUser(true);
    try {
      const {
        data: { profile },
      } = await query1(`profile(id: ${userId}) {
        firstName
        lastName
        email
        googleId
        facebookId
        hasPassword
        businesses(orderBy: {name: ASC}) {
            id
            name
            createdAt
            activatedAt
            activeUntil
            payfastToken
            images(find: { type: ${BusinessImageType.Logo} }) {
                url
            }
            subscriptionPackage
            hasConfirmedCancellation
            hasOpenCancellation
            isSpecial
        }
      }`);
      setUser(profile);
    } catch (err) {
      addToast(
        ToastType.Error,
        "Failed to load user. The server may be temporarily down. Please try again in a few minutes."
      );
    } finally {
      setLoadingUser(false);
    }
  }, [addToast, match?.params?.id]);

  useEffect(() => {
    if (!isLoggedIn) {
      history.push("/");
    } else {
      getProfile();
    }
  }, [isLoggedIn, history, getProfile]);

  return (
    <Fragment>
      {loadingUser && <LogoLoader className="h-128" />}
      {!loadingUser && (
        <>
          <FirstNameEditModal
            open={isFirstNameEditModalOpen}
            setOpen={setIsFirstNameEditModalOpen}
            userId={match?.params?.id}
            mergeUser={mergeUser(user, setUser)}
            firstName={user.firstName}
          />
          <LastNameEditModal
            open={isLastNameEditModalOpen}
            setOpen={setIsLastNameEditModalOpen}
            userId={match?.params?.id}
            mergeUser={mergeUser(user, setUser)}
            lastName={user.lastName}
          />
          <EmailEditModal
            open={isEmailEditModalOpen}
            setOpen={setIsEmailEditModalOpen}
            userId={match?.params?.id}
            mergeUser={mergeUser(user, setUser)}
          />
          <PasswordEditModal
            open={isPasswordEditModalOpen}
            setOpen={setIsPasswordEditModalOpen}
            userId={match?.params?.id}
            mergeUser={mergeUser(user, setUser)}
          />
          <div className="container mx-auto my-10">
            <div
              className={joinStrings(
                "flex flex-col space-y-10 rounded-lg p-5",
                "lg:p-20"
              )}
            >
              <div className="flex flex-col space-y-5">
                <h3 className="font-medium text-cyan-900">Details</h3>
                <div className="h-0.5 bg-gray-200 w-full"></div>
              </div>

              {/* Account info */}
              <div
                className={joinStrings(
                  "grid grid-cols-2 gap-y-10 rounded-lg p-16 min-w-full bg-white shadow-md",
                  // "lg:space-x-10",
                  "transition-shadow duration-300",
                  "hover:shadow-2xl"
                )}
              >
                <div
                  className={joinStrings(
                    "col-span-2 flex flex-col space-y-5 w-full",
                    "md:space-y-10"
                  )}
                >
                  <div
                    className={joinStrings(
                      "flex flex-col space-x-0 space-y-5",
                      "md:flex-row md:space-x-10 md:space-y-0"
                    )}
                  >
                    <div className="flex-grow">
                      <label className="text-gray-500">First Name:</label>
                      <ProfileEdit
                        text={user?.firstName}
                        onEditClick={setIsFirstNameEditModalOpen}
                        icon={
                          <HiOutlineUserCircle className="text-2xl text-gray-400" />
                        }
                      />
                    </div>

                    <div className="flex-grow">
                      <label className="text-gray-500">Last Name:</label>
                      <ProfileEdit
                        text={user?.lastName}
                        onEditClick={() => setIsLastNameEditModalOpen(true)}
                        icon={
                          <HiOutlineUserCircle className="text-2xl text-gray-400" />
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="col-span-2">
                  <label className="text-gray-500">Email:</label>
                  <ProfileEdit
                    text={user?.email}
                    onEditClick={() => setIsEmailEditModalOpen(true)}
                    icon={<HiOutlineMail className="text-2xl text-gray-400" />}
                    editable={false}
                  />
                </div>

                <div className="col-span-2 flex items-center w-full">
                  {user?.hasPassword && (
                    <SecondaryButton
                      onClick={() => setIsPasswordEditModalOpen(true)}
                    >
                      <RiLockPasswordFill />
                      <span>Change Password</span>
                    </SecondaryButton>
                  )}
                </div>
              </div>

              {/* Business Listings */}
              <div className="rounded-lg min-h-full w-full">
                <div className="flex flex-col space-y-5">
                  <h3 className="font-medium text-cyan-900">
                    Business Listings
                  </h3>
                  <div className="h-0.5 bg-gray-200 w-full"></div>
                </div>
                {!!length(user.businesses) && currentDate.getDate() === 1 && (
                  <Message type={MessageType.Warn} className="mt-2">
                    You will not be able to cancel a subscription or change a
                    payment plan today. We process all debit orders on the 1
                    <sup>st</sup> of every month.
                  </Message>
                )}
                {loadingBusinesses && <LogoLoader />}
                {!loadingBusinesses && (
                  <div
                    className={joinStrings(
                      "grid grid-cols-1 gap-5 mt-5",
                      "md:grid-cols-2"
                    )}
                  >
                    {!length(user.businesses) && (
                      <div className="h-32 flex">
                        <p>No business listings found.</p>
                      </div>
                    )}
                    {!!length(user.businesses) &&
                      pipe(
                        defaultTo([]),
                        map(
                          ({
                            id,
                            name,
                            createdAt,
                            images,
                            activatedAt,
                            payfastToken,
                            subscriptionPackage,
                            activeUntil,
                            hasConfirmedCancellation,
                            hasOpenCancellation,
                            isSpecial,
                          }) => (
                            <div
                              className="flex justify-center w-full"
                              key={`profile-business-card-${id}`}
                            >
                              <ProfileBusinessCard
                                businessId={id}
                                name={name}
                                createdAt={createdAt}
                                activatedAt={activatedAt}
                                image={images[0]?.url}
                                payfastToken={payfastToken}
                                userId={match?.params?.id}
                                firstName={user.firstName}
                                lastName={user.lastName}
                                email={user.email}
                                // user={user}
                                subscriptionPlan={subscriptionPackage}
                                refreshBusinesses={refreshBusinesses}
                                activeUntil={activeUntil}
                                hasConfirmedCancellation={
                                  hasConfirmedCancellation
                                }
                                hasOpenCancellation={hasOpenCancellation}
                                isSpecial={isSpecial}
                              />
                            </div>
                          )
                        )
                      )(user?.businesses)}
                  </div>
                )}
              </div>
              {/* </div> */}
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};
