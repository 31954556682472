import { assoc } from "ramda";
import React from "react";
import { indexedMap } from "../../utils/util-methods";
import { PaginationButton } from "./PaginationButton";

export const Pagination = ({
  limit,
  // onPageChange,
  total,
  loading,
  skip,
  setPaginated,
  fetch,
}) => {
  // const [skip, setSkip] = useState(0);
  return (
    <div className="flex">
      <PaginationButton
        className="rounded-l-lg border border-r-0"
        onClick={() => {
          // const newSkip = 0;
          setPaginated((prev) => {
            if (total !== 0 && prev.skip !== 0) {
              fetch(0);
              return assoc("skip", 0, prev);
            } else {
              return prev;
            }
          });
          // if (!!fetch) {
          //   fetch(newSkip);
          // }
        }}
        disabled={loading}
      >
        {"<<"}
      </PaginationButton>
      <PaginationButton
        className="border border-r-0 border-l-0"
        onClick={() =>
          setPaginated((prev) => {
            // const newSkip = prev.skip - 1 !== -1 ? prev.skip - 1 : 0;
            if (total !== 0) {
              if (prev.skip - 1 !== -1) {
                fetch(prev.skip - 1);
                return assoc("skip", prev.skip - 1, prev);
              } else {
                return prev;
              }
            } else {
              return prev;
            }
          })
        }
        disabled={loading}
      >
        {"<"}
      </PaginationButton>
      {(() => {
        const pages = [];
        const numOfPages = Math.ceil(total / limit);
        for (let i = 0; i < numOfPages; i++) {
          pages.push(1);
        }

        return indexedMap((_, i) => {
          const page = i + 1;
          return (
            <PaginationButton
              className="border border-r-0 border-l-0"
              key={`paginated-button-${i}`}
              disabled={loading}
              onClick={() => {
                setPaginated((prev) => {
                  if (prev.skip !== i) {
                    fetch(i);
                    return assoc("skip", i, prev);
                  } else {
                    return prev;
                  }
                });
              }}
              isActive={i === skip}
            >
              {page}
            </PaginationButton>
          );
        }, pages);
      })()}
      <PaginationButton
        className="border border-l-0 border-r-0"
        onClick={() =>
          setPaginated((prev) => {
            if (total !== 0) {
              if (prev.skip + 1 !== Math.ceil(total / limit)) {
                fetch(prev.skip + 1);
                return assoc("skip", prev.skip + 1, prev);
              } else {
                return prev;
              }
            } else {
              return prev;
            }
          })
        }
        disabled={loading}
      >
        {">"}
      </PaginationButton>
      <PaginationButton
        className="rounded-r-lg border border-l-0"
        onClick={() =>
          setPaginated((prev) => {
            if (
              total !== 0 &&
              limit !== 0 &&
              prev.skip !== Math.ceil(total / limit) - 1
            ) {
              fetch(Math.ceil(total / limit) - 1);
              return assoc("skip", Math.ceil(total / limit) - 1, prev);
            } else {
              return prev;
            }
          })
        }
        disabled={loading}
      >
        {">>"}
      </PaginationButton>
    </div>
  );
};
