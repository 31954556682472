import React, { useCallback, useEffect, useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import { joinStrings } from "../utils/util-methods";

export const ShadowSearch = ({
  id,
  name,
  options,
  onSelect,
  className,
  value,
  placeHolder,
  onChange,
  error,
  touched,
  loading,
  icons = [],
  label,
  useOptions = true,
  clearable = false,
  onClear,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef(null);

  const clickListener = useCallback((e) => {
    if (!ref?.current?.contains(e.target)) {
      setIsFocused(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", clickListener);

    return () => {
      document.removeEventListener("click", clickListener);
    };
  }, [clickListener]);

  return (
    <div className={"relative inline-block text-left " + className}>
      {!!label && (
        <label htmlFor={id} className="text-gray-600">
          {label}
        </label>
      )}
      <div ref={ref} className="flex items-center relative">
        <AiOutlineSearch className="text-xl absolute left-3 text-gray-400" />
        <input
          clearable="false"
          id={id}
          name={name}
          type="text"
          // onClick={() => setIsFocused(true)}
          placeholder={placeHolder}
          value={value}
          onChange={onChange}
          className={joinStrings(
            "pl-10 py-3 appearance-none w-full placeholder-gray-300 bg-white rounded border border-gray-400",
            "transition-colors duration-300",
            "focus:outline-none focus:border-cyan-700"
            // !!error && touched && "placeholder-red-600 bg-red-50"
          )}
          onClick={() => setIsFocused(true)}
        />
        {clearable && !loading && !!value?.length && (
          <button
            className="btn absolute right-5"
            onClick={onClear}
          >
            <IoClose className="text-2xl text-red-600" />
          </button>
        )}
        {loading && (
          <svg
            className="h-6 w-6 text-cyan-900 absolute right-5 animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
      </div>

      {!!value?.length && !options?.length && isFocused && useOptions && (
        <div className="absolute left-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-y-auto max-h-52 h-auto z-10">
          <div className="py-1 cursor-pointer">
            <div className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
              No results found
            </div>
          </div>
        </div>
      )}

      {!!options?.length && isFocused && useOptions && (
        <div className="absolute left-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-y-auto max-h-52 h-auto z-10">
          <div className="py-1 cursor-pointer">
            {options?.map(({ text, key, value }, i) => (
              <div
                key={key}
                onClick={() => {
                  setIsFocused(false);
                  onSelect && onSelect(value);
                }}
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 space-x-2"
              >
                {icons[i % icons.length]}
                <span>{text}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
