import { getDate, getMonth, getYear, isAfter, isBefore } from "date-fns";
import format from "date-fns/format";
import { fill } from "lodash";
import { defaultTo, find, isEmpty, length, map } from "ramda";
import React, { Fragment, useEffect, useState } from "react";
import { AiFillInfoCircle, AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import { FaPhone } from "react-icons/fa";
import { withRouter } from "react-router";
import { indexedMap, joinStrings } from "../utils/util-methods";
import { Popup } from "./Popup";

export const FeatureCard1 = withRouter(
  ({
    businessId,
    logo,
    name,
    operatingTimes = [],
    categories,
    rating = 0,
    history,
    phoneNumber,
    loading = false,
  }) => {
    const [operatingDates, setOperatingDates] = useState([]);
    const [openTime, setOpenTime] = useState({});

    useEffect(() => {
      const currentDate = new Date();
      setOperatingDates(
        map(({ openingTime, closingTime }) => {
          const splitOpeningTime = openingTime.split(":");
          const openingTimeHour = parseInt(splitOpeningTime[0]);
          const openingTimeMinute = parseInt(splitOpeningTime[1]);

          const splitClosingTime = closingTime.split(":");
          const closingTimeHour = parseInt(splitClosingTime[0]);
          const closingTimeMinute = parseInt(splitClosingTime[1]);
          return {
            openingTime: new Date(
              getYear(currentDate),
              getMonth(currentDate),
              getDate(currentDate),
              openingTimeHour,
              openingTimeMinute
            ),
            closingTime: new Date(
              getYear(currentDate),
              getMonth(currentDate),
              getDate(currentDate),
              closingTimeHour,
              closingTimeMinute
            ),
          };
        }, operatingTimes)
      );
    }, [operatingTimes]);

    // const isOpen = useCallback(() => {
    //   const currentDate = new Date();
    //   return !!find(
    //     ({ openingTime, closingTime }) =>
    //       isAfter(currentDate, openingTime) &&
    //       isBefore(currentDate, closingTime),
    //     operatingDates
    //   );
    // }, [operatingDates]);

    useEffect(() => {
      const currentDate = new Date();
      const open = find(
        ({ openingTime, closingTime }) =>
          isAfter(currentDate, openingTime) &&
          isBefore(currentDate, closingTime),
        operatingDates
      );

      setOpenTime(defaultTo({}, open));
    }, [operatingDates]);

    return (
      <div
        className={joinStrings(
          "flex flex-col w-80 rounded-lg bg-white overflow-hidden shadow-md cursor-pointer h-full",
          "xl:w-164 xl:flex-row",
          "transform duration-300",
          "hover:shadow-2xl hover:-translate-y-2"
        )}
        onClick={() => history.push(`/business/${businessId}`)}
      >
        {!loading && (
          <div
            className={joinStrings(
              "w-80 h-64 flex-grow bg-black",
              "xl:w-2/5 xl:h-full"
            )}
          >
            <img
              src={logo}
              alt="business_logo"
              className={joinStrings(
                "w-80 h-64 object-contain",
                "xl:w-full"
              )}
            />
          </div>
        )}
        {loading && (
          <div
            className={joinStrings(
              "w-80 h-64 bg-gray-300 animate-pulse flex-grow flex-shrink-0",
              "xl:w-2/5"
            )}
          ></div>
        )}
        <div
          className={joinStrings(
            "flex flex-col px-7 py-5 overflow-hidden h-full w-full flex-shrink-0",
            "xl:w-3/5"
          )}
        >
          {!loading && (
            <Fragment>
              <div className="flex justify-between items-center">
                <h4 className="font-semibold text-gray-900">{name}</h4>
                {!isEmpty(openTime) && (
                  <span className="text-green-600 font-semibold text-lg">
                    OPEN
                  </span>
                )}
                {isEmpty(openTime) && (
                  <span className="text-red-600 font-semibold text-lg">
                    CLOSED
                  </span>
                )}
              </div>
              <div className="flex items-center space-x-2 overflow-x-auto scrollbar-hidden">
                {!!length(categories) &&
                  categories.map(({ name }, i) => (
                    <span
                      key={`category-${businessId}-${i}`}
                      className="px-4 my-1 mt-4 bg-gray-200 text-gray-600 rounded-full shadow text-sm w-max whitespace-nowrap"
                    >
                      {name}
                    </span>
                  ))}
              </div>
              <div className="flex flex-col mt-5 space-y-2">
                <div className="flex items-center space-x-3">
                  <FaPhone className="text-cyan-600" />
                  <div>{phoneNumber}</div>
                </div>

                <div className="flex items-center space-x-3">
                  <BiTimeFive className="text-lg text-cyan-600" />
                  {!isEmpty(operatingTimes) && (
                    <div className="flex space-x-2 items-center">
                      <span>
                        {isEmpty(openTime) && (
                          <>
                            {operatingTimes[0].openingTime} -{" "}
                            {operatingTimes[0].closingTime}
                          </>
                        )}
                        {!isEmpty(openTime) && (
                          <>
                            {format(openTime.openingTime, "HH:mm")} -{" "}
                            {format(openTime.closingTime, "HH:mm")}
                          </>
                        )}
                      </span>

                      {length(operatingTimes) > 1 && (
                        <Popup
                          text={
                            <div className="flex flex-col">
                              {indexedMap(
                                ({ openingTime, closingTime }, i) => (
                                  <span
                                    key={`operating-time-popup-${i}-${name}-${businessId}`}
                                  >
                                    {openingTime} - {closingTime}
                                  </span>
                                ),
                                operatingTimes
                              )}
                            </div>
                          }
                        >
                          <AiFillInfoCircle className="text-gray-400 text-xl" />
                        </Popup>
                      )}
                    </div>
                  )}
                  {isEmpty(operatingTimes) && <span>Closed Today</span>}
                </div>
              </div>
              <div className="flex mt-5 justify-between items-center">
                {!!rating && (
                  <div className="flex text-gray-600">
                    {fill(Array(rating), "").map((_, i) => (
                      <AiFillStar key={`star-rating-filled-${i}`} />
                    ))}
                    {fill(Array(5 - rating), "").map((_, i) => (
                      <AiOutlineStar key={`star-rating-empty-${i}`} />
                    ))}
                  </div>
                )}
                {!rating && <p>No reviews yet</p>}
              </div>
            </Fragment>
          )}
          {loading && (
            <div
              className={joinStrings(
                "w-full h-64 bg-gray-300 animate-pulse rounded-lg",
                "xl:h-full"
              )}
            ></div>
          )}
        </div>
      </div>
    );
  }
);
// export default withRouter(FeatureCard1);
