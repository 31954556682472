import React, { Fragment } from "react";
import { joinStrings } from "../utils/util-methods";

export const Dropdown = ({ options, isOpen, setIsOpen, className }) => {
  return (
    <Fragment>
      {isOpen && (
        <div
          className={joinStrings(
            "absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-y-auto max-h-52 h-auto",
            className
          )}
        >
          <div className="py-1 cursor-pointer">
            {options?.map(({ text, key, clickEvent }) => (
              <div
                key={key}
                onClick={() => {
                  setIsOpen(false);
                  if (clickEvent) {
                      clickEvent();
                  }
                }}
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 space-x-2"
              >
                <span>{text}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </Fragment>
  );
};
