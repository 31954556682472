import React from "react";
import { joinStrings } from "../../utils/util-methods";

export const PaginationButton = ({
  children,
  isActive = false,
  onClick,
  disabled = false,
  className,
}) => {
  return (
    <button
      type="button"
      className={joinStrings(
        "btn w-10 h-10",
        "transition-colors duration-300",
        isActive ? "bg-cyan-700 text-white" : "bg-white text-black",
        isActive ? "" : "hover:bg-cyan-100",
        className
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
