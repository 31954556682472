import React from "react";
import { joinStrings } from "../../utils/util-methods";

export const Logo = ({ className }) => {
  return (
    <svg
      viewBox="0 0 500 500"
      xmlns="http://www.w3.org/2000/svg"
      className={joinStrings(className)}
    >
      <g id="Layer_1">
        <path
          stroke="#000"
          transform="rotate(90 211.0534515380859,257.5315246582032)"
          id="svg_31"
          d="m8.7912,353.42343l171.66664,-272.50006l171.66664,272.50006l-343.33328,0z"
          opacity="undefined"
          strokeOpacity="null"
          strokeDasharray="null"
          strokeWidth="0"
          fill="#164e63"
          className="group-hover:translate-y-20 duration-500 transform rotate-90 origin-center -translate-x-14 translate-y-8"
        />
        <path
          stroke="#B1FFFF"
          strokeWidth="0"
          transform="rotate(90 289.623779296875,296.10241699218756) "
          id="svg_28"
          d="m128.67126,431.63819l160.95251,-271.07149l160.95251,271.07149l-321.90501,0z"
          opacity="undefined"
          strokeDasharray="null"
          className="group-hover:-translate-y-24 duration-500 transform rotate-90 origin-center translate-x-16"
          fill="#B1FFFF"
        />
      </g>
    </svg>
  );
};
