import { useFormik } from "formik";
import { isEmpty } from "ramda";
import React, { useContext, useEffect, useState } from "react";
import { HiOutlineUserCircle } from "react-icons/hi";
import * as yup from "yup";
import { ToastContext } from "../../../contexts/ToastContext";
import { mutation1 } from "../../../services/graphqlService";
import { ToastType } from "../../../utils/enums/ToastType";
import { ShadowInput } from "../../ShadowInput";
import { ProfileEditModal } from "../ProfileEditModal";

export const FirstNameEditModal = ({
  open,
  setOpen,
  userId,
  mergeUser,
  firstName,
}) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useContext(ToastContext);

  const editForm = useFormik({
    initialValues: {
      firstName: "",
    },
    validationSchema: yup.object({
      firstName: yup.string().required("A new first name is required"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const {
          data: { updateUser },
        } = await mutation1(`updateUser(id: ${userId}, data: { firstName: "${values.firstName}" }) {
              firstName
          }`);

        mergeUser(updateUser);
        setLoading(false);
        editForm.resetForm();
        setOpen(false);
      } catch (err) {
        addToast(
          ToastType.Error,
          "Action failed. The server may be temporarily down. Please try again in a few minutes."
        );
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (open && !isEmpty(firstName)) {
      editForm?.setFieldValue("firstName", firstName, false);
    }
    //eslint-disable-next-line
  }, [firstName, open]);

  return (
    <ProfileEditModal
      open={open}
      setOpen={setOpen}
      header="Update your first name"
      onSubmit={editForm.handleSubmit}
      onClose={() => editForm.setFieldValue("firstName", firstName, false)}
      loading={loading}
    >
      <form className="flex flex-col space-y-3 px-10 mt-5">
        <ShadowInput
          id="firstName"
          name="firstName"
          placeHolder="First Name"
          onChange={editForm.handleChange}
          onBlur={editForm.handleBlur}
          error={editForm.errors.firstName}
          value={editForm.values.firstName}
          touched={editForm.touched.firstName}
          icon={<HiOutlineUserCircle className="text-2xl text-gray-400" />}
        />
      </form>
    </ProfileEditModal>
  );
};
