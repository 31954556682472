import React, { useRef } from "react";
import { joinStrings } from "../utils/util-methods";

export const FileSelect = ({ onChange, children, className }) => {
  const inputFileRef = useRef(null);
  return (
    <button
      className={joinStrings("outline-none overflow-hidden", "focus:outline-none", className)}
      type="button"
      onClick={() => inputFileRef.current.click()}
    >
      {children}
      <input
        ref={inputFileRef}
        type="file"
        className="hidden"
        onChange={onChange}
      />
    </button>
  );
};
