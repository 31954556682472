import React, { createContext } from "react";

export const AccordionItemContext = createContext({
  name: "",
});

export const AccordionItem = ({ children, name }) => {
  return (
    <AccordionItemContext.Provider value={{ name }}>
      <div className="flex flex-col">{children}</div>
    </AccordionItemContext.Provider>
  );
};
