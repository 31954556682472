import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LoggedInContext } from "../contexts/LoggedInContext";
import LoggedInDropdown from "./LoggedInDropdown";
import { NavbarItem } from "./NavbarItem";
import { Logo } from "./SVG/Logo";

export const NavBar = ({ navbarItems, loginOnClick }) => {
  const { isLoggedIn } = useContext(LoggedInContext);
  return (
    <div className="w-full bg-cyan-900 shadow-lg sticky top-0 z-50 hidden lg:inline-block h-20">
      <div className="container mx-auto">
        <div className="flex px-4 py-6 justify-between h-20 items-center">
          <Link
            to="/"
            className="flex items-center space-x-0 text-white font-semibold bg-cyan-600 pr-5 group rounded-sm"
          >
            <Logo className="h-14 w-14 p-1" />
            <h3 className="mb-1">Syncvine</h3>
          </Link>

          <div className="flex space-x-10 items-center">
            {navbarItems?.map((x, i) => {
              return <NavbarItem {...x} key={"navbarItem " + i} />;
            })}
            {!isLoggedIn && (
              <button
                className="bg-cyan-500 text-blue-50 py-2 px-3 ml-10 rounded focus:outline-none hover:bg-cyan-700 transition-colors duration-300 text-base"
                onClick={loginOnClick}
              >
                Login/Register
              </button>
            )}
            {isLoggedIn && <LoggedInDropdown />}
          </div>
        </div>
      </div>
    </div>
  );
};
