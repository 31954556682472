import React, { useState } from "react";
import { BiCoinStack } from "react-icons/bi";
import { BsCreditCard } from "react-icons/bs";
import { joinStrings } from "../../../../utils/util-methods";
import { CloseButton } from "../../../CloseButton";
import { Modal } from "../../../Modal";
import { UpdatePlanModal } from "./UpdatePlanModal";

export const UpdateSubscriptionModal = ({
  open,
  setOpen,
  payfastToken,
  userId,
  currentPlan,
  businessName,
  businessId,
  refreshBusinesses,
}) => {
  const [isUpdatePackageModalOpen, setIsUpdatePackageModalOpen] =
    useState(false);
  const [currentDate] = useState(new Date());

  return (
    <>
      <Modal open={open} setOpen={setOpen}>
        <div className={joinStrings("bg-white rounded-lg")}>
          <div className="flex justify-center items-center h-20 border-b border-gray-200">
            <h3 className="text-cyan-900">Update Subscription</h3>
          </div>

          <div className="flex space-x-5 p-6">
            <button
              onClick={() => setIsUpdatePackageModalOpen(true)}
              type="button"
              className={joinStrings(
                "btn flex items-center space-x-2 px-5 py-3 rounded-md text-gray-700",
                "transition-shadow duration-300",
                "hover:shadow-lg"
              )}
              disabled={currentDate.getDate() === 1}
            >
              <BiCoinStack className="text-2xl text-cyan-600" />
              <span>Update Payment Plan</span>
            </button>

            <a
              href={`https://www.payfast.co.za/eng/recurring/update/${payfastToken}?return=https://syncvine.co.za/profile/${userId}`}
              className={joinStrings(
                "flex space-x-3 items-center rounded-md px-5 py-3 text-gray-700",
                "transition-all duration-300",
                "hover:shadow-lg"
              )}
            >
              <BsCreditCard className="text-2xl text-green-600" />
              <span>Update Payment Details</span>
            </a>
          </div>

          <div className="flex justify-end items-center h-20 px-10 space-x-3 border-t border-gray-200">
            <CloseButton
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </CloseButton>
          </div>
        </div>
      </Modal>
      <UpdatePlanModal
        open={isUpdatePackageModalOpen}
        setOpen={setIsUpdatePackageModalOpen}
        setOpenOfParentModal={setOpen}
        currentPlan={currentPlan}
        businessName={businessName}
        businessId={businessId}
        userId={userId}
        refreshBusinesses={refreshBusinesses}
      />
    </>
  );
};
