import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LoggedInContext } from "../contexts/LoggedInContext";
import { LoginModalContext } from "../contexts/LoginModalContext";
import { joinStrings } from "../utils/util-methods";
import { FacebookLogin } from "./FacebookLogin";
import { GoogleLogin } from "./GoogleLogin";

export const Footer = () => {
  const { isLoggedIn, logout } = useContext(LoggedInContext);
  const { setIsLoginModalOpen } = useContext(LoginModalContext);
  return (
    <div className="bg-cyan-900 text-white">
      <div className="container mx-auto px-3">
        <div
          className={joinStrings(
            "grid grid-cols-1 pt-10 gap-y-10",
            "sm:grid-cols-2",
            "lg:grid-cols-3"
            // "xl:mx-52"
          )}
        >
          {/* Resources */}
          <div
            className={joinStrings(
              "flex flex-col space-y-3 col-span-1 w-max mx-auto",
              "sm:mx-0",
              "lg:mx-0"
            )}
          >
            <h3 className="border-b border-white text-2xl font-semibold w-max">
              Resources
            </h3>
            <Link to="/" className="w-max">
              Home
            </Link>
            <Link to="/browse" className="w-max">
              Browse
            </Link>
            <Link to="/advertise" className="w-max">
              Advertise
            </Link>
            <Link to="/forgot-password" className="w-max">
              Forgot your password?
            </Link>
            {!isLoggedIn && (
              <button
                className="btn w-max"
                onClick={() => setIsLoginModalOpen(true)}
              >
                Login/Register
              </button>
            )}
            {isLoggedIn && (
              <button className="btn w-max" onClick={() => logout()}>
                Logout
              </button>
            )}
          </div>

          {/* Business */}
          <div
            className={joinStrings(
              "flex flex-col space-y-3 col-span-1 w-max mx-auto",
              "sm:mx-0",
              "lg:mx-0"
            )}
          >
            <h3 className="border-b border-white text-2xl font-semibold w-max">
              Business
            </h3>
            <Link to="/contact-us" className="w-max">
              Contact us
            </Link>
            <Link to="/privacy-policy" className="w-max">
              Privacy Policy
            </Link>
            <Link to="/terms-and-conditions" className="w-max">
              Terms and Conditions
            </Link>
          </div>

          {!isLoggedIn && (
            <div
              className={joinStrings(
                "flex flex-col space-y-5 col-span-1 max-w-max mx-auto",
                "sm:col-span-2 sm:max-w-full sm:w-full",
                "lg:col-span-1"
              )}
            >
              <h6 className="font-medium">
                Use social media accounts to log in quickly
              </h6>
              <GoogleLogin />
              <FacebookLogin />
              <p className="text-sm">
                By logging in you agree to our privacy policy and terms and
                conditons.
              </p>
            </div>
          )}
          {isLoggedIn && <div className="p-3 ml-auto"></div>}
        </div>

        {/* Copyright */}
        <div className={joinStrings("pt-10 pb-5 mx-auto max-w-max", "sm:mx-0")}>
          <p>Syncvine&reg; 2021. All Rights Reserved | Proudly South African</p>
        </div>
      </div>
    </div>
  );
};
