import React from 'react'
import { ContactUs } from '../components/ContactUs'

export const Contact = () => {
    return (
        <div className="flex justify-center container mx-auto my-20">
            <ContactUs />
        </div>
    )
}
