import React from "react";

export const UndrawDescription = ({ className }) => {
  return (
    <svg
      id="b349698d-76f6-4d11-bd66-db075a783fa5"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="625.64494"
      height="417.05929"
      viewBox="0 0 625.64494 417.05929"
      className={className}
    >
      <polygon
        points="85.4 405.79 96.832 405.789 102.269 361.693 85.396 361.695 85.4 405.79"
        fill="#ffb8b8"
      />
      <path
        d="M370.128,643.9928H406.0577a0,0,0,0,1,0,0v13.88195a0,0,0,0,1,0,0H384.00989A13.88193,13.88193,0,0,1,370.128,643.99281v0a0,0,0,0,1,0,0Z"
        transform="translate(489.06685 1060.36217) rotate(179.99483)"
        fill="#2f2e41"
      />
      <polygon
        points="177.4 405.79 188.832 405.789 194.269 361.693 177.396 361.695 177.4 405.79"
        fill="#ffb8b8"
      />
      <path
        d="M462.128,643.9928H498.0577a0,0,0,0,1,0,0v13.88195a0,0,0,0,1,0,0H476.00989A13.88193,13.88193,0,0,1,462.128,643.99281v0A0,0,0,0,1,462.128,643.9928Z"
        transform="translate(673.06685 1060.35387) rotate(179.99483)"
        fill="#2f2e41"
      />
      <path
        d="M370.10494,631.01988A4.49341,4.49341,0,0,1,365.69,627.3856l-7.34119-92.24511c-7.903-78.1167,5.62439-105.63721,5.762-105.90577l.14844-.29052.32532.019,63.45251,3.65186,13.46924,44.06L477.11056,524.513a43.3315,43.3315,0,0,1,8.52075,26.28663l-.90112,69.748a4.52634,4.52634,0,0,1-4.18738,4.43115l-18.58728,1.293a4.48515,4.48515,0,0,1-4.7865-4.00781l-4.6051-75.72461a.39027.39027,0,0,0-.10682-.20849l-52.805-49.21631a1.50066,1.50066,0,0,0-2.49621.49267l-5.32532,128.19483a4.44628,4.44628,0,0,1-1.103,3.38183,4.51,4.51,0,0,1-3.29211,1.52149l-17.24744.314C370.16024,631.01988,370.13241,631.01988,370.10494,631.01988Z"
        transform="translate(-287.17753 -241.47035)"
        fill="#2f2e41"
      />
      <circle
        cx="399.34472"
        cy="305.68027"
        r="32.04114"
        transform="translate(-384.86222 -12.46081) rotate(-28.66321)"
        fill="#2f2e41"
      />
      <path
        d="M536.9762,368.57457a9.37692,9.37692,0,0,0-13.45739,5.06347l-21.39441,1.19923-2.44223,13.17907,30.18926-2.11789a9.42779,9.42779,0,0,0,7.10477-17.32388Z"
        transform="translate(-287.17753 -241.47035)"
        fill="#ffb8b8"
      />
      <path
        d="M360.18855,477.31747a9.37694,9.37694,0,0,0-5.94813-13.09045l-2.62086-21.26712-13.31241-1.55944,4.123,29.9813a9.42779,9.42779,0,0,0,17.75843,5.93571Z"
        transform="translate(-287.17753 -241.47035)"
        fill="#ffb8b8"
      />
      <circle cx="110.76448" cy="74.01763" r="24.56103" fill="#ffb8b8" />
      <path
        d="M402.78463,441.29429c-13.06018,0-24.00427-1.05712-34.85815-4.9375-4.46827-1.59716-4.94581-3.57763-5.5741-6.05468-.98633-3.8877-1.5271-49.86621,3.5647-65.12549a22.90232,22.90232,0,0,1,18.97314-15.42822c4.84827-.57129,19.35046-2.6211,27.56348-3.792a15.51792,15.51792,0,0,1,11.44592,2.80371c2.177,1.63477,3.30737,3.39063,3.35986,5.21924l2.23169,77.73926a8.664,8.664,0,0,1-8.09,8.92578C416.38265,440.98375,409.8597,441.29429,402.78463,441.29429Z"
        transform="translate(-287.17753 -241.47035)"
        fill="#59d3ff"
      />
      <path
        d="M337.82015,460.33336a4.49989,4.49989,0,0,1-4.49548-4.67383l.78-35.65479a25.38989,25.38989,0,0,1,3.81738-12.43554l30.2655-48.54932a10.52921,10.52921,0,0,1,8.93262-4.936,10.41458,10.41458,0,0,1,8.89587,5.00244h0a10.50194,10.50194,0,0,1,.24744,10.53027l-29.35779,53.356,2.02088,32.748a4.49964,4.49964,0,0,1-4.48987,4.58057l-16.60657.03223Z"
        transform="translate(-287.17753 -241.47035)"
        fill="#59d3ff"
      />
      <path
        d="M517.1868,393.32687a4.45507,4.45507,0,0,1-3.18543,1.4998l-35.63052,1.88964a25.38339,25.38339,0,0,1-12.67741-2.87692l-50.67665-26.54961a10.48028,10.48028,0,0,1-1.26753-17.78351l.29231.40548-.29231-.40548a10.50027,10.50027,0,0,1,10.482-1.03433L479.634,373.75679l32.52917-4.46727a4.50016,4.50016,0,0,1,4.87914,4.13744l1.27443,16.55767c.00634.08288.01023.16533.01216.24771A4.45677,4.45677,0,0,1,517.1868,393.32687Z"
        transform="translate(-287.17753 -241.47035)"
        fill="#59d3ff"
      />
      <polygon
        points="92.571 70.539 86.199 53.332 114.851 45.039 135.33 51.332 135.33 70.539 92.571 70.539"
        fill="#2f2e41"
      />
      <path
        d="M409.868,273.21653a34.90653,34.90653,0,0,0-61.72687-19.11236c-6.02356,7.32094-8.80679,16.7392-10.84385,25.99824s-3.512,18.762-7.4673,27.37794S318.90113,323.924,309.6944,326.18576s-20.38861-2.83111-22.51687-12.06961a46.84536,46.84536,0,0,0,15.89785,32.751c7.97692,6.91794,21.0009,4.47181,28.85369-2.58672s11.73951-17.4174,15.10188-27.42658,6.6777-20.44546,13.81009-28.23122,27.34681-16.06095,36.50759-10.81033C404.53286,281.93,407.11075,279.31237,409.868,273.21653Z"
        transform="translate(-287.17753 -241.47035)"
        fill="#2f2e41"
      />
      <path
        d="M896.32247,478.02965h-348a16.51868,16.51868,0,0,1-16.5-16.5v-177a16.51867,16.51867,0,0,1,16.5-16.5h348a16.51866,16.51866,0,0,1,16.5,16.5v177A16.51867,16.51867,0,0,1,896.32247,478.02965Z"
        transform="translate(-287.17753 -241.47035)"
        fill="#e6e6e6"
      />
      <path
        d="M892.32247,466.02965h-340a8.50951,8.50951,0,0,1-8.5-8.5v-169a8.5095,8.5095,0,0,1,8.5-8.5h340a8.5095,8.5095,0,0,1,8.5,8.5v169A8.50951,8.50951,0,0,1,892.32247,466.02965Z"
        transform="translate(-287.17753 -241.47035)"
        fill="#fff"
      />
      <path
        d="M855.32253,441.52965h-266a8,8,0,1,1,0-16h266a8,8,0,0,1,0,16Z"
        transform="translate(-287.17753 -241.47035)"
        fill="#e6e6e6"
      />
      <path
        d="M855.32253,407.52965h-266a8,8,0,1,1,0-16h266a8,8,0,0,1,0,16Z"
        transform="translate(-287.17753 -241.47035)"
        fill="#e6e6e6"
      />
      <path
        d="M647.32247,368.15709h-58a8.50951,8.50951,0,0,1-8.5-8.5V317.4022a8.50951,8.50951,0,0,1,8.5-8.5h58a8.50951,8.50951,0,0,1,8.5,8.5v42.25489A8.50951,8.50951,0,0,1,647.32247,368.15709Z"
        transform="translate(-287.17753 -241.47035)"
        fill="#59d3ff"
      />
      <path
        d="M855.61781,363.52965h-135a8,8,0,1,1,0-16h135a8,8,0,0,1,0,16Z"
        transform="translate(-287.17753 -241.47035)"
        fill="#ccc"
      />
      <path
        d="M799.61781,329.52965h-79a8,8,0,1,1,0-16h79a8,8,0,0,1,0,16Z"
        transform="translate(-287.17753 -241.47035)"
        fill="#ccc"
      />
      <path
        d="M537.4957,658.52965h-198a1,1,0,1,1,0-2h198a1,1,0,0,1,0,2Z"
        transform="translate(-287.17753 -241.47035)"
        fill="#ccc"
      />
    </svg>
  );
};
