import React, { createContext, useEffect, useState } from "react";
import { joinStrings } from "../../utils/util-methods";
import { AccordionBody } from "./AccordionBody";
import { AccordionHeader } from "./AccordionHeader";
import { AccordionItem } from "./AccordionItem";
import { AccordionToggle } from "./AccordionToggle";

export const AccordionContext = createContext({
  activeItems: null,
  setActiveItems: null,
  allowAllOpen: false,
});

export const Accordion = ({
  children,
  className,
  defaultOpenItem,
  allowAllOpen = false,
}) => {
  const [activeItems, setActiveItems] = useState(null);
  useEffect(() => {
    if (allowAllOpen) {
      if (defaultOpenItem) {
        setActiveItems([defaultOpenItem]);
      } else {
        setActiveItems([]);
      }
    } else if (!allowAllOpen) {
      if (defaultOpenItem) {
        setActiveItems(defaultOpenItem);
      } else {
        setActiveItems("");
      }
    }
  }, [defaultOpenItem, allowAllOpen]);

  return (
    <AccordionContext.Provider
      value={{
        activeItems,
        setActiveItems,
        allowAllOpen: allowAllOpen,
      }}
    >
      <div className={joinStrings("flex flex-col", className)}>{children}</div>
    </AccordionContext.Provider>
  );
};

Accordion.Toggle = AccordionToggle;
Accordion.Item = AccordionItem;
Accordion.Header = AccordionHeader;
Accordion.Body = AccordionBody;
