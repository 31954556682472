import { format } from "date-fns";
import { BusinessSubscriptionPackage } from "../utils/enums/BusinessSubscriptionPackage";
import { PayfastSubscriptionFrequency } from "../utils/enums/PayfastSubscriptionFrequency";
import { getEnvVariable } from "../utils/util-methods";

const crypto = require("crypto");

export const generateSignature = (data, passPhrase = null) => {
  // Create parameter string
  let pfOutput = "";
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      if (data[key] !== "") {
        pfOutput += `${key}=${encodeURIComponent(data[key].trim()).replace(
          /%20/g,
          "+"
        )}&`;
      }
    }
  }

  // Remove last ampersand
  let getString = pfOutput.slice(0, -1);
  if (passPhrase !== null) {
    getString += `&passphrase=${encodeURIComponent(passPhrase.trim()).replace(
      /%20/g,
      "+"
    )}`;
  }

  //   return md5(getString);
  return crypto.createHash("md5").update(getString).digest("hex");
};

export const generateParamString = (data, passPhrase = null) => {
  // Create parameter string
  let pfOutput = "";
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      if (data[key] !== "") {
        pfOutput += `${key}=${encodeURIComponent(data[key].trim()).replace(
          /%20/g,
          "+"
        )}&`;
      }
    }
  }

  // Remove last ampersand
  let getString = pfOutput.slice(0, -1);
  if (passPhrase !== null) {
    getString += `&passphrase=${encodeURIComponent(passPhrase.trim()).replace(
      /%20/g,
      "+"
    )}`;
  }

  //   return md5(getString);
  return getString;
};

export const getPayfastAmount = (subscriptionPackage) => {
  if (subscriptionPackage === BusinessSubscriptionPackage.BRONZE) {
    return `${getEnvVariable("BRONZE_AMOUNT")}.00`;
  } else if (subscriptionPackage === BusinessSubscriptionPackage.SILVER) {
    return `${getEnvVariable("SILVER_AMOUNT")}.00`;
  } else if (subscriptionPackage === BusinessSubscriptionPackage.GOLD) {
    return `${getEnvVariable("GOLD_AMOUNT")}.00`;
  }
};

export const getNextBillingDate = (subscriptionPackage) => {
  const today = new Date();
  const currentMonth = today.getMonth();
  if (subscriptionPackage === BusinessSubscriptionPackage.BRONZE) {
    return format(
      new Date(today.getFullYear(), currentMonth + 1, 1),
      "yyyy-MM-dd"
    );
  } else if (subscriptionPackage === BusinessSubscriptionPackage.SILVER) {
    return format(
      new Date(today.getFullYear(), currentMonth + 3, 1),
      "yyyy-MM-dd"
    );
  } else if (subscriptionPackage === BusinessSubscriptionPackage.GOLD) {
    return format(
      new Date(today.getFullYear(), currentMonth + 6, 1),
      "yyyy-MM-dd"
    );
  }
};

export const getSubscriptionFrequency = (subscriptionPackage) => {
  if (subscriptionPackage === BusinessSubscriptionPackage.BRONZE) {
    return PayfastSubscriptionFrequency.Monthly;
  } else if (subscriptionPackage === BusinessSubscriptionPackage.SILVER) {
    return PayfastSubscriptionFrequency.Quarterly;
  } else if (subscriptionPackage === BusinessSubscriptionPackage.GOLD) {
    return PayfastSubscriptionFrequency.BiAnnually;
  }
};
