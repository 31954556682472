import { FieldArray, FormikProvider, getIn, useFormik } from "formik";
import jwt_decode from "jwt-decode";
import { gte } from "lodash";
import {
  append,
  ascend,
  assoc,
  dec,
  filter,
  find,
  findIndex,
  flatten,
  forEach,
  groupBy,
  inc,
  includes,
  isEmpty,
  isNil,
  lte,
  map as Rmap,
  mergeRight,
  pipe,
  pluck,
  prop,
  propEq,
  remove,
  reverse,
  sort,
  sortBy,
  take,
  values,
} from "ramda";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  AiFillFacebook,
  BiImageAdd,
  BiTimeFive,
  BsPlusSquareFill,
  CgWebsite,
  FaPhone,
  FaTwitter,
  FiPackage,
  GrInstagram,
  GrPinterest,
  GrYoutube,
  HiOutlineClipboardList,
  IoBusinessSharp,
  IoClose,
  MdLocationOn,
} from "react-icons/all";
import { withRouter } from "react-router";
import * as yup from "yup";
import { LoggedInContext } from "../contexts/LoggedInContext";
import { ToastContext } from "../contexts/ToastContext";
import { Business } from "../pages/Business";
import { query1, updateBusiness } from "../services/graphqlService";
import { BusinessImageType } from "../utils/enums/BusinessImageType";
import { DayOfWeek } from "../utils/enums/DayOfWeek";
import { MessageType } from "../utils/enums/MessageType";
import { SocialMediaPlatform } from "../utils/enums/SocialMediaPlatforms";
import { ToastMessage } from "../utils/enums/ToastMessage";
import { ToastType } from "../utils/enums/ToastType";
import {
  getToken,
  getUserId,
  indexedForEach,
  indexedMap,
  joinStrings,
} from "../utils/util-methods";
import { FileSelect } from "./FileSelect";
import { LogoLoader } from "./LogoLoader";
import { Message } from "./Message";
import { PrimaryButton } from "./PrimaryButton";
import {
  handleGoogleMapSearchChange,
  handlePlaceSelect,
  isAddOperatingHourDisabled,
  placeMarkerAndPanTo,
} from "./RegisterBusiness.functions";
import { RegisterCarousel } from "./RegisterCarousel";
import { RegisterSelect } from "./RegisterSelect";
import { SecondaryButton } from "./SecondaryButton";
import { ShadowInput } from "./ShadowInput";
import { ShadowSearch } from "./ShadowSearch";
import { ShadowTextArea } from "./ShadowTextArea";
import { StepperContext } from "./Stepper/StepperContext";
import { Toggle } from "./Toggle";

const times = [
  { key: "time1", value: "00:00", text: "00:00" },
  { key: "time2", value: "00:30", text: "00:30" },
  { key: "time3", value: "01:00", text: "01:00" },
  { key: "time4", value: "01:30", text: "01:30" },
  { key: "time5", value: "02:00", text: "02:00" },
  { key: "time6", value: "02:30", text: "02:30" },
  { key: "time7", value: "03:00", text: "03:00" },
  { key: "time8", value: "03:30", text: "03:30" },
  { key: "time9", value: "04:00", text: "04:00" },
  { key: "time10", value: "04:30", text: "04:30" },
  { key: "time11", value: "05:00", text: "05:00" },
  { key: "time12", value: "05:30", text: "05:30" },
  { key: "time13", value: "06:00", text: "06:00" },
  { key: "time14", value: "06:30", text: "06:30" },
  { key: "time15", value: "07:00", text: "07:00" },
  { key: "time16", value: "07:30", text: "07:30" },
  { key: "time17", value: "08:00", text: "08:00" },
  { key: "time18", value: "08:30", text: "08:30" },
  { key: "time19", value: "09:00", text: "09:00" },
  { key: "time20", value: "09:30", text: "09:30" },
  { key: "time21", value: "10:00", text: "10:00" },
  { key: "time22", value: "10:30", text: "10:30" },
  { key: "time23", value: "11:00", text: "11:00" },
  { key: "time24", value: "11:30", text: "11:30" },
  { key: "time25", value: "12:00", text: "12:00" },
  { key: "time26", value: "12:30", text: "12:30" },
  { key: "time27", value: "13:00", text: "13:00" },
  { key: "time28", value: "13:30", text: "13:30" },
  { key: "time29", value: "14:00", text: "14:00" },
  { key: "time30", value: "14:30", text: "14:30" },
  { key: "time31", value: "15:00", text: "15:00" },
  { key: "time32", value: "15:30", text: "15:30" },
  { key: "time33", value: "16:00", text: "16:00" },
  { key: "time34", value: "16:30", text: "16:30" },
  { key: "time35", value: "17:00", text: "17:00" },
  { key: "time36", value: "17:30", text: "17:30" },
  { key: "time37", value: "18:00", text: "18:00" },
  { key: "time38", value: "18:30", text: "18:30" },
  { key: "time39", value: "19:00", text: "19:00" },
  { key: "time40", value: "19:30", text: "19:30" },
  { key: "time41", value: "20:00", text: "20:00" },
  { key: "time42", value: "20:30", text: "20:30" },
  { key: "time43", value: "21:00", text: "21:00" },
  { key: "time44", value: "21:30", text: "21:30" },
  { key: "time45", value: "22:00", text: "22:00" },
  { key: "time46", value: "22:30", text: "22:30" },
  { key: "time47", value: "23:00", text: "23:00" },
  { key: "time48", value: "23:30", text: "23:30" },
];

const daysOfTheWeekArr = values(DayOfWeek);

const google = window.google;
const startCoordinates = new google.maps.LatLng(-26.0986489, 27.9297368);
const markers = [];

const checkFileType = (file) => {
  if (
    file.type === "image/jpg" ||
    file.type === "image/png" ||
    file.type === "image/jpeg"
  ) {
    return true;
  }
  return false;
};
const checkFileSize = (file) => {
  //500 kilobytes
  if (file.size <= 500000) {
    return true;
  }
  return false;
};
const checkFileSizeCover = (file) => {
  //1000 kilobytes
  if (file.size <= 1000000) {
    return true;
  }
  return false;
};

const RegisterBusiness = ({ business = null, history }) => {
  // state
  const [map, setMap] = useState(null);
  const [places, setPlaces] = useState([]);
  const [placesService, setPlacesService] = useState(null);
  const [loadingGooglePlaces, setLoadingGooglePlaces] = useState(false);
  const [loadingEditBusiness, setLoadingEditBusiness] = useState(false);
  const [componentHasMounted, setComponenthasMounted] = useState(false);

  const [categories, setCategories] = useState([]);
  const [features, setFeatures] = useState([]);

  const [loadingPageData, setLoadingPageData] = useState(false);

  // context
  const stepperContext = useContext(StepperContext);
  const { addToast } = useContext(ToastContext);
  const { isLoggedIn } = useContext(LoggedInContext);

  const form = useFormik({
    initialValues: {
      businessName: "",
      businessEmail: "",
      businessPhone: "",
      businessWebsite: "",
      businessDescription: "",
      placeSearch: "",
      businessAddress: "",
      latitude: "",
      longitude: "",
      categories: [],
      features: [],
      daysOfTheWeek: [
        {
          day: DayOfWeek.MONDAY,
          isToggled: true,
          operatingTimes: [{ id: 0, openingTime: "", closingTime: "" }],
        },
        {
          day: DayOfWeek.TUESDAY,
          isToggled: true,
          operatingTimes: [{ id: 0, openingTime: "", closingTime: "" }],
        },
        {
          day: DayOfWeek.WEDNESDAY,
          isToggled: true,
          operatingTimes: [{ id: 0, openingTime: "", closingTime: "" }],
        },
        {
          day: DayOfWeek.THURSDAY,
          isToggled: true,
          operatingTimes: [{ id: 0, openingTime: "", closingTime: "" }],
        },
        {
          day: DayOfWeek.FRIDAY,
          isToggled: true,
          operatingTimes: [{ id: 0, openingTime: "", closingTime: "" }],
        },
        {
          day: DayOfWeek.SATURDAY,
          isToggled: false,
          operatingTimes: [{ id: 0, openingTime: "", closingTime: "" }],
        },
        {
          day: DayOfWeek.SUNDAY,
          isToggled: false,
          operatingTimes: [{ id: 0, openingTime: "", closingTime: "" }],
        },
      ],
      socialMedias: [
        {
          platform: SocialMediaPlatform.Twitter,
          website: "",
          isToggled: true,
        },
        {
          platform: SocialMediaPlatform.Facebook,
          website: "",
          isToggled: true,
        },
        {
          platform: SocialMediaPlatform.Instagram,
          website: "",
          isToggled: true,
        },
        {
          platform: SocialMediaPlatform.Pinterest,
          website: "",
          isToggled: true,
        },
        {
          platform: SocialMediaPlatform.Youtube,
          website: "",
          isToggled: true,
        },
      ],
      logo: { src: "", file: null },
      cover: { src: "", file: null },
      gallery: [],
    },
    validationSchema: () => {
      let schema = {
        businessName: yup.string().required("A business name is required"),
        businessEmail: yup
          .string()
          .required("A business email is required")
          .email("Must be a legitimate email"),
        businessPhone: yup
          .string()
          .required("A business phone number is required")
          .min(10, "Phone number must be at least 10 characters long")
          .max(15, "Phone number can not be longer than 15 characters"),
        businessWebsite: yup.string().url("Must be a legitimate URL"),
        businessDescription: yup
          .string()
          .required("A business description is required")
          .max(1000, "The business description can only have 1000 characters."),
        // businessAddress: yup.string().required("A business address is required"),
        latitude: yup.number().required("A business latitude is required"),
        longitude: yup.number().required("A business longitude is required"),
        categories: yup
          .array()
          .of(
            yup.object({
              name: yup.string().required("A category name is required."),
              id: yup.number().required("A category id is required."),
            })
          )
          .min(1, "Selecting at least 1 category is required"),
        features: yup.array().of(
          yup.object({
            name: yup.string().required("A feature name is required"),
            id: yup.number().required("A feature id is required"),
          })
        ),
        // .min(1, "Selecting at least 1 feature is required"),
        daysOfTheWeek: yup
          .array()
          .of(
            yup.object({
              day: yup
                .string()
                .required("A day of the week is required")
                .oneOf([
                  DayOfWeek.MONDAY,
                  DayOfWeek.TUESDAY,
                  DayOfWeek.WEDNESDAY,
                  DayOfWeek.THURSDAY,
                  DayOfWeek.FRIDAY,
                  DayOfWeek.SATURDAY,
                  DayOfWeek.SUNDAY,
                ]),
              isToggled: yup.boolean().required("A toggle value is required"),
              operatingTimes: yup.array().when("isToggled", {
                is: true,
                then: yup
                  .array()
                  .of(
                    yup.object({
                      id: yup
                        .number()
                        .required("An operating hour ID is required"),
                      openingTime: yup
                        .string()
                        .required("An opening time is required"),
                      closingTime: yup
                        .string()
                        .required("A closing time is required.")
                        .test(
                          "earlierThanClosingTime",
                          "The closing time must come after the opening time.",
                          function (closingTime) {
                            const { openingTime } = this.parent;

                            if (openingTime) {
                              const openingTimeAsDate = Date.parse(
                                `01/01/2021 ${openingTime}:00`
                              );
                              const closingTimeAsDate = Date.parse(
                                `01/01/2021 ${closingTime}:00`
                              );

                              return !gte(openingTimeAsDate, closingTimeAsDate);
                            }
                            return true;
                          }
                        ),
                    })
                  )
                  .test("earlierThanPreviousClose", function (operatingTimes) {
                    if (operatingTimes.length > 1) {
                      const sortedOperatingTimesDesc = pipe(
                        sortBy(prop("id")),
                        reverse
                      )(operatingTimes);

                      const currentOpeningTimeAsDate = Date.parse(
                        `01/01/2021 ${prop("openingTime")(
                          sortedOperatingTimesDesc[0]
                        )}:00`
                      );

                      const previousClosingTimeAsDate = Date.parse(
                        `01/01/2021 ${prop("closingTime")(
                          sortedOperatingTimesDesc[1]
                        )}:00`
                      );

                      if (
                        lte(currentOpeningTimeAsDate, previousClosingTimeAsDate)
                      ) {
                        return this.createError({
                          message:
                            "This opening time must come after the previous closing time",
                          path: `daysOfTheWeek[${findIndex(
                            propEq("day", this.parent.day),
                            form.values.daysOfTheWeek
                          )}].operatingTimes[${
                            sortedOperatingTimesDesc[0].id
                          }].openingTime`,
                        });
                      }
                    }
                    return true;
                  }),
                otherwise: yup.array().notRequired(),
              }),
            })
          )
          .test(
            "atleast-one-toggled",
            "At least 1 operating time must be selected",
            function (daysOfTheWeek) {
              const toggledDaysOfTheWeek = filter(
                propEq("isToggled", true),
                daysOfTheWeek
              );

              return !isEmpty(toggledDaysOfTheWeek);
            }
          ),
        socialMedias: yup.array().of(
          yup.object({
            platform: yup.string().required("A platform is required."),
            isToggled: yup.bool().required("A toggle value is required"),
            website: yup
              .string()
              .url("Must be a legitimate URL")
              .when("isToggled", {
                is: true,
                then: yup
                  .string()
                  .required(
                    "The website is required since it has been toggled."
                  ),
                otherwise: yup.string().notRequired(),
              }),
          })
        ),
      };
      schema.logo = yup.object({
        src: yup.string().required("A logo image is required"),
        file: yup.mixed(),
      });
      schema.cover = yup.object({
        src: yup.string().required("A cover image is required"),
        file: yup.mixed(),
      });
      schema.gallery = yup
        .array()
        .of(
          yup.object({
            src: yup.string().required(),
            file: yup.mixed(),
          })
        )
        .min(1, "At least 1 gallery image is required")
        .max(5, "Only 5 gallery images can be selected per business");
      return yup.object(schema);
    },
    onSubmit: async (values) => {
      if (!business) {
        try {
          if (!isLoggedIn) {
            addToast(
              ToastType.Warn,
              "You must login to preview your business listing."
            );
            return;
          }

          const loggedInRes = await query1(`businessNoFilter(name: "${
            values.businessName
          }", userId: ${getUserId()}) {
            user {
              id
            }
          }`);

          // eslint-disable-next-line eqeqeq
          if (loggedInRes.data.businessNoFilter?.user?.id == getUserId()) {
            addToast(
              ToastType.Warn,
              "You already have a business listing with the same name. Check your profile to renew the subscription."
            );
            return;
          }
          stepperContext.setState((prev) => assoc("formValues", values, prev));
          stepperContext.setActiveContent(
            <Business
              isPreview
              name={values.businessName}
              email={values.businessEmail}
              phoneNumber={values.businessPhone}
              description={values.businessDescription}
              categories={values.categories}
              features={values.features}
              operatingTimes={pipe(
                filter(propEq("isToggled", true)),
                Rmap((dayOfTheWeek) => {
                  const { day, operatingTimes } = dayOfTheWeek;
                  return Rmap(
                    ({ openingTime, closingTime }) => ({
                      day,
                      openingTime,
                      closingTime,
                    }),
                    operatingTimes
                  );
                }),
                flatten
              )(values.daysOfTheWeek)}
              socialMedias={values.socialMedias}
              logo={values.logo.src}
              cover={values.cover.src}
              gallery={Rmap(prop("src"))(values.gallery)}
              website={values.businessWebsite}
              latitude={values.latitude}
              longitude={values.longitude}
            />
          );
          stepperContext.setActiveStep(inc(stepperContext.activeStep));
        } catch (err) {
          addToast(ToastType.Error, ToastMessage.FAILURE);
        }
      } else {
        setLoadingEditBusiness(true);
        try {
          await updateBusiness(form.values, business);
          addToast(
            ToastType.Success,
            `Your business: ${form.values.businessName} has been upated. If you've changed any pictures it may take a couple hours to reflect.`
          );
          history.push(`/profile/${getUserId()}`);
        } catch (err) {
          console.error(err);
          addToast(ToastType.Error, ToastMessage.FAILURE);
        } finally {
          setLoadingEditBusiness(false);
        }
      }
    },
  });

  useEffect(() => {
    setComponenthasMounted(true);
    setLoadingPageData(true);
    (async () => {
      try {
        const {
          data: { categories, features },
        } = await query1(`
        categories {
          id
          name
          parentCategory {
            id
            name
          }
        }
        features {
          name
          id
        }`);

        const groupedCategories = groupBy(
          (x) => (!isNil(x.parentCategory) ? `${x.parentCategory.id}` : null),
          categories
        );
        const parentCategories = sortBy(
          ascend(prop("name")),
          groupedCategories[null]
        );

        const newCategories = [];
        forEach((parentCategory) => {
          newCategories.push(parentCategory);
          const childCategories = sortBy(prop("name"))(
            groupedCategories[prop("id", parentCategory)] ?? []
          );
          forEach(
            (childCategory) => newCategories.push(childCategory),
            childCategories
          );
        }, parentCategories);

        setCategories(
          indexedMap(
            ({ id, name }, i) => ({
              key: `category-${id}-${name}-${i}`,
              value: id,
              text: name,
              children: groupedCategories[id] ?? [],
            }),
            newCategories
          )
        );
        setFeatures(
          indexedMap(
            ({ id, name }, i) => ({
              key: `feature-${id}-${name}-${i}`,
              value: id,
              text: name,
            }),
            features
          )
        );
      } catch (err) {
        console.error(err);
        addToast(ToastType.Error, ToastMessage.FAILURE);
      } finally {
        setLoadingPageData(false);
      }
    })();
  }, [addToast]);

  useEffect(() => {
    (async () => {
      if (!!business && !!map && componentHasMounted) {
        await form.setValues(
          (prev) => ({
            ...prev,
            businessName: business.name,
            businessEmail: business.email,
            businessPhone: business.phoneNumber,
            businessWebsite: business.website ?? "",
            businessDescription: business.description,
            businessAddress: business.adress,
            latitude: business.latitude,
            longitude: business.longitude,
            categories: business.categories,
            features: business.features,
            daysOfTheWeek: Rmap((dayOfTheWeek) => {
              const day = dayOfTheWeek;
              const filteredOperatingTimes = filter(
                propEq("day", dayOfTheWeek),
                business.operatingTimes
              );
              const isToggled = !isEmpty(filteredOperatingTimes);
              return {
                day,
                isToggled,
                operatingTimes: !isEmpty(filteredOperatingTimes)
                  ? indexedMap(
                      ({ openingTime, closingTime }, i) => ({
                        id: i,
                        openingTime,
                        closingTime,
                      }),
                      filteredOperatingTimes
                    )
                  : append(
                      { id: 0, openingTime: "", closingTime: "" },
                      filteredOperatingTimes
                    ),
              };
            }, daysOfTheWeekArr),
            logo: {
              src: find(propEq("type", BusinessImageType.Logo), business.images)
                .url,
              file: null,
            },
            cover: {
              src: find(
                propEq("type", BusinessImageType.Cover),
                business.images
              ).url,
              file: null,
            },
            gallery: pipe(
              filter(propEq("type", BusinessImageType.Gallery)),
              sort(prop("url")),
              Rmap((x) => ({ src: x.url, file: null }))
            )(business.images),
          }),
          false
        );

        indexedForEach(async (x, i) => {
          const socialMedia = find((y) => y.platform === x.platform)(
            business.socialMedias
          );
          if (!!socialMedia) {
            await form.setFieldValue(
              `socialMedias[${i}].website`,
              socialMedia.website,
              true
            );
          } else {
            await form.setFieldValue(
              `socialMedias[${i}].isToggled`,
              false,
              true
            );
          }
        })(form.values.socialMedias);

        const latlng = new google.maps.LatLng(
          parseFloat(business.latitude),
          parseFloat(business.longitude)
        );
        markers.push(
          new google.maps.Marker({
            position: latlng,
            title: `Latitude: ${latlng.lat()}; Longitude: ${latlng.lng()}`,
            map: map,
          })
        );
        map.panTo(latlng);
      }
    })();
    //eslint-disable-next-line
  }, [business, map, componentHasMounted]);

  useEffect(() => {
    if (!!stepperContext.state?.formValues) {
      form.setValues((prev) =>
        mergeRight(prev, stepperContext.state.formValues)
      );
    }
    //eslint-disable-next-line
  }, [stepperContext.activeStep, stepperContext.state?.formValues]);

  useEffect(() => {
    const mapState = {
      center: startCoordinates,
      zoom: 15,
      mapTypeID: google.maps.MapTypeId.ROADMAP,
    };

    const tempMap = new google.maps.Map(
      document.getElementById("map"),
      mapState
    );
    tempMap.addListener(
      "click",
      async (e) =>
        await placeMarkerAndPanTo({
          latlng: e.latLng,
          map: tempMap,
          markers,
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
        })
    );
    setPlacesService(new google.maps.places.PlacesService(tempMap));
    setMap(tempMap);
  }, [form.setFieldValue, form.setFieldTouched]);

  // useEffect(() => {
  //   console.log("errors: ", form.errors);
  // }, [form.errors]);

  // useEffect(() => {
  //   console.log("values: ", form.values);
  // }, [form.values]);

  // useEffect(() => {
  //   console.log("touched: ", form.touched);
  // }, [form.touched]);

  const displayLogo = async ({ target: { files } }) => {
    const file = files[0];

    const fileReader = new FileReader();

    fileReader.onload = async (e) => {
      await form.setFieldValue(
        "logo",
        {
          file,
          src: e.target.result,
        },
        true
      );
    };

    fileReader.onerror = (e) => {
      console.error("Could not read the file");
    };

    if (file) {
      if (checkFileType(file)) {
        if (checkFileSize(file)) {
          fileReader.readAsDataURL(file);
        } else {
          addToast(
            ToastType.Error,
            "Selected image must be 500 kilobytes or smaller."
          );
        }
      } else {
        addToast(
          ToastType.Error,
          "Selected image must be either a png or jpg."
        );
      }
    }
  };

  const displayCover = async ({ target: { files } }) => {
    const file = files[0];

    const fileReader = new FileReader();

    fileReader.onload = async (e) => {
      await form.setFieldValue(
        "cover",
        {
          file,
          src: e.target.result,
        },
        true
      );
    };

    fileReader.onerror = (e) => {
      console.error("Could not read the file");
    };

    if (file) {
      if (checkFileType(file)) {
        if (checkFileSizeCover(file)) {
          fileReader.readAsDataURL(file);
        } else {
          addToast(
            ToastType.Error,
            "Selected image must be 1000 kilobytes or smaller."
          );
        }
      } else {
        addToast(
          ToastType.Error,
          "Selected image must be either a png or jpg."
        );
      }
    }
  };

  const displayGallery = async ({ target: { files } }) => {
    const file = files[0];
    const currentGallerySize = form.values.gallery.length;
    const fileReader = new FileReader();

    fileReader.onload = async (e) => {
      await form.setFieldValue(
        `gallery[${currentGallerySize}]`,
        {
          file,
          src: e.target.result,
        },
        false
      );
    };

    fileReader.onerror = (e) => {
      console.error("Could not read the file");
    };

    if (file && form.values.gallery.length < 5) {
      if (checkFileType(file)) {
        if (checkFileSize(file)) {
          fileReader.readAsDataURL(file);
        } else {
          addToast(
            ToastType.Error,
            "Selected image must be 500 kilobytes or smaller."
          );
        }
      } else {
        addToast(
          ToastType.Error,
          "Selected image must be either a png or jpg."
        );
      }
    } else {
      form.setFieldError(
        `gallery`,
        "Only 5 gallery images can be selected per business"
      );
    }
  };

  const handleDropdownSelect = async ({ value, name }) => {
    await form.setFieldValue(name, value);
  };
  const handleDropdownBlur = async ({ name }) => {
    await form.setFieldTouched(name, true, false);
  };

  return (
    <FormikProvider value={form}>
      <form
        onSubmit={form.handleSubmit}
        className={joinStrings("flex flex-col space-y-10 py-10", "lg:mx-20")}
      >
        <div
          className={joinStrings(
            "flex flex-col space-y-5 p-10 bg-white shadow-lg rounded-lg",
            "transition-shadow duration-300",
            "hover:shadow-2xl"
          )}
        >
          <div className="flex flex-col">
            <h4 className="text-gray-700 font-semibold">Business Details</h4>
            <p className="text-gray-400">Information about your business.</p>
          </div>

          <div
            className={joinStrings(
              "flex w-full flex-col space-y-5",
              "md:flex-row md:space-x-5 md:space-y-0"
            )}
          >
            <ShadowInput
              value={form.values.businessName}
              id="businessName"
              name="businessName"
              error={form.errors.businessName}
              touched={form.touched.businessName}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              placeHolder="Business Name *"
              className="md:w-2/5"
              // label="Business Name"
              icon={<IoBusinessSharp className="text-2xl text-gray-400" />}
            />
            <ShadowInput
              value={form.values.businessEmail}
              id="businessEmail"
              name="businessEmail"
              error={form.errors.businessEmail}
              touched={form.touched.businessEmail}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              type="email"
              placeHolder="Business Email *"
              className="flex-grow"
              // label="Business Email"
            />
          </div>

          <div
            className={joinStrings(
              "flex w-full flex-col space-y-5",
              "md:flex-row md:space-x-5 md:space-y-0"
            )}
          >
            <ShadowInput
              value={form.values.businessPhone}
              id="businessPhone"
              name="businessPhone"
              error={form.errors.businessPhone}
              touched={form.touched.businessPhone}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              placeHolder="Business Phone *"
              className="md:w-2/5"
              icon={<FaPhone className="text-xl text-gray-400" />}
              // label="Business Phone"
            />

            <ShadowInput
              value={form.values.businessWebsite}
              id="businessWebsite"
              name="businessWebsite"
              error={form.errors.businessWebsite}
              touched={form.touched.businessWebsite}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              placeHolder="Business Website"
              className="flex-grow"
              icon={<CgWebsite className="text-2xl text-gray-400" />}
              // label="Business Phone"
            />
          </div>

          <ShadowTextArea
            value={form.values.businessDescription}
            id={"businessDescription"}
            name="businessDescription"
            error={form.errors.businessDescription}
            touched={form.touched.businessDescription}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeHolder="Description *"
            className="w-full"
            rows={5}
            // label="Description"
          />

          {/* google map search */}
          <ShadowSearch
            value={form.values.placeSearch}
            id="placeSearch"
            name="placeSearch"
            placeHolder="Location (Physical Address)"
            className="md:w-full"
            options={places}
            onChange={async (e) => {
              form.setFieldValue(e.target.name, e.target.value);
              handleGoogleMapSearchChange({
                setPlaces,
                placesService,
                placeSearch: e.target.value,
                setLoadingGooglePlaces,
              });
            }}
            onSelect={handlePlaceSelect({
              map,
              markers,
              setFieldValue: form.setFieldValue,
              setFieldTouched: form.setFieldTouched,
            })}
            loading={loadingGooglePlaces}
            icons={[<MdLocationOn className=" w-6 h-6" />]}
          />

          {form.errors.latitude &&
            form.errors.longitude &&
            form.touched.latitude &&
            form.touched.longitude && (
              <Message type={MessageType.Error}>
                Selecting a pin drop or address is required.
              </Message>
            )}

          {/* google map */}
          <div id="map" className="w-full h-96 rounded-lg"></div>
        </div>

        {/* categories and features */}
        <div
          className={joinStrings(
            "flex flex-col space-y-5 rounded-lg bg-white shadow-lg p-10",
            "transition-shadow duration-300",
            "hover:shadow-2xl"
          )}
        >
          {loadingPageData && <LogoLoader />}
          {!loadingPageData && (
            <>
              <div className="flex flex-col">
                <h4 className="text-gray-700 font-semibold">
                  Categories and Features
                </h4>
                <p className="text-gray-400">
                  Select categories that best describe your business and
                  features that you offer.
                </p>
              </div>

              {/* categories dropdown */}
              <div className="flex flex-col">
                <h6>Select your categories:</h6>
                <FieldArray
                  name="categories"
                  render={(arrayHelpers) => (
                    <>
                      <RegisterSelect
                        useChildren
                        name={`categories[${form.values.categories.length}]`}
                        text="Categories"
                        className="w-64 mt-2"
                        onSelect={({ value, text }) => {
                          if (
                            !includes(
                              { id: value, name: text },
                              form.values.categories
                            )
                          ) {
                            arrayHelpers.push({ id: value, name: text });
                          }
                        }}
                        options={categories}
                        textIcon={
                          <FiPackage className="text-xl text-cyan-600" />
                        }
                        onBlur={() => {
                          form.setFieldTouched("categories", true, false);
                        }}
                        error={form.errors.categories}
                        touched={form.touched.categories}
                      />
                      <div className="flex flex-wrap items-center mt-5">
                        {form.values.categories.map(({ name }, i) => (
                          <Fragment>
                            <span
                              key={`form-value-category-${i}-${name}`}
                              className="flex items-center space-x-2 px-5 py-1 bg-white-800 border border-gray-300 rounded-full text-gray-600 mr-3"
                            >
                              <span>{name}</span>
                              <button
                                type="button"
                                className="btn"
                                onClick={async () => {
                                  arrayHelpers.remove(i);
                                  await form.setFieldTouched(
                                    "categories",
                                    true,
                                    false
                                  );
                                }}
                              >
                                <IoClose className="text-xl text-red-600 cursor-pointer" />
                              </button>
                            </span>
                          </Fragment>
                        ))}
                      </div>
                    </>
                  )}
                />
              </div>

              {/* features dropdown */}
              <div className="flex flex-col">
                <h6>Select your features:</h6>
                <FieldArray
                  name="features"
                  render={(arrayHelpers) => (
                    <>
                      <RegisterSelect
                        name={`features[${form.values.features.length}]`}
                        text="Featues"
                        className="w-64 mt-2"
                        options={features}
                        onSelect={({ value, text }) => {
                          if (
                            !includes(
                              { id: value, name: text },
                              form.values.features
                            )
                          ) {
                            arrayHelpers.push({ id: value, name: text });
                          }
                        }}
                        textIcon={
                          <HiOutlineClipboardList className="text-xl text-cyan-600" />
                        }
                        onBlur={handleDropdownBlur}
                      />
                      <div className="flex flex-wrap items-center mt-5">
                        {form.values.features.map(({ name }, i) => (
                          <Fragment>
                            <span
                              key={`form-value-feature-${i}-${name}`}
                              className="flex items-center space-x-2 px-5 py-1 bg-white-800 border border-gray-300 rounded-full text-gray-600 mr-3"
                            >
                              <span>{name}</span>
                              <button
                                type="button"
                                className="btn"
                                onClick={() => arrayHelpers.remove(i)}
                              >
                                <IoClose className="text-xl text-red-600 cursor-pointer" />
                              </button>
                            </span>
                          </Fragment>
                        ))}
                      </div>
                    </>
                  )}
                />
              </div>
            </>
          )}
        </div>

        {/* operating hours */}
        <div
          className={joinStrings(
            "flex flex-col space-y-5 rounded-lg bg-white shadow-lg p-10",
            "transition-shadow duration-300",
            "hover:shadow-2xl"
          )}
        >
          <div className="flex flex-col">
            <h4 className="font-semibold text-gray-700">Trading Hours</h4>
            <p className="text-gray-400">
              Let your customers know when you're open.
            </p>
          </div>

          {!!form.errors.daysOfTheWeek &&
            typeof form.errors.daysOfTheWeek === "string" && (
              <Message type={MessageType.Error}>
                {form.errors.daysOfTheWeek}
              </Message>
            )}
          <div className="inline-flex flex-col space-y-3">
            {/* headers */}
            {/* <div className="flex space-x-10">
              <div> </div>
              <div className="font-bold text-lg">Opening Time</div>
              <div className="font-bold text-lg">Closing Time</div>
            </div> */}

            {/* operating hour dropdowns */}
            {form.values.daysOfTheWeek.map(
              ({ day, isToggled, operatingTimes }, index) => {
                const idOfHighestOperatingHour = pipe(
                  sortBy(prop("id")),
                  reverse,
                  take(1),
                  pluck("id")
                )(operatingTimes)[0];
                return (
                  <Fragment key={`days-of-the-week-${index}-${day}`}>
                    <FieldArray
                      name={`daysOfTheWeek[${index}].operatingTimes`}
                      render={(arrayHelpers) => (
                        <div
                          className={joinStrings(
                            "flex flex-col items-center rounded-lg space-y-3 py-2",
                            "lg:space-x-10 lg:space-y-0 lg:flex-row"
                          )}
                        >
                          <div
                            className={joinStrings(
                              "flex items-center justify-between w-full text-center border-b space-x-3 self-start bg-gray-100 rounded-lg px-3 py-2",
                              "lg:w-64 lg:border-b-0 lg:space-x-3"
                            )}
                          >
                            <div className="flex justify-between w-full items-center">
                              <p>{day}</p>
                              <Toggle
                                checked={isToggled}
                                onClick={() => {
                                  form.setFieldValue(
                                    `daysOfTheWeek[${index}].isToggled`,
                                    !isToggled
                                  );
                                }}
                              />
                            </div>

                            <button
                              type="button"
                              className={joinStrings(
                                `text-xl`,
                                `focus:outline-none`,
                                !isAddOperatingHourDisabled({
                                  form,
                                  dayIndex: index,
                                  idOfHighestOperatingHour,
                                })
                                  ? `text-cyan-600`
                                  : `text-gray-300 cursor-default`
                              )}
                              disabled={isAddOperatingHourDisabled({
                                form,
                                dayIndex: index,
                                idOfHighestOperatingHour,
                              })}
                              onClick={() => {
                                arrayHelpers.push({
                                  id: operatingTimes.length,
                                  day,
                                  openingTime: "",
                                  closingTime: "",
                                });
                              }}
                            >
                              <BsPlusSquareFill className="rounded-full" />
                            </button>
                          </div>

                          {!isToggled && (
                            <div className="bg-gray-50 rounded-lg py-2 px-4 mr-auto w-full">
                              <p>Closed</p>
                            </div>
                          )}
                          {isToggled && (
                            <div
                              className={joinStrings(
                                "flex flex-col w-full space-y-2 ",
                                "lg:space-y-3"
                              )}
                            >
                              {operatingTimes.map(
                                ({ id, openingTime, closingTime }, i) => {
                                  return (
                                    <div
                                      key={`operating-time-${id}-flexbox-${i}-for-day-index-${index}`}
                                      className="flex space-x-5 rounded-lg items-center"
                                    >
                                      <RegisterSelect
                                        name={`daysOfTheWeek[${index}].operatingTimes[${id}].openingTime`}
                                        text="Opening Time"
                                        options={times}
                                        className="w-full"
                                        onSelect={handleDropdownSelect}
                                        value={openingTime}
                                        onBlur={handleDropdownBlur}
                                        error={getIn(
                                          form.errors,
                                          `daysOfTheWeek[${index}].operatingTimes[${id}].openingTime`
                                        )}
                                        touched={getIn(
                                          form.touched,
                                          `daysOfTheWeek[${index}].operatingTimes[${id}].openingTime`
                                        )}
                                        icons={[<BiTimeFive />]}
                                        disabled={
                                          id !== idOfHighestOperatingHour
                                        }
                                      />

                                      <RegisterSelect
                                        key={`${day} closing time ${id}`}
                                        name={`daysOfTheWeek[${index}].operatingTimes[${id}].closingTime`}
                                        text="Closing Time"
                                        options={times}
                                        className="w-full"
                                        onSelect={handleDropdownSelect}
                                        value={closingTime}
                                        onBlur={handleDropdownBlur}
                                        error={getIn(
                                          form.errors,
                                          `daysOfTheWeek[${index}].operatingTimes[${id}].closingTime`
                                        )}
                                        touched={getIn(
                                          form.touched,
                                          `daysOfTheWeek[${index}].operatingTimes[${id}].closingTime`
                                        )}
                                        icons={[<BiTimeFive />]}
                                        disabled={
                                          id !== idOfHighestOperatingHour
                                        }
                                      />

                                      {(i !== operatingTimes.length - 1 ||
                                        operatingTimes.length === 1) && (
                                        <div
                                          className="text-red-600 rounded-lg cursor-pointer self-center text-2xl invisible"
                                          onClick={() =>
                                            arrayHelpers.remove(
                                              idOfHighestOperatingHour
                                            )
                                          }
                                        >
                                          <IoClose />
                                        </div>
                                      )}
                                      {i === operatingTimes.length - 1 &&
                                        operatingTimes.length > 1 && (
                                          <div
                                            className="text-red-600 rounded-lg cursor-pointer self-center text-2xl"
                                            onClick={() =>
                                              arrayHelpers.remove(
                                                idOfHighestOperatingHour
                                              )
                                            }
                                          >
                                            <IoClose />
                                          </div>
                                        )}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </Fragment>
                );
              }
            )}
          </div>
        </div>

        {/* social medias */}
        <div
          className={joinStrings(
            "flex flex-col space-y-5 rounded-lg bg-white shadow-lg p-10",
            "transition-shadow duration-300",
            "hover:shadow-2xl"
          )}
        >
          <div className="flex flex-col">
            <h4 className="font-semibold text-gray-700">Social Media</h4>
            <p className="text-gray-400">
              Let your customers see your social media business pages.
            </p>
          </div>

          <div className="flex flex-col space-y-5">
            {form.values.socialMedias.map(
              ({ platform, isToggled, website }, i) => (
                <div
                  key={`social-media-platform-${platform}-${i}`}
                  className={joinStrings(
                    "flex flex-col space-x-0 space-y-3 items-baseline"
                  )}
                >
                  <div
                    className={joinStrings(
                      "flex justify-between w-full items-center",
                      "lg:w-32"
                    )}
                  >
                    <div>{platform}</div>

                    <Toggle
                      checked={isToggled}
                      onClick={() => {
                        form.setFieldValue(
                          `socialMedias[${i}].isToggled`,
                          !isToggled
                        );
                      }}
                    />
                  </div>

                  <ShadowInput
                    id={`${platform}Website`}
                    name={`socialMedias[${i}].website`}
                    value={website}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    placeHolder={`${platform} page`}
                    className={joinStrings(
                      "transition-opacity duration-300 w-full",
                      "lg:w-full lg:flex-initial",
                      `${
                        isToggled
                          ? "opacity-100 visible"
                          : "opacity-0 invisible"
                      }`
                    )}
                    error={getIn(form.errors, `socialMedias[${i}].website`)}
                    touched={getIn(form.touched, `socialMedias[${i}].website`)}
                    key={`${platform}Website`}
                    icon={
                      <Fragment>
                        {platform === SocialMediaPlatform.Facebook && (
                          <AiFillFacebook className="text-3xl text-gray-400" />
                        )}
                        {platform === SocialMediaPlatform.Twitter && (
                          <FaTwitter className="text-2xl text-gray-400" />
                        )}
                        {platform === SocialMediaPlatform.Instagram && (
                          <GrInstagram className="text-2xl text-gray-400" />
                        )}

                        {platform === SocialMediaPlatform.Pinterest && (
                          <GrPinterest className="text-2xl text-gray-400" />
                        )}

                        {platform === SocialMediaPlatform.Youtube && (
                          <GrYoutube className="text-2xl text-gray-400" />
                        )}
                      </Fragment>
                    }
                  />
                </div>
              )
            )}
          </div>
        </div>

        {/* Images */}
        <div
          className={joinStrings(
            "flex flex-col space-y-5 rounded-lg bg-white shadow-lg p-10",
            "transition-shadow duration-300",
            "hover:shadow-2xl"
          )}
        >
          <div className="flex flex-col">
            <h4 className="font-semibold text-gray-700">Images</h4>
            <p className="text-gray-400">
              Let your customers see the pictures that define your business.
            </p>
          </div>

          <div className="flex flex-col space-y-5">
            {/* logo */}
            <div
              className={joinStrings(
                "flex flex-col space-x-0 items-center",
                "lg:flex-row lg: space-x-5"
              )}
            >
              <FileSelect
                onChange={displayLogo}
                className={joinStrings(
                  "border-2 border-dashed h-96 rounded-lg flex flex-col justify-center items-center cursor-pointer w-96 max-w-full",
                  "transition-all duration-300",
                  "hover:shadow-2xl hover:border-transparent",
                  !!form.values.logo.src
                    ? "border-transparent bg-black"
                    : "border-gray-400"
                )}
              >
                {!form.values.logo.src && (
                  <>
                    <BiImageAdd className="text-6xl text-gray-400" />
                    <div className="flex flex-col items-center">
                      <p className="text-cyan-500 font-semibold">
                        Upload A Logo
                      </p>
                      <p className="text-sm text-gray-500">PNG, JPG</p>
                      <p className="text-sm text-gray-500">
                        (Recommended size: 400x400)
                      </p>
                      <p className="text-sm text-gray-500">
                        (Max file size: 500KB)
                      </p>
                    </div>
                  </>
                )}
                <img
                  src={form.values.logo.src}
                  alt="logo"
                  className={joinStrings(
                    "",
                    !!form.values.logo.src ? "block" : "hidden"
                  )}
                />
              </FileSelect>
            </div>
            {!!form.errors.logo?.src && form.touched.logo?.src && (
              <Message type={MessageType.Error}>
                {form.errors.logo?.src}
              </Message>
            )}

            {/* cover */}
            <div
              className={joinStrings(
                "flex flex-col space-x-0",
                "lg:flex-row lg: space-x-5"
              )}
            >
              <FileSelect
                onChange={displayCover}
                className={joinStrings(
                  "border-2 border-dashed h-132 rounded-lg flex flex-col justify-center items-center cursor-pointer mx-auto w-full overflow-hidden",
                  "transition-all duration-300",
                  "hover:shadow-2xl hover:border-transparent",
                  !!form.values.cover.src
                    ? "border-transparent"
                    : "border-gray-400"
                )}
              >
                {!form.values.cover.src && (
                  <>
                    <BiImageAdd className="text-6xl text-gray-400" />
                    <div className="flex flex-col items-center">
                      <p className="text-cyan-500 font-semibold">
                        Upload A Cover
                      </p>
                      <p className="text-sm text-gray-500">PNG, JPG</p>
                      <p className="text-sm text-gray-500">
                        (Recommended size: 1200x630)
                      </p>
                      <p className="text-sm text-gray-500">
                        (Max file size: 1000KB)
                      </p>
                    </div>
                  </>
                )}

                <img
                  src={form.values.cover.src}
                  alt="cover"
                  className={joinStrings(
                    "w-full object-contain h-full",
                    !!form.values.cover.src ? "block" : "hidden"
                  )}
                />
              </FileSelect>
            </div>
            {!!form.errors.cover?.src &&
              form.touched.cover?.src &&
              "string" && (
                <Message type={MessageType.Error}>
                  {form.errors.cover?.src}
                </Message>
              )}

            {/* gallery */}
            <div className="">
              <FieldArray
                name="gallery"
                render={(arrayHelpers) => (
                  <Fragment>
                    <FileSelect
                      className={joinStrings(
                        "border-2 border-dashed border-gray-400 h-40 rounded-lg flex flex-col justify-center items-center cursor-pointer w-64",
                        "transition-all duration-300",
                        "hover:shadow-2xl hover:border-transparent",
                        "focus:outline-none"
                      )}
                      onChange={(e) => displayGallery(e, arrayHelpers)}
                    >
                      <BiImageAdd className="text-4xl text-gray-400" />
                      <div className="flex flex-col items-center">
                        <p className="text-cyan-500 font-semibold">
                          Upload A Gallery Picture
                        </p>
                        <p className="text-sm text-gray-500">
                          PNG, JPG (Maximum of 5)
                        </p>
                        <p className="text-sm text-gray-500">
                          (Recommended size: ANY)
                        </p>
                        <p className="text-sm text-gray-500">
                          (Max file size: 500KB)
                        </p>
                      </div>
                    </FileSelect>
                    {!!form.errors.gallery &&
                      form.touched.gallery &&
                      typeof form.errors.gallery === "string" && (
                        <Message type={MessageType.Error} className="mt-5">
                          {form.errors.gallery}
                        </Message>
                      )}
                    <div className="lg:px-20 mt-10">
                      <div className="h-132 mx-auto">
                        <RegisterCarousel
                          images={Rmap(prop("src"))(form.values.gallery)}
                          removeImage={(imageIndex) => {
                            // setImgGallery(remove(imageIndex, 1)(imgGallery));
                            // arrayHelpers.remove(imageIndex);
                            form.setFieldValue(
                              "gallery",
                              remove(imageIndex, 1, form.values.gallery)
                            );
                          }}
                          removable
                          swipeOnChange
                        />
                      </div>
                    </div>
                  </Fragment>
                )}
              />
            </div>
          </div>
        </div>

        <div className="flex space-x-5 justify-end flex-wrap">
          {!isEmpty(form.errors) && form.submitCount >= 1 && (
            <Message type={MessageType.Error}>
              There are issues with your provided fields. Please scroll up to
              see and resolve the issues.
            </Message>
          )}
          {!!business && (
            <SecondaryButton
              as="link"
              to={(() => {
                const userId = jwt_decode(getToken()).id;
                return `/profile/${userId}`;
              })()}
            >
              Cancel
            </SecondaryButton>
          )}
          {!business && (
            <SecondaryButton
              onClick={() => stepperContext.setActiveStep((prev) => dec(prev))}
            >
              Cancel
            </SecondaryButton>
          )}
          {!business && (
            <PrimaryButton
              type="submit"
              disabled={
                !business && !isEmpty(form.errors) && form.submitCount >= 1
              }
            >
              Preview
            </PrimaryButton>
          )}
          {!!business && (
            <PrimaryButton
              type="submit"
              loading={loadingEditBusiness}
              disabled={
                (!isEmpty(form.errors) && form.submitCount >= 1) ||
                loadingEditBusiness
              }
            >
              Save
            </PrimaryButton>
          )}
        </div>
      </form>
    </FormikProvider>
  );
};

export default withRouter(RegisterBusiness);
