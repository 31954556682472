import { motion } from "framer-motion";
import React, { Fragment, useEffect, useRef } from "react";
import { joinStrings } from "../utils/util-methods";

const animationTime = 0.2;

export const Modal = ({ children, open, setOpen, className }) => {
  const ref = useRef(null);

  useEffect(() => {
    open
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
  }, [open]);

  return (
    <Fragment>
      {open && (
        <motion.div
          animate={{
            opacity: 1,
            transition: {
              duration: 0.5,
            },
          }}
          initial={{ opacity: 0 }}
          className="fixed bg-black bg-opacity-50 z-60 inset-0"
          onMouseDown={(e) => {
            if (!ref?.current?.contains(e.target)) {
              setOpen(false);
            }
          }}
        >
          <div className="flex justify-center items-center px-3 py-10 max-w-full w-screen h-screen max-h-full">
            <motion.div
              animate={{
                y: 0,
                opacity: 1,
                transition: {
                  type: "spring",
                  mass: 1,
                  duration: animationTime,
                  delay: 0,
                },
              }}
              initial={{ y: 100, opacity: 0 }}
              ref={ref}
              className={joinStrings(
                "block h-auto max-h-full my-auto overflow-y-auto overflow-x-hidden w-full mx-auto",
                "sm:w-auto",
                className
              )}
            >
              {children}
            </motion.div>
          </div>
        </motion.div>
      )}
    </Fragment>
  );
};
