import { keys, map } from "ramda";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { BsArrowRight } from "react-icons/bs";
import { IoCheckmarkCircle } from "react-icons/io5";
import { ToastContext } from "../contexts/ToastContext";
import { query1 } from "../services/graphqlService";
import {
  generateSignature,
  getNextBillingDate,
  getPayfastAmount,
  getSubscriptionFrequency,
} from "../services/payfastService";
import { ToastMessage } from "../utils/enums/ToastMessage";
import { ToastType } from "../utils/enums/ToastType";
import { getEnvVariable, joinStrings } from "../utils/util-methods";
import { PrimaryButton } from "./PrimaryButton";

export const SimpleAdvertiseCard = ({
  heading,
  monthly,
  cost,
  className,
  subscriptionPackage,
  undraw,
  businessId,
  businessName,
  userId,
  firstName,
  lastName,
  email,
}) => {
  const [pfData] = useState({
    merchant_id: getEnvVariable("PAYFAST_MERCHANT_ID"),
    merchant_key: getEnvVariable("PAYFAST_MERCHANT_KEY"),
    return_url: "https://syncvine.co.za/payment-complete",
    cancel_url: `https://syncvine.co.za/profile/${userId}`,
    notify_url: `${getEnvVariable("API_URL")}/pfHook`,
    name_first: firstName,
    name_last: lastName,
    email_address: email,
    amount: getPayfastAmount(subscriptionPackage),
    item_name: "Business Listing",
    item_description: "Syncvine Business Listing",
    custom_int1: businessId, //businessId
    custom_int2: userId, //userId
    custom_str1: subscriptionPackage,
    subscription_type: "1", //requrired for subscriptions
    billing_date: getNextBillingDate(subscriptionPackage),
    recurring_amount: getPayfastAmount(subscriptionPackage),
    frequency: getSubscriptionFrequency(subscriptionPackage),
    cycles: "0", //indefinite subscription
  });
  const [pfSignature, setPfSignature] = useState("");
  const [loading, setLoading] = useState(false);

  const { addToast } = useContext(ToastContext);

  useEffect(() => {
    setPfSignature(
      generateSignature(pfData, getEnvVariable("PAYFAST_PASSPHRASE"))
    );
  }, [pfData]);

  const proceedToPayfast = useCallback(async () => {
    setLoading(true);
    try {
      const {
        data: { businessNoFilter },
      } = await query1(`businessNoFilter(name: "${businessName}", userId: ${userId}) {
        activatedAt
      }`);

      if (!!businessNoFilter?.activatedAt) {
        addToast(
          ToastType.Error,
          "This business already has an active subscription."
        );
        return;
      }

      buttonRef.current?.click();
    } catch (err) {
      addToast(ToastType.Error, ToastMessage.FAILURE);
    } finally {
      setLoading(false);
    }
  }, [addToast, businessName, userId]);

  // useEffect(() => {
  //   console.log('pfData: ', pfData);
  // }, [pfData])

  const buttonRef = useRef(null);
  return (
    <>
      <form
        action={getEnvVariable("PAYFAST_DOMAIN")}
        method="post"
        className="hidden"
      >
        {map(
          (key) => (
            <input
              key={key}
              name={key}
              type="hidden"
              value={`${pfData[key]?.trim()}`}
            />
          ),
          keys(pfData)
        )}
        <input
          key={"signature"}
          name={"signature"}
          type="hidden"
          value={`${pfSignature?.trim()}`}
        />
        <button id="payfastSubmit" type="submit" ref={buttonRef}>
          Pay now
        </button>
      </form>

      <div
        // border-2 border-blue-100
        className={joinStrings(
          "rounded-lg w-max max-w-2xl p-5 shadow-lg",
          // "sm:w-96",
          "transform duration-300",
          "group-hover:bg-gray-800",
          "hover:shadow-2xl",
          className
        )}
      >
        <h3
          className={joinStrings(
            " tracking-widest text-gray-600 text-center",
            "md:text-left",
            "group-hover:text-gray-400"
          )}
        >
          {heading}
        </h3>
        <div
          className={joinStrings(
            "flex flex-col items-center space-x-1 border-b border-gray-300 pb-3",
            "md:flex-row"
          )}
        >
          <h2
            className={joinStrings(
              "text-cyan-900 font-medium tracking-wide",
              "group-hover:text-white"
            )}
          >{`R${cost}.00`}</h2>
          <h4
            className={joinStrings("text-gray-400", "group-hover:text-white")}
          >
            {monthly}
          </h4>
        </div>

        <div
          className={joinStrings(
            "flex flex-col space-y-1 mt-3 text-cyan-900 mb-10",
            "group-hover:text-gray-300"
          )}
        >
          <div className="flex space-x-2 items-center text-lg">
            <IoCheckmarkCircle className="text-xl" />
            <span>1 Renewed Subscription</span>
          </div>

          <div className="flex space-x-2 items-center text-lg">
            <IoCheckmarkCircle className="text-xl" />
            <span>1 Daily facebook post</span>
          </div>

          <div className="flex space-x-2 items-center text-lg">
            <IoCheckmarkCircle className="text-xl" />
            <span>1 Renewed Business Listing</span>
          </div>
        </div>

        {!!undraw && undraw}
        <PrimaryButton
          loading={loading}
          disabled={loading}
          className={joinStrings(
            "flex items-center justify-between w-full mt-10",
            "group-hover:bg-gray-600 group-hover:text-white group-hover:border-gray-600"
          )}
          onClick={proceedToPayfast}
        >
          Proceed to payfast
          <BsArrowRight />
        </PrimaryButton>
      </div>
    </>
  );
};
