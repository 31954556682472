import { useFormik } from "formik";
import { assoc } from "ramda";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { HiOutlineUserCircle } from "react-icons/hi";
import { RiLockPasswordFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { LoggedInContext } from "../contexts/LoggedInContext";
import { ToastContext } from "../contexts/ToastContext";
import { mutation1, query1 } from "../services/graphqlService";
import { MessageType } from "../utils/enums/MessageType";
import { RegisterResponse } from "../utils/enums/RepisterReponse";
import { ToastType } from "../utils/enums/ToastType";
import { joinStrings } from "../utils/util-methods";
import { CloseButton } from "./CloseButton";
import { FacebookLogin } from "./FacebookLogin";
import { GoogleLogin } from "./GoogleLogin";
import { Message } from "./Message";
import { Modal } from "./Modal";
import { PrimaryButton } from "./PrimaryButton";
import { ShadowInput } from "./ShadowInput";

export const LoginModal = ({ open, setOpen }) => {
  //state
  const [isLogin, setIsLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loginErrors, setLoginErrors] = useState({
    enteredIncorrectDetails: false,
    unverifiedEmail: false,
  });
  const [registerErrors, setRegisterErrors] = useState({
    emailAlreadyExists: false,
  });
  const [registerName, setRegisterName] = useState("");

  //context
  const { addToast } = useContext(ToastContext);
  const loggedInContext = useContext(LoggedInContext);

  useEffect(() => {
    if (loggedInContext.isLoggedIn) {
      setOpen(false);
    }
  }, [loggedInContext.isLoggedIn, setOpen]);

  const registerForm = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirm: "",
    },
    validationSchema: yup.object({
      firstName: yup.string().required("Your first name is required"),
      lastName: yup.string().required("Your last name is required"),
      email: yup
        .string()
        .required("Your email is required")
        .email("Must be a legitimate email"),
      password: yup
        .string()
        .required("Your password is required")
        .min(8, "Your password must be at least 8 characters long."),
      passwordConfirm: yup
        .string()
        .required("Your password confirmation is required")
        .test(
          "passwords-match",
          "Your passwords must match",
          function (passwordConfirm) {
            return passwordConfirm === this.parent.password;
          }
        ),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const { data } = await mutation1(
          `createUser(data: {firstName: "${values.firstName}", lastName: "${values.lastName}", email: "${values.email}", password: "${values.password}"}) {
            item {
              firstName
            }
            message
          }`
        );
        if (data) {
          const { createUser } = data;
          const { item, message } = createUser;
          if (message === RegisterResponse.AlreadyExists) {
            setRegisterErrors((prev) =>
              assoc("emailAlreadyExists", true, prev)
            );
          } else if (
            message === RegisterResponse.Success ||
            message === RegisterResponse.Reactivation
          ) {
            setRegisterErrors({
              emailAlreadyExists: false,
            });
            setRegisterName(item.firstName);
            setIsLogin(true);
            registerForm.resetForm();
          }
        }
      } catch (err) {
        addToast(
          ToastType.Error,
          "Action failed. The server may be temporarily down. Please try again in a few minutes."
        );
      } finally {
        setLoading(false);
      }
    },
  });

  const loginForm = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .required("Your email is required")
        .email("Must be a legitimate email"),
      password: yup.string().required("Your password is required"),
    }),
    onSubmit: async ({ email, password }) => {
      setLoading(true);
      try {
        const { data } = await query1(
          `login(email: "${email}", password: "${password}")`
        );
        if (data) {
          const { login } = data;
          if (login === "Failure") {
            setLoading(false);
            setLoginErrors((prev) =>
              assoc("enteredIncorrectDetails", true, prev)
            );
          } else if (login === "Not verified") {
            setLoading(false);
            setLoginErrors((prev) => assoc("unverifiedEmail", true, prev));
          } else {
            localStorage.setItem("token", login);
            loggedInContext.login();
            loginForm.resetForm();
            setRegisterName("");
            setLoading(false);
            setOpen(false);
          }
        }
      } catch (err) {
        addToast(
          ToastType.Error,
          "Action failed. The server may be temporarily down. Please try again in a few minutes."
        );
        setLoading(false);
      }
    },
  });

  return (
    <Modal open={open} setOpen={setOpen}>
      <div
        className={joinStrings(
          "flex flex-col bg-white mx-auto my-auto overflow-y-auto rounded-lg",
          "sm:w-96"
        )}
      >
        <div className="flex py-4 px-5 bg-cyan-900">
          <div className="flex justify-center flex-1">
            <h6
              className={joinStrings(
                `px-4 py-2 cursor-pointer text-center text-white rounded`,
                "transition-colors duration-300",
                isLogin && "bg-cyan-600",
                `hover:bg-cyan-500`
              )}
              onClick={() => setIsLogin(true)}
            >
              Login
            </h6>
          </div>

          <div className="w-0.5 bg-gray-200"></div>

          <div className="flex justify-center flex-1">
            <h6
              className={joinStrings(
                `px-4 py-2 cursor-pointer text-center text-white rounded`,
                "transition-colors duration-300",
                `hover:bg-cyan-600`,
                !isLogin && "bg-cyan-600"
              )}
              onClick={() => setIsLogin(false)}
            >
              Register
            </h6>
          </div>
        </div>

        <div className="flex flex-col p-5 space-y-2">
          {isLogin && (
            <form
              onSubmit={loginForm.handleSubmit}
              className="flex flex-col space-y-5"
            >
              {loginErrors.enteredIncorrectDetails && (
                <Message type={MessageType.Error}>
                  Login failed. Incorrect email or password.
                </Message>
              )}
              {loginErrors.unverifiedEmail && (
                <Message type={MessageType.Error}>
                  Login failed. The email has not been verified.
                </Message>
              )}
              {registerName && (
                <Message type={MessageType.Info}>
                  {`Welcome ${registerName}. Check your email for a verification mail from us.`}
                </Message>
              )}
              <div className="flex flex-col space-y-4">
                <ShadowInput
                  placeHolder="Email"
                  id="email"
                  name="email"
                  type="email"
                  value={loginForm.values.email}
                  onChange={(e) => {
                    setLoginErrors((prev) =>
                      assoc("enteredIncorrectDetails", false, prev)
                    );
                    loginForm.handleChange(e);
                  }}
                  onBlur={loginForm.handleBlur}
                  error={loginForm.errors.email}
                  touched={loginForm.touched.email}
                  label="Email"
                />
                <ShadowInput
                  id="password"
                  name="password"
                  type="password"
                  value={loginForm.values.password}
                  onChange={(e) => {
                    setLoginErrors((prev) =>
                      assoc("enteredIncorrectDetails", false, prev)
                    );
                    loginForm.handleChange(e);
                  }}
                  onBlur={loginForm.handleBlur}
                  placeHolder="Password"
                  error={loginForm.errors.password}
                  touched={loginForm.touched.password}
                  label="Password"
                />
                <Link
                  to="/forgot-password"
                  className={joinStrings(
                    "underline cursor-pointer text-gray-600 text-sm self-end mr-3",
                    "transition-colors duration-500",
                    "hover:text-cyan-600"
                  )}
                  onClick={() => setOpen(false)}
                >
                  Forgot password?
                </Link>
              </div>

              <PrimaryButton type="submit" disabled={loading} loading={loading}>
                Login
              </PrimaryButton>
            </form>
          )}
          {!isLogin && (
            <form
              onSubmit={registerForm.handleSubmit}
              className="flex flex-col space-y-3"
            >
              <ShadowInput
                id="firstName"
                name="firstName"
                value={registerForm.values.firstName}
                onChange={registerForm.handleChange}
                onBlur={registerForm.handleBlur}
                error={registerForm.errors.firstName}
                touched={registerForm.touched.firstName}
                placeHolder="First Name"
                label="First Name"
                icon={
                  <HiOutlineUserCircle className="text-2xl text-gray-400" />
                }
              />
              <ShadowInput
                id="lastName"
                name="lastName"
                value={registerForm.values.lastName}
                onChange={registerForm.handleChange}
                onBlur={registerForm.handleBlur}
                error={registerForm.errors.lastName}
                touched={registerForm.touched.lastName}
                placeHolder="Last Name"
                label="Last Name"
                icon={
                  <HiOutlineUserCircle className="text-2xl text-gray-400" />
                }
              />
              <ShadowInput
                id="email"
                name="email"
                type="email"
                value={registerForm.values.email}
                onChange={(e) => {
                  setRegisterErrors((prev) =>
                    assoc("emailAlreadyExists", false, prev)
                  );
                  registerForm.handleChange(e);
                }}
                onBlur={registerForm.handleBlur}
                error={registerForm.errors.email}
                touched={registerForm.touched.email}
                placeHolder="Email"
                label="Email"
              />
              {registerErrors.emailAlreadyExists && (
                <Message type={MessageType.Error}>
                  This email already exists.
                </Message>
              )}
              <ShadowInput
                id="password"
                name="password"
                type="password"
                value={registerForm.values.password}
                onChange={registerForm.handleChange}
                onBlur={registerForm.handleBlur}
                error={registerForm.errors.password}
                touched={registerForm.touched.password}
                placeHolder="Password"
                label="Password"
              />
              <ShadowInput
                id="passwordConfirm"
                name="passwordConfirm"
                type="password"
                value={registerForm.values.passwordConfirm}
                onChange={registerForm.handleChange}
                onBlur={registerForm.handleBlur}
                error={registerForm.errors.passwordConfirm}
                touched={registerForm.touched.passwordConfirm}
                placeHolder="Confirm password"
                // label="Confirm password"
                icon={<RiLockPasswordFill className="text-2xl text-gray-400" />}
              />
              <PrimaryButton
                type="submit"
                disabled={registerForm.isSubmitting}
                loading={loading}
              >
                Register
              </PrimaryButton>
            </form>
          )}

          {isLogin && (
            <Fragment>
              <div className="flex items-center my-3">
                <div className="flex-1 h-0.5 bg-gray-200"></div>
                <p className="px-3 text-lg rounded-full pb-1">or</p>
                <div className="flex-1 h-0.5 bg-gray-200"></div>
              </div>
              <div className="flex flex-col space-y-3">
                <GoogleLogin />
                <FacebookLogin />
              </div>
            </Fragment>
          )}

          <div className="flex flex-col items-center space-y-2 pt-5">
            {isLogin && (
              <p onClick={() => setIsLogin(false)} className="text-sm">
                Don't have an account?{" "}
                <span
                  className={joinStrings(
                    "underline cursor-pointer text-gray-600 text",
                    "transition-colors duration-500",
                    "hover:text-cyan-600"
                  )}
                >
                  Register
                </span>
              </p>
            )}
            {!isLogin && (
              <p onClick={() => setIsLogin(true)} className="text-sm">
                Already have an account?{" "}
                <span
                  className={joinStrings(
                    "underline cursor-pointer text-gray-600",
                    "transition-colors duration-500",
                    "hover:text-cyan-600"
                  )}
                >
                  Login
                </span>
              </p>
            )}
          </div>
        </div>

        <div className="flex justify-end py-2 px-5 border-t border-gray-200">
          <CloseButton
            // className="bg-rose-600 text-white rounded-lg py-2 px-5 focus:outline-none"
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </CloseButton>
        </div>
      </div>
    </Modal>
  );
};
