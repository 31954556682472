import { useFormik } from "formik";
import { filter, find, map, mergeDeepRight, pipe, propEq } from "ramda";
import { useEffect } from "react";
import { FaSort } from "react-icons/fa";
import { FiPackage } from "react-icons/fi";
import { HiOutlineClipboardList } from "react-icons/hi";
import { joinStrings } from "../../utils/util-methods";
import { Accordion } from "../Accordion/Accordion";
import { CloseButton } from "../CloseButton";
import { FilterCheck } from "../FilterCheck";
import { Modal } from "../Modal";
import { PrimaryButton } from "../PrimaryButton";

const sorts = [
  "Name: Ascending",
  "Name: Descending",
  "Rating: Lowest to highest",
  "Rating: Highest to lowest",
];

export const FilterModal = ({
  open,
  setOpen,
  categories,
  features,
  // fetchBusinesses,
  setPaginatedBusinesses,
}) => {
  const filterForm = useFormik({
    initialValues: {
      categories: [],
      features: [],
      sorts: map(
        (x) => ({
          name: x,
          isToggled: false,
        }),
        sorts
      ),
    },
    onSubmit: async (values) => {
      // try {
      const categoriesToUse = pipe(
        filter(propEq("isToggled", true)),
        map(({ id, name }) => ({ id, name }))
      )(values.categories);

      const featuresToUse = pipe(
        filter(propEq("isToggled", true)),
        map(({ id, name }) => ({ id, name }))
      )(values.features);

      const sortToUse = find(propEq("isToggled", true), values.sorts);
      let transformedSort = {};
      if (sortToUse === values.sorts[0]) {
        transformedSort = {
          column: "name",
          order: "asc",
        };
      } else if (sortToUse === values.sorts[1]) {
        transformedSort = {
          column: "name",
          order: "desc",
        };
      } else if (sortToUse === values.sorts[2]) {
        transformedSort = {
          column: "avgRating",
          order: "asc_nulls_last",
        };
      } else if (sortToUse === values.sorts[3]) {
        transformedSort = {
          column: "avgRating",
          order: "desc_nulls_last",
        };
      }

      const toMerge = {
        skip: 0,
        filters: { categories: categoriesToUse, features: featuresToUse },
        sort: transformedSort,
      };

      setPaginatedBusinesses((prev) => mergeDeepRight(prev, toMerge));
      // setFilters((prev) => mergeRight(prev, { categoryIds, featureIds }));
      // fetchBusinesses({ categoryIds, featureIds });
      setOpen(false);
      // } catch (err) {
      //   addToast(ToastType.Error, "Failed to fetch filtered businesses");
      // }
    },
  });

  const handleFilterClick = (isToggled) => async (name) => {
    await filterForm.setFieldValue(name, !isToggled, false);
  };

  const handleSortClick = (isToggled) => async (name) => {
    await filterForm.setFieldValue(
      "sorts",
      map(
        (x) => ({
          name: x,
          isToggled: false,
        }),
        sorts
      ),
      false
    );
    await filterForm.setFieldValue(name, !isToggled, false);
  };

  useEffect(() => {
    if (!!categories?.length) {
      filterForm.initialValues.categories = categories?.map(({ id, name }) => ({
        id,
        name,
        isToggled: false,
      }));
    }
  }, [categories, filterForm?.initialValues]);

  useEffect(() => {
    if (!!features?.length) {
      filterForm.initialValues.features = features?.map(({ id, name }) => ({
        id,
        name,
        isToggled: false,
      }));
    }
  }, [features, filterForm.initialValues]);

  return (
    <Modal open={open} setOpen={setOpen}>
      <div
        className={joinStrings("bg-white rounded-lg container", "sm:w-screen")}
      >
        <div className="flex justify-center items-center space-x-3 h-20 border-b border-gray-200">
          <h3 className="text-cyan-900">Filters</h3>
        </div>

        <Accordion className="m-10" defaultOpenItem="categories" allowAllOpen>
          <Accordion.Item name="categories">
            <Accordion.Toggle>
              <Accordion.Header>
                <div className="flex space-x-3 items-center">
                  <FiPackage className="text-xl text-cyan-600" />
                  <h5>Categories</h5>
                </div>
              </Accordion.Header>
            </Accordion.Toggle>

            <Accordion.Body>
              <div
                className={joinStrings(
                  "grid grid-cols-1 gap-y-3 max-h-56 overflow-y-auto p-5",
                  "sm:grid-cols-2",
                  "lg:grid-cols-3",
                  "xl:grid-cols-4"
                )}
              >
                {filterForm?.values?.categories?.map(
                  ({ name, isToggled }, i) => (
                    <FilterCheck
                      key={`category-toggle-${name}`}
                      name={`categories[${i}].isToggled`}
                      toggled={isToggled}
                      text={name}
                      onChange={handleFilterClick(isToggled)}
                    />
                  )
                )}
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item name="features">
            <Accordion.Toggle>
              <Accordion.Header>
                <div className="flex space-x-3 items-center">
                  <HiOutlineClipboardList className="text-2xl text-cyan-600" />
                  <h5>Features</h5>
                </div>
              </Accordion.Header>
            </Accordion.Toggle>

            <Accordion.Body>
              <div
                className={joinStrings(
                  "grid grid-cols-1 gap-y-3 p-5 max-h-56 overflow-y-auto",
                  "sm:grid-cols-2",
                  "lg:grid-cols-3",
                  "xl:grid-cols-4"
                )}
              >
                {filterForm?.values?.features?.map(({ name, isToggled }, i) => (
                  <FilterCheck
                    key={`feature-toggle-${name}`}
                    name={`features[${i}].isToggled`}
                    toggled={isToggled}
                    text={name}
                    onChange={handleFilterClick(isToggled)}
                  />
                ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item name="sort">
            <Accordion.Toggle>
              <Accordion.Header>
                <div className="flex space-x-3 items-center">
                  <FaSort className="text-2xl text-cyan-600" />
                  <h5>Sort</h5>
                </div>
              </Accordion.Header>
            </Accordion.Toggle>

            <Accordion.Body>
              <div
                className={joinStrings(
                  "grid grid-cols-1 gap-y-3 p-5",
                  "sm:grid-cols-2",
                  "lg:grid-cols-3",
                  "xl:grid-cols-4"
                )}
              >
                {filterForm?.values?.sorts?.map(({ name, isToggled }, i) => (
                  <FilterCheck
                    key={`sort-toggle-${name}`}
                    name={`sorts[${i}].isToggled`}
                    toggled={isToggled}
                    text={name}
                    onChange={handleSortClick(isToggled)}
                  />
                ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <div className="flex justify-end items-center h-20 px-10 space-x-3 border-t border-gray-200">
          <CloseButton onClick={() => setOpen(false)}>Close</CloseButton>
          <PrimaryButton
            // loading={loading}
            className="space-x-2"
            onClick={filterForm.handleSubmit}
          >
            <span>Apply</span>
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};
