import { fill } from "lodash";
import React from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { joinStrings } from "../utils/util-methods";

export const Review = ({ className, firstName, lastName, rating, comment }) => {
  return (
    <div
      className={joinStrings(
        "flex flex-col space-x-0 space-y-3",
        "md:flex-row md:space-x-5 md:space-y-0",
        className
      )}
    >
      <div className="flex flex-col flex-grow overflow-hidden">
        <div className="flex items-center justify-between">
          <h6
            className={joinStrings("text-center", "md:text-left")}
          >{`${firstName} ${lastName}`}</h6>
          {!!rating && (
            <div className="flex text-gray-600">
              {fill(Array(rating), "").map((_, i) => (
                <AiFillStar key={`rating-star-business-view-filled-${i}`} />
              ))}
              {fill(Array(5 - rating), "").map((_, i) => (
                <AiOutlineStar key={`rating-star-business-view-empty-${i}`} />
              ))}
            </div>
          )}
        </div>

        <div className="h-0.5 bg-gray-200 mt-0.5"></div>

        <pre
          className={joinStrings(
            "text-center mt-3 text-gray-600 max-h-52 overflow-y-auto whitespace-pre-wrap break-words",
            "md:text-left"
          )}
        >
          {comment}
        </pre>
      </div>
    </div>
  );
};
