import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { RiLockPasswordFill, RiLockPasswordLine } from "react-icons/ri";
import * as yup from "yup";
import { ToastContext } from "../../../contexts/ToastContext";
import { mutation1, query1 } from "../../../services/graphqlService";
import { MessageType } from "../../../utils/enums/MessageType";
import { ToastType } from "../../../utils/enums/ToastType";
import { getUserId } from "../../../utils/util-methods";
import { Message } from "../../Message";
import { ShadowInput } from "../../ShadowInput";
import { ProfileEditModal } from "../ProfileEditModal";

export const PasswordEditModal = ({ open, setOpen, userId, mergeUser }) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useContext(ToastContext);
  const [isOldPasswordIncorrect, setIsOldPasswordIncorrect] = useState(false);

  const editForm = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    },
    validationSchema: yup.object({
      oldPassword: yup
        .string()
        .required("Your old password is required")
        .min(8, "Your old password must be at least 8 characters long."),
      newPassword: yup
        .string()
        .required("Your new password is required")
        .min(8, "Your new password must be at least 8 characters long."),
      newPasswordConfirm: yup
        .string()
        .required("Your new password confirmation is required")
        .test(
          "passwords-match",
          "Your new passwords must match",
          function (newPasswordConfirm) {
            return newPasswordConfirm === this.parent.newPassword;
          }
        ),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const {
          data: { checkPassword },
        } = await query1(
          `checkPassword(userId: ${getUserId()}, password: "${
            values.oldPassword
          }")`
        );

        if (checkPassword === "Success") {
          const {
            data: { updateUser },
          } = await mutation1(`updateUser(id: ${userId}, data: { password: "${values.newPassword}" }) {
                      password
                  }`);

          mergeUser(updateUser);
          setLoading(false);
          editForm.resetForm();
          addToast(ToastType.Success, "Your password has been updated");
          setOpen(false);
        } else if (checkPassword === "Failure") {
          setIsOldPasswordIncorrect(true);
        }
      } catch (err) {
        addToast(
          ToastType.Error,
          "Action failed. The server may be temporarily down. Please try again in a few minutes."
        );
        setLoading(false);
      }
    },
  });

  return (
    <ProfileEditModal
      open={open}
      setOpen={setOpen}
      header="Update your password"
      onSubmit={editForm.handleSubmit}
      loading={loading}
    >
      <form className="flex flex-col space-y-3 px-10 mt-5">
        {isOldPasswordIncorrect && (
          <Message type={MessageType.Error}>
            The old password is incorrect.
          </Message>
        )}
        <ShadowInput
          id="oldPassword"
          name="oldPassword"
          placeHolder="Old Password"
          onChange={(e) => {
            if (isOldPasswordIncorrect) {
              setIsOldPasswordIncorrect(false);
            }
            editForm.handleChange(e);
          }}
          onBlur={editForm.handleBlur}
          error={editForm.errors.oldPassword}
          value={editForm.values.oldPassword}
          touched={editForm.touched.oldPassword}
          icon={<RiLockPasswordFill className="text-2xl text-gray-400" />}
          type="password"
        />
        <ShadowInput
          id="newPassword"
          name="newPassword"
          placeHolder="New Password"
          onChange={editForm.handleChange}
          onBlur={editForm.handleBlur}
          error={editForm.errors.newPassword}
          value={editForm.values.newPassword}
          touched={editForm.touched.newPassword}
          icon={<RiLockPasswordLine className="text-2xl text-gray-400" />}
          type="password"
        />
        <ShadowInput
          id="newPasswordConfirm"
          name="newPasswordConfirm"
          placeHolder="Confirm New Password"
          onChange={editForm.handleChange}
          onBlur={editForm.handleBlur}
          error={editForm.errors.newPasswordConfirm}
          value={editForm.values.newPasswordConfirm}
          touched={editForm.touched.newPasswordConfirm}
          icon={<RiLockPasswordFill className="text-2xl text-gray-400" />}
          type="password"
        />
      </form>
    </ProfileEditModal>
  );
};
