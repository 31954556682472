import React, { useContext, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { ToastContext } from "../../../../contexts/ToastContext";
import { mutation1 } from "../../../../services/graphqlService";
import { BusinessSubscriptionPackage } from "../../../../utils/enums/BusinessSubscriptionPackage";
import { ToastMessage } from "../../../../utils/enums/ToastMessage";
import { ToastType } from "../../../../utils/enums/ToastType";
import { joinStrings } from "../../../../utils/util-methods";
import { PrimaryButton } from "../../../PrimaryButton";

export const PlanSwap = ({
  heading,
  cost,
  monthly,
  isCurrent = false,
  subscriptionPlan,
  businessId,
  userId,
  setOpen,
  refreshBusinesses,
  setOpenOfParentModal,
}) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useContext(ToastContext);

  return (
    <>
      <div
        className={joinStrings(
          "rounded-lg p-5 border",
          isCurrent ? "border-cyan-900" : "",
          "group-hover:bg-gray-800"
        )}
      >
        <h3
          className={joinStrings(
            " tracking-widest text-gray-600 text-center",
            "md:text-left",
            "group-hover:text-gray-400"
          )}
        >
          {heading}
        </h3>
        <div
          className={joinStrings(
            "flex flex-col items-center space-x-1",
            "md:flex-row"
          )}
        >
          <h2
            className={joinStrings(
              "text-cyan-900 font-medium tracking-wide",
              "group-hover:text-white"
            )}
          >{`R${cost}.00`}</h2>
          <h4
            className={joinStrings("text-gray-400", "group-hover:text-white")}
          >
            {monthly}
          </h4>
        </div>

        {!isCurrent && (
          <PrimaryButton
            loading={loading}
            disabled={loading}
            onClick={async () => {
              setLoading(true);
              try {
                let newFrequency = "";
                if (subscriptionPlan === BusinessSubscriptionPackage.BRONZE) {
                  newFrequency = "Monthly";
                } else if (
                  subscriptionPlan === BusinessSubscriptionPackage.SILVER
                ) {
                  newFrequency = "Quarterly";
                } else if (
                  subscriptionPlan === BusinessSubscriptionPackage.GOLD
                ) {
                  newFrequency = "BiAnnually";
                }
                const {
                  data: { updateSubscription },
                } = await mutation1(`
                updateSubscription(id: ${businessId}, frequency: ${newFrequency})`);

                if (updateSubscription === "Success") {
                  addToast(
                    ToastType.Success,
                    `Your payment plan has been updated.`
                  );
                  refreshBusinesses();
                  setLoading(false);
                  setOpen(false);
                  setOpenOfParentModal(false);
                } else if (updateSubscription === "Failure") {
                  addToast(
                    ToastType.Error,
                    ToastMessage.PAYMENT_GATEWAY_FAILURE
                  );
                  setLoading(false);
                } else if (updateSubscription === "Unavailable") {
                  addToast(ToastType.Warn, ToastMessage.UNAVAILABLE);
                  setLoading(false);
                  setOpen(false);
                  setOpenOfParentModal(false);
                }
              } catch (err) {
                addToast(ToastType.Error, ToastMessage.FAILURE);
                setLoading(false);
              }
            }}
            paddingY="small"
            className={joinStrings(
              "flex items-center justify-between w-full mt-3",
              "group-hover:bg-gray-600 group-hover:text-white group-hover:border-gray-600"
            )}
          >
            Swap
            <BsArrowRight />
          </PrimaryButton>
        )}
        {isCurrent && <p className="mt-5">Current Plan</p>}
      </div>
    </>
  );
};
