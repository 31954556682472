import { toLower } from "ramda";
import React from "react";
import { BusinessSubscriptionPackage } from "../../../../utils/enums/BusinessSubscriptionPackage";
import { getEnvVariable, joinStrings } from "../../../../utils/util-methods";
import { CloseButton } from "../../../CloseButton";
import { Modal } from "../../../Modal";
import { PlanSwap } from "./PlanSwap";

export const UpdatePlanModal = ({
  open,
  setOpen,
  setOpenOfParentModal,
  currentPlan,
  businessName,
  businessId,
  userId,
  refreshBusinesses,
}) => {
  // const [plan, setPlan] = useState({
  //   name: "",
  //   loading: false
  // })
  return (
    <Modal open={open} setOpen={setOpen}>
      <div className={joinStrings("bg-white rounded-lg")}>
        <div className="flex justify-center items-center h-20 border-b border-gray-200 p-5">
          <h3 className="text-cyan-900">{businessName}: Update Payment Plan</h3>
        </div>

        <div
          className={joinStrings(
            "flex flex-col space-y-3 px-5 py-10",
            "lg:flex-row lg:space-x-5 lg:space-y-0"
          )}
        >
          <PlanSwap
            heading="Bronze Plan"
            cost={getEnvVariable("BRONZE_AMOUNT")}
            monthly="/month"
            isCurrent={
              toLower(currentPlan ?? "") === BusinessSubscriptionPackage.BRONZE
            }
            subscriptionPlan={BusinessSubscriptionPackage.BRONZE}
            businessId={businessId}
            userId={userId}
            setOpen={setOpen}
            refreshBusinesses={refreshBusinesses}
            setOpenOfParentModal={setOpenOfParentModal}
          />
          <PlanSwap
            heading="Silver Plan"
            cost={getEnvVariable("SILVER_AMOUNT")}
            monthly="/3 months"
            isCurrent={
              toLower(currentPlan ?? "") === BusinessSubscriptionPackage.SILVER
            }
            subscriptionPlan={BusinessSubscriptionPackage.SILVER}
            businessId={businessId}
            userId={userId}
            setOpen={setOpen}
            refreshBusinesses={refreshBusinesses}
            setOpenOfParentModal={setOpenOfParentModal}
          />
          <PlanSwap
            heading="Gold Plan"
            cost={getEnvVariable("GOLD_AMOUNT")}
            monthly="/6 months"
            isCurrent={
              toLower(currentPlan ?? "") === BusinessSubscriptionPackage.GOLD
            }
            subscriptionPlan={BusinessSubscriptionPackage.GOLD}
            businessId={businessId}
            userId={userId}
            setOpen={setOpen}
            refreshBusinesses={refreshBusinesses}
            setOpenOfParentModal={setOpenOfParentModal}
          />
        </div>

        <div className="flex justify-end items-center h-20 px-10 space-x-3 border-t border-gray-200">
          <CloseButton
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </CloseButton>
        </div>
      </div>
    </Modal>
  );
};
