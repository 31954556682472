import { mutation1 } from "./graphqlService";

export const signInGoogle = ({
  loadedGoogleApi,
  setLoadingGoogleLogIn,
  login,
}) => {
  const options = {
    prompt: "select_account",
  };
  if (loadedGoogleApi) {
    const googleAuth = window.gapi.auth2.getAuthInstance();
    googleAuth.signIn(options).then(
      (res) => onSuccess(res)({ setLoadingGoogleLogIn, login }),
      (err) => onFailure(err)
    );
  }
};

const onSuccess = (res) => async ({ setLoadingGoogleLogIn, login }) => {
  // const profile = res.getBasicProfile();
  // console.log("ID: " + profile.getId());
  // console.log("Full Name: " + profile.getName());
  // console.log("Given Name: " + profile.getGivenName());
  // console.log("Family Name: " + profile.getFamilyName());
  // console.log("Image URL: " + profile.getImageUrl());
  // console.log("Email: " + profile.getEmail());
  // console.log("basic profile: ", basicProfile);
  setLoadingGoogleLogIn(true);
  try {
    const { id_token } = res.getAuthResponse(true);
    // console.log("id_token: ", id_token);
    const {
      data: { loginGoogle },
    } = await mutation1(`loginGoogle(token: "${id_token}")`);
    localStorage.setItem("token", loginGoogle);
    login();
  } catch (err) {
    // addToast
  } finally {
    setLoadingGoogleLogIn(false);
  }
};

const onFailure = (err) => {
  console.error(err);
};
