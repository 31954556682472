import { createContext } from "react";

export const StepperContext = createContext({
  activeStep: 0,
  setActiveStep: () => {},
  state: null,
  setState: () => {},
  activeContent: null,
  setActiveContent: () => {},
});
