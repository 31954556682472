import React, { useCallback, useContext, useState } from "react";
import { ToastContext } from "../../../contexts/ToastContext";
import { mutation1 } from "../../../services/graphqlService";
import { ToastMessage } from "../../../utils/enums/ToastMessage";
import { ToastType } from "../../../utils/enums/ToastType";
import { CloseButton } from "../../CloseButton";
import { Modal } from "../../Modal";
import { SecondaryButton } from "../../SecondaryButton";

export const CancelSubscriptionModal = ({
  open,
  setOpen,
  businessId,
  refreshBusinesses,
}) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useContext(ToastContext);
  const [currentDate] = useState(new Date());

  const cancelSubscription = useCallback(async () => {
    setLoading(true);
    try {
      const {
        data: { cancelSubscription },
      } = await mutation1(`cancelSubscription(id: ${businessId})`);
      if (cancelSubscription === "Success") {
        refreshBusinesses();
        setLoading(false);
        setOpen(false);
      } else if (cancelSubscription === "Failure") {
        addToast(ToastType.Error, ToastMessage.PAYMENT_GATEWAY_FAILURE);
        setLoading(false);
      } else if (cancelSubscription === "Unavailable") {
        addToast(ToastType.Warn, ToastMessage.UNAVAILABLE);
        setLoading(false);
      }
    } catch (err) {
      addToast(ToastType.Error, ToastMessage.FAILURE);
      setLoading(false);
    }
  }, [refreshBusinesses, setOpen, addToast, businessId]);

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="w-128 bg-white rounded-lg">
        <div className="py-10 px-10">
          <h6>Are you sure you want to cancel your subscription?</h6>
        </div>

        <div className="flex justify-end items-center h-20 px-10 space-x-3 border-t border-gray-200">
          <SecondaryButton
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </SecondaryButton>
          <CloseButton
            loading={loading}
            onClick={cancelSubscription}
            disabled={currentDate.getDate() === 1}
          >
            Cancel Subscription
          </CloseButton>
        </div>
      </div>
    </Modal>
  );
};
