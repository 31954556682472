import jwt from "jsonwebtoken";
import { forEach } from "ramda";
import React, { useContext, useEffect, useState } from "react";
import { AiFillFacebook } from "react-icons/ai";
import { LoggedInContext } from "../contexts/LoggedInContext";
import { ToastContext } from "../contexts/ToastContext";
import { mutation1 } from "../services/graphqlService";
import { FacebookLoginStatus } from "../utils/enums/FacebookLoginStatus";
import { ToastMessage } from "../utils/enums/ToastMessage";
import { ToastType } from "../utils/enums/ToastType";
import { getEnvVariable, joinStrings } from "../utils/util-methods";

// const window.FB = window.FB;

const loadSdkAsynchronously = () => {
  ((d, s, id) => {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");
};

const setFbAsyncInit = () => {
  window.fbAsyncInit = () => {
    window.FB.init({
      appId: "161966858575333",
      cookie: true,
      xfbml: true,
      version: "v10.0",
    });
    // setIsSdkLoaded(true);
    // if (autoLoad) {
    //   window.FB.getLoginStatus((res) => {
    //     if (res.status === "connected") {
    //     } else {
    //       window.FB.login((loginResponse) => queryFacebook(loginResponse), true);
    //     }
    //   });
    // }
  };
};

const loginFacebook = async ({ meResponse, setLoading, login, addToast }) => {
  //verify that auth response contains all necessary fields sine fb won't return emails that haven't been verified
  const requiredKeys = ["email", "first_name", "last_name", "id", "picture"];
  let containsAllRequiredKeys = true;
  forEach((key) => {
    if (!meResponse.hasOwnProperty(key)) {
      containsAllRequiredKeys = false;
    }
  }, requiredKeys);

  if (containsAllRequiredKeys) {
    setLoading(true);
    try {
      const facebookToken = jwt.sign(
        {
          email: meResponse?.email,
          firstName: meResponse?.first_name,
          lastName: meResponse?.last_name,
          facebookId: meResponse?.id,
          profilePictureUrl: meResponse?.picture?.data?.url,
        },
        getEnvVariable("TOKEN_SECRET") //token secret
      );
      const {
        data: { loginFacebook },
      } = await mutation1(`loginFacebook(token: "${facebookToken}")`);
      localStorage.setItem("token", loginFacebook);
      login();
    } catch (err) {
      addToast(ToastType.Error, ToastMessage.FAILURE);
    } finally {
      setLoading(false);
    }
  }
};

export const FacebookLogin = ({
  fields = "id,first_name,last_name,email,picture",
}) => {
  const [loading, setLoading] = useState(false);
  // const [isSdkLoaded, setIsSdkLoaded] = useState(false);
  // const [authObj, setAuthObj] = useState(null);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { login } = useContext(LoggedInContext);

  const { addToast } = useContext(ToastContext);

  // useEffect(() => {
  //   if (isSdkLoaded && !loggedInContext.isLoggedIn) {
  //   }
  // }, [loggedInContext.isLoggedIn, isSdkLoaded]);

  const queryFacebook = (response) => {
    console.log('response: ', response);
    // console.log('granted scopes: ', response.authResponse.grantedScopes);
    if (response.authResponse) {
      // console.log('gets here');
      const requiredScopes = "email,public_profile";
      if (response.authResponse.grantedScopes === requiredScopes) {
        window.FB.api(
          "/me",
          { fields },
          async (res) => {
            const loginFacebookOptions = {
              meResponse: res,
              setLoading,
              login,
              addToast,
            };
            await loginFacebook(loginFacebookOptions);
          }
          // console.log("window.FB /me response: ", res)
        );
      } else {
        // console.log('gets into else');
        window.FB.login((loginResponse) => queryFacebook(loginResponse), {
          scope: "email",
          return_scopes: true,
          auth_type: "rerequest",
        });

        // addToast(
        //   ToastType.Warn,
        //   "You have not provided us with the necessary permissions, please remove our site from your facebook account and try log in again."
        // );
      }
    }
  };

  useEffect(() => {
    if (document.getElementById("facebook-jssdk")) {
      // setIsSdkLoaded(true);
      return;
    }
    setFbAsyncInit();
    loadSdkAsynchronously();
  }, []);

  return (
    <button
      disabled={loading}
      className={joinStrings(
        "py-2 w-full bg-blue-900 rounded inline-flex items-center justify-center space-x-3 text-white",
        "focus:outline-none"
      )}
      onClick={() =>
        window.FB.getLoginStatus((res) => {
          console.log("res: ", res);
          if (res.status === FacebookLoginStatus.Connected) {
            //query facebook for userId and exchance userId for syncvine token.
            queryFacebook(res);
          } else {
            window.FB.login((loginResponse) => queryFacebook(loginResponse), {
              scope: "email,public_profile",
              return_scopes: true,
            });
            //query facebook for details, send them to syncvine api, register or link the user, send back api token
          }
        })
      }
    >
      <AiFillFacebook className="text-2xl" />
      <span>Sign in with facebook</span>
    </button>
  );
};
