import React, { Fragment } from "react";
import { joinStrings } from "../utils/util-methods";
import { LoadingCircle } from "./SVG/LoadingCircle";

export const PrimaryButton = ({
  onClick,
  disabled = false,
  loading = false,
  type = "button",
  className,
  children,
  paddingY = "normal"
}) => {
  return (
    <button
      className={joinStrings(
        "outline-none px-7 transition-colors duration-300 border rounded flex items-center justify-center max-h-14",
        "focus:outline-none",
        `${paddingY === 'normal' ? "py-2.5" : ""}`,
        `${paddingY === 'small' ? "py-1.5" : ""}`,
        disabled &&
          !loading &&
          "border-gray-200 bg-gray-200 cursor-default text-white",
        !disabled &&
          !loading &&
          joinStrings(
            "border-cyan-900 bg-cyan-900 text-white",
            "hover:bg-white hover:text-cyan-900"
          ),
        loading && "bg-gray-200 cursor-default space-x-3",
        className
      )}
      onClick={onClick}
      type={type}
      disabled={disabled || loading}
    >
      {loading && (
        <Fragment>
          <span className="text-gray-600">Loading...</span> <LoadingCircle />
        </Fragment>
      )}
      {!loading && children}
    </button>
  );
};
