import { fill } from "lodash";
import React, { useEffect, useState } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

export const StarRating = ({ label, rating, setRating }) => {
  const [starsFull, setStarsFull] = useState([]);
  const [starsEmpty, setStarsEmpty] = useState([]);

  useEffect(() => {
    setStarsFull(fill(Array(rating), ""));
    setStarsEmpty(fill(Array(5 - rating), ""));
  }, [rating]);

  return (
    <div>
      <span className="text-gray-600">{label}</span>
      <div
        className="flex space-x-1 items-center h-10 text-2xl"
        // onMouseLeave={() => setStarsHovered(rating)}
      >
        {!!starsFull?.length &&
          starsFull.map((_, i) => (
            <AiFillStar
              className="text-cyan-900"
              key={`full star ${i}`}
              onMouseEnter={() => setRating(i + 1)}
              //   onClick={() => setRating(i + 1)}
            />
          ))}
        {starsEmpty.map((_, i) => (
          <AiOutlineStar
            className="text-cyan-900"
            key={`empty star ${i}`}
            onMouseEnter={() =>
              setRating(Math.abs(i + 1 + starsFull.length))
            }
            // onClick={() => setRating(i + 1)}
          />
        ))}
      </div>
      <p className="pl-1">{rating} / 5</p>
    </div>
  );
};
