import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CgProfile } from "react-icons/cg";
import { withRouter } from "react-router-dom";
import { LoggedInContext } from "../contexts/LoggedInContext";
import { Dropdown } from "./Dropdown";
import jwt_decode from "jwt-decode";

const LoggedInDropdown = ({ className, history }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const context = useContext(LoggedInContext);

  const options = [
    {
      text: "Profile",
      key: "Profile",
      clickEvent: () => {
        const token = localStorage.getItem("token");
        const { id } = jwt_decode(token);
        history.push(`/profile/${id}`);
      },
    },
    {
      text: "Logout",
      key: "Logout dropdown",
      clickEvent: () => context.logout(),
    },
  ];

  const clickListener = useCallback(
    (e) => {
      if (!ref?.current?.contains(e.target)) {
        setIsOpen(false);
      }
    },
    [ref]
  );

  useEffect(() => {
    document.addEventListener("click", clickListener);

    return () => {
      document.removeEventListener("click", clickListener);
    };
  }, [clickListener]);

  return (
    <div
      ref={ref}
      onClick={() => setIsOpen(!isOpen)}
      className="relative inline-block text-left w-full"
    >
      <CgProfile className="h-10 w-10 text-white cursor-pointer bg-cyan-700 rounded-full p-2" />

      <Dropdown
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        options={options}
        className="w-36"
      />
    </div>
  );
};

export default withRouter(LoggedInDropdown);
