import React, { Fragment, useContext, useEffect, useState } from "react";
import { LogoLoader } from "../components/LogoLoader";
import { UndrawCancel } from "../components/Undraw/UndrawCancel";
import { UndrawComplete } from "../components/Undraw/UndrawComplete";
import { ToastContext } from "../contexts/ToastContext";
import { mutation1 } from "../services/graphqlService";
import { ToastMessage } from "../utils/enums/ToastMessage";
import { ToastType } from "../utils/enums/ToastType";
import { joinStrings } from "../utils/util-methods";

export const VerifyEmail = ({ match }) => {
  const [loadingVerification, setLoadingVerification] = useState(false);
  const [response, setResponse] = useState("");
  const { verificationCode } = match?.params;
  const { addToast } = useContext(ToastContext);

  useEffect(() => {
    if (verificationCode) {
      (async () => {
        setLoadingVerification(true);
        try {
          const {
            data: { verifyEmail },
          } = await mutation1(
            `verifyEmail(verificationCode: "${verificationCode}")`
          );
          setResponse(verifyEmail);
        } catch (err) {
          addToast(ToastType.Error, ToastMessage.FAILURE);
        } finally {
          setLoadingVerification(false);
        }
      })();
    }
  }, [verificationCode, addToast]);
  return (
    <div className="my-20 mx-auto max-w-2xl">
      <div
        className={joinStrings(
          "h-128 flex flex-col justify-center items-center bg-white rounded-lg shadow-md p-10",
          "transition-shadow duration-300",
          "hover:shadow-2xl"
        )}
      >
        {loadingVerification && <LogoLoader className="h-full" />}
        {!loadingVerification && response === "Success" && (
          <Fragment>
            <div className="flex flex-col">
              <h2 className="text-cyan-900 font-medium">
                Your email has been verified.
              </h2>
              <p className="text-gray-400 mt-1">
                Please return to the login process and enter your details.
              </p>
            </div>
            <UndrawComplete className="h-52 mt-10 w-full" />
          </Fragment>
        )}

        {!loadingVerification && response === "Failure" && (
          <Fragment>
            <div className="flex flex-col items-center">
              <h2 className="text-cyan-900 font-medium text-center">
                Oops! We couldn't find an account against your verification code
              </h2>
              <p className="text-gray-400 mt-5">
                Please contact us directly should you require assistance.
              </p>
            </div>
            <UndrawCancel className="h-52 mt-10 w-full" />
          </Fragment>
        )}
      </div>
    </div>
  );
};
