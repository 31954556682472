import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import * as yup from "yup";
import { ToastContext } from "../../../contexts/ToastContext";
import { mutation1 } from "../../../services/graphqlService";
import { ToastType } from "../../../utils/enums/ToastType";
import { ShadowInput } from "../../ShadowInput";
import { ProfileEditModal } from "../ProfileEditModal";

export const EmailEditModal = ({ open, setOpen, userId, mergeUser }) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useContext(ToastContext);
  const editForm = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .required("A new email is required")
        .email("Must be a legitimate email"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const {
          data: { updateUser },
        } = await mutation1(`updateUser(id: ${userId}, data: { email: "${values.email}" }) {
                  email
              }`);

        mergeUser(updateUser);
        setLoading(false);
        editForm.resetForm();
        setOpen(false);
      } catch (err) {
        addToast(
          ToastType.Error,
          "Action failed. The server may be temporarily down. Please try again in a few minutes."
        );
        setLoading(false);
      }
    },
  });

  return (
    <ProfileEditModal
      open={open}
      setOpen={setOpen}
      header="Update your email"
      onSubmit={editForm.handleSubmit}
      loading={loading}
    >
      <form className="flex flex-col space-y-3 px-10 mt-5">
        <ShadowInput
          id="email"
          name="email"
          // label="Email"
          placeHolder="Email"
          onChange={editForm.handleChange}
          onBlur={editForm.handleBlur}
          error={editForm.errors.email}
          value={editForm.values.email}
          touched={editForm.touched.email}
          type="email"
        />
      </form>
    </ProfileEditModal>
  );
};
