import React, { useCallback, useContext, useRef } from "react";
import { MdContentCopy } from "react-icons/md";
import { ToastContext } from "../contexts/ToastContext";
import { ToastType } from "../utils/enums/ToastType";
import { joinStrings } from "../utils/util-methods";
import { CloseButton } from "./CloseButton";
import { Modal } from "./Modal";

export const ShareModal = ({ open, setOpen, businessId }) => {
  const { addToast } = useContext(ToastContext);
  const clipboardRef = useRef(null);
  const copyToClipboard = useCallback(async () => {
    try {
      //check if clipboard navigator api is available
      if (!navigator.clipboard) {
        /* Select the text field */
        clipboardRef.current.select();
        clipboardRef.current.setSelectionRange(
          0,
          99999
        ); /* For mobile devices */

        /* Copy the text inside the text field */
        const copiedSuccessfully = document.execCommand("copy");
        copiedSuccessfully
          ? addToast(ToastType.Success, "Copied link to clipboard.")
          : addToast(ToastType.Warn, "Couldn't copy to clipboard.");
      } else {
        await navigator.clipboard.writeText(
          `https://syncvine.co.za/business/${businessId}`
        );
        addToast(ToastType.Success, "Copied link to clipboard.");
      }
    } catch (err) {
      addToast(ToastType.Error, "Couldn't copy to clipboard.");
    }
  }, [addToast, businessId]);

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="bg-white rounded-lg">
        <div className="flex justify-center items-center h-20 border-b border-gray-300">
          <h3 className="text-cyan-900">Share this business</h3>
        </div>

        <div className="flex flex-col pt-2 pb-4 px-7">
          <p className="text-gray-400 text-center">
            Copy the link and send it to anyone you know
          </p>

          <div className="flex items-center space-x-3 mt-5 relative z-10 bg-white">
            <input
              ref={clipboardRef}
              className={joinStrings(
                "appearance-none outline-none select-none w-5 h-5 bg-transparent absolute z-0 text-transparent left-20",
                "focus:outline-none"
              )}
              value={`https://syncvine.co.za/business/${businessId}`}
              onChange={() => {}}
            />
            <p
              className={joinStrings(
                "py-2 rounded bg-gray-100 text-sm w-full text-center z-10",
                "md:text-base"
              )}
            >
              {`https://syncvine.co.za/business/${businessId}`}
            </p>
            <button className="btn" onClick={() => copyToClipboard()}>
              <MdContentCopy className="text-2xl text-gray-500 cursor-pointer" />
            </button>
          </div>
        </div>

        <div className="flex justify-end items-center h-20 border-t border-gray-300 px-5">
          <CloseButton onClick={() => setOpen(false)}>Close</CloseButton>
        </div>
      </div>
    </Modal>
  );
};
