import React, { useState } from "react";
import RegisterBusiness from "../components/RegisterBusiness";
// import { LoggedInContext } from "../contexts/LoggedInContext";
import { joinStrings } from "../utils/util-methods";
import { PackageSelect } from "./PackageSelect";
import { Stepper } from "./Stepper/Stepper";

export const Register = ({ match, history }) => {
  // const [startStep, setStartStep] = useState(0);
  // const [parentState, setParentState] = useState(null);
  // const { isLoggedIn } = useContext(LoggedInContext);

  // useEffect(() => {
  //   const subscriptionPlan = match?.params?.plan;
  //   if (!!subscriptionPlan) {
  //     if (subscriptionPlan <= 3 && subscriptionPlan >= 1) {
  //       setStartStep(1);
  //       setParentState({ subscriptionPlan });
  //     }
  //      else if (subscriptionPlan === 0) {
  //       // history.push("/");
  //     }
  //   }
  // }, [match?.params?.plan, history]);

  const [steps] = useState([
    { name: "Select a plan", content: <PackageSelect /> },
    { name: "Enter business details", content: <RegisterBusiness /> },
    { name: "Preview and checkout", content: null },
  ]);

  return (
    <div className="container mx-auto my-10">
      <div className={joinStrings("mx-5")}>
        {/* {!isLoggedIn && (
          <Message type={MessageType.Warn} className="mb-3">
            You will be asked to log in before being able to checkout.
          </Message>
        )} */}
        <Stepper
          // startStep={startStep}
          // className={joinStrings("lg:mx-20", "xl:mx-16")}
          steps={steps}
          // parentState={parentState}
        />
      </div>
      {/* {displayLoginWarning && (
        <Message type={MessageType.Warn} className="mx-auto w-max">
          You must log in to proceed past this step.
        </Message>
      )} */}
    </div>
  );
};
