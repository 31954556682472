import React, { createContext, useCallback, useEffect, useState } from "react";
import {
  // HashRouter as Router,
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import "./App.css";
import { About } from "./components/About";
import { Footer } from "./components/Footer";
import { HamburgerNav } from "./components/HamburgerNav";
import { LoginModal } from "./components/LoginModal";
import { NavBar } from "./components/Navbar";
import { NewHome } from "./components/NewHome";
import { Register } from "./components/Register";
import ScrollToTop from "./components/ScrollToTop";
import { ToastContainer } from "./components/ToastContainer";
import { LoggedInContext } from "./contexts/LoggedInContext";
import { LoginModalContext } from "./contexts/LoginModalContext";
import { ToastContext } from "./contexts/ToastContext";
import { useGoogleLogin } from "./hooks/useGoogleLogin";
import { Browse } from "./pages/Browse";
import { Business } from "./pages/Business";
import { Contact } from "./pages/Contact";
import { EditBusiness } from "./pages/EditBusiness";
import { ForgotPassword } from "./pages/ForgotPassword";
import { PaymentCancelled } from "./pages/PaymentCancelled";
import { PaymentComplete } from "./pages/PaymentComplete";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { Profile } from "./pages/Profile";
import { ResetPassword } from "./pages/ResetPassword";
import { TermsAndConditions } from "./pages/TermsAndConditions";
import { VerifyEmail } from "./pages/VerifyEmail";
import { ToastType } from "./utils/enums/ToastType";
import { getTokenUnsafe } from "./utils/util-methods";

const navbarItems = [
  { to: "/", text: "Home" },
  { to: "/browse", text: "Browse" },
  // { to: "/about", text: "About" },
  { to: "/advertise", text: "Advertise" },
];

export const GoogleApiLoadContext = createContext({
  loadedGoogleApi: false,
});

const App = () => {
  const [displayLoginModal, setDisplayLoginModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!getTokenUnsafe());
  const loadedGoogleApi = useGoogleLogin();
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback(
    (type, text) =>
      setToasts((prev) => [...prev, { id: prev.length, type, text }]),
    [setToasts]
  );

  const login = useCallback(() => {
    setIsLoggedIn(true);
    addToast(ToastType.Info, "You have been logged in");
  }, [addToast]);

  const logout = useCallback(() => {
    setIsLoggedIn(false);
    addToast(ToastType.Info, "You have been logged out");
  }, [addToast]);

  useEffect(() => {
    if (!isLoggedIn) {
      localStorage.removeItem("token");
      if (loadedGoogleApi) {
        const auth2 = window.gapi.auth2.getAuthInstance();
        if (auth2.isSignedIn.get()) {
          auth2.signOut().then(() => {
            console.log("User signed out.");
          });
        }
      }
    }
  }, [isLoggedIn, loadedGoogleApi]);

  // useEffect(() => {
  //   console.log("env: ", process.env);
  //   console.log(getEnvVariable("PAYFAST_DOMAIN"));
  //   console.log(getEnvVariable("PAYFAST_MERCHANT_KEY"));
  //   console.log(getEnvVariable("PAYFAST_MERCHANT_ID"));
  //   console.log(getEnvVariable("PAYFAST_PASSPHRASE"));
  //   console.log(getEnvVariable("PAYFAST_DOMAIN"));
  // }, []);

  return (
    <Router>
      {/* <LocationListener /> */}
      <LoginModalContext.Provider
        value={{
          isLoginModalOpen: displayLoginModal,
          setIsLoginModalOpen: setDisplayLoginModal,
        }}
      >
        <LoggedInContext.Provider value={{ isLoggedIn, login, logout }}>
          <ToastContext.Provider
            value={{
              toasts,
              setToasts,
              addToast,
            }}
          >
            <GoogleApiLoadContext.Provider value={{ loadedGoogleApi }}>
              <ToastContainer />
              <LoginModal
                open={displayLoginModal}
                setOpen={setDisplayLoginModal}
              />
              <NavBar
                navbarItems={navbarItems}
                loginOnClick={() => setDisplayLoginModal(!displayLoginModal)}
              />
              <HamburgerNav
                navbarItems={navbarItems}
                loginOnClick={() => setDisplayLoginModal(!displayLoginModal)}
              />
              <ScrollToTop>
                <Switch>
                  {/* <ScrollToTop /> */}
                  <Route path="/" exact component={NewHome} />
                  <Route path="/browse" component={Browse} />
                  <Route path="/about" component={About} />
                  {/* <Route path="/advertise" component={Register} /> */}
                  <Route path="/business/:id" component={Business} />
                  {/* <Route path="/map" component={Map} /> */}
                  {/* <Route path="/register" exact component={Register} /> */}
                  <Route path="/advertise" component={Register} />
                  <Route path="/privacy-policy" component={PrivacyPolicy} />
                  <Route
                    path="/terms-and-conditions"
                    component={TermsAndConditions}
                  />
                  <Route
                    path="/verify-email/:verificationCode"
                    component={VerifyEmail}
                  />
                  <Route path="/forgot-password" component={ForgotPassword} />
                  <Route path="/profile/:id" component={Profile} />
                  <Route path="/payment-complete" component={PaymentComplete} />
                  <Route
                    path="/payment-cancelled"
                    component={PaymentCancelled}
                  />
                  <Route
                    path="/reset-password/:code"
                    component={ResetPassword}
                  />
                  <Route
                    path="/edit-business/:businessId"
                    component={EditBusiness}
                  />
                  {/* <Route
                  path="/category-and-feature-insert"
                  component={CategoryAndFeatureInsert}
                /> */}
                  <Route path="/contact-us" component={Contact} />
                </Switch>
              </ScrollToTop>
              <Footer />
            </GoogleApiLoadContext.Provider>
          </ToastContext.Provider>
        </LoggedInContext.Provider>
      </LoginModalContext.Provider>
    </Router>
  );
};

export default withRouter(App);
