import React from "react";
import { GoChevronDown } from "react-icons/go";

export const AccordionHeader = ({ children }) => {
//   const { setActiveItem } = useContext(AccordionContext);
  return (
    <div
      className="flex items-center p-3 border rounded-md"
    //   onClick={() => setActiveItem("categories")}
    >
      {children}
      <GoChevronDown className="ml-auto text-2xl text-cyan-900" />
    </div>
  );
};
