import { AnimatePresence, motion } from "framer-motion";
import { assoc, isEmpty, mergeRight, not, path, pluck } from "ramda";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import { FilterModal } from "../components/Browse/FilterModal";
import { FeatureCard1 } from "../components/FeatureCard1";
import { LogoLoader } from "../components/LogoLoader";
import { Pagination } from "../components/Pagination/Pagination";
import { ShadowSearch } from "../components/ShadowSearch";
import { UndrawNoData } from "../components/Undraw/UndrawNoData";
import { ToastContext } from "../contexts/ToastContext";
import { query1 } from "../services/graphqlService";
import { ToastType } from "../utils/enums/ToastType";
import {
  getDayOfTheWeek,
  indexedMap,
  joinStrings,
} from "../utils/util-methods";

const animationTime = 0.1;

export const Browse = () => {
  //state
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [pageData, setPageData] = useState({
    categories: [],
    features: [],
  });
  const [loading, setLoading] = useState(false);
  const [loadingPageData, setLoadingPageData] = useState(false);
  const { addToast } = useContext(ToastContext);
  const [paginatedBusinesses, setPaginatedBusinesses] = useState({
    limit: 12,
    total: 0,
    data: [],
    skip: 0,
    filters: {
      categories: [],
      features: [],
    },
    sort: {
      column: "name",
      order: "asc",
    },
  });
  const [businessSearch, setBusinessSearch] = useState("");

  const fetchCategoriesAndFeatures = useCallback(async () => {
    setLoadingPageData(true);
    try {
      const { data } = await query1(`
          categories(find: {parentCategoryId: null}) {
            id
            name
          }
          features {
            id
            name
          }
          `);
      setLoading(false);

      setPageData((prev) => mergeRight(prev, data));
    } catch (err) {
      addToast(ToastType.Error, "Failed to load data");
    } finally {
      setLoadingPageData(false);
    }
  }, [addToast]);

  const fetchBusinesses = useCallback(
    async ({ skip = 0 }) => {
      if (!!paginatedBusinesses.limit) {
        setLoading(true);
        try {
          const { data } = await query1(`
          businesses(paginate: {take: ${paginatedBusinesses.limit}, skip: ${
            skip * paginatedBusinesses.limit
          }}, find: {categoryIds: [${pluck(
            "id",
            paginatedBusinesses.filters.categories
          )}], featureIds: [${pluck(
            "id",
            paginatedBusinesses.filters.features
          )}]}, orderBy: {${paginatedBusinesses.sort.column}: ${
            paginatedBusinesses.sort.order
          }}) {
            total
            data {
              id
              name
              phoneNumber
              images(find: {type: Logo}) {
                url
              }
              operatingTimes(find: {day: ${getDayOfTheWeek()}}) {
                openingTime
                closingTime
              }
              categories {
                name
              }
              avgRating
            }
          }
          `);
          setLoading(false);

          setPaginatedBusinesses((prev) => mergeRight(prev, data.businesses));
        } catch (err) {
          addToast(ToastType.Error, "Failed to load data");
        } finally {
          setLoading(false);
        }
      }
    },
    [
      addToast,
      paginatedBusinesses.limit,
      paginatedBusinesses.filters.categories,
      paginatedBusinesses.filters.features,
      paginatedBusinesses.sort,
    ]
  );

  const getBusinessesByName = useCallback(
    async ({ skip = 0 }) => {
      setLoading(true);
      try {
        if (isEmpty(businessSearch)) {
          return;
        }
        const { data } = await query1(`
        businesses(paginate: {take: ${paginatedBusinesses.limit}, skip: ${
          skip * paginatedBusinesses.limit
        }}, find:{name: "${businessSearch}"}) {
          total
          data {
            id
            name
            phoneNumber
            images(find: {type: Logo}) {
              url
            }
            operatingTimes(find: {day: ${getDayOfTheWeek()}}) {
              openingTime
              closingTime
            }
            categories {
              name
            }
            avgRating
          }
        }
        `);

        setPaginatedBusinesses((prev) =>
          mergeRight(prev, { ...data.businesses })
        );
      } catch (err) {
        addToast(ToastType.Error, "Failed to search for businesses");
      } finally {
        setLoading(false);
      }
    },
    [
      addToast,
      businessSearch,
      paginatedBusinesses?.limit,
      // paginatedBusinesses?.skip,
    ]
  );

  useEffect(() => {
    if (!isEmpty(businessSearch)) {
      const debounce = setTimeout(async () => {
        await getBusinessesByName({ skip: 0 });
      }, 1000);

      return () => {
        clearTimeout(debounce);
      };
    } else {
      fetchBusinesses({ skip: 0 });
    }
  }, [businessSearch, getBusinessesByName, fetchBusinesses]);

  useEffect(() => {
    fetchCategoriesAndFeatures();
  }, [fetchCategoriesAndFeatures]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [paginatedBusinesses.skip]);

  return (
    <Fragment>
      <FilterModal
        open={filterModalOpen}
        setOpen={setFilterModalOpen}
        categories={pageData.categories}
        features={pageData.features}
        setPaginatedBusinesses={setPaginatedBusinesses}
      />
      <div className={joinStrings("mb-10 container mx-auto", "md:mt-10")}>
        <div className="flex flex-col space-y-10">
          <div
            className={joinStrings(
              "w-full bg-white rounded-lg p-10 shadow-md",
              "transition-shadow duration-300",
              "hover:shadow-2xl"
            )}
          >
            <div
              className={joinStrings(
                "flex flex-col space-y-3 space-x-0",
                "md:flex-row md:space-x-3 md:space-y-0 md:items-center"
              )}
            >
              <ShadowSearch
                value={businessSearch}
                id="businessSearch"
                placeHolder="Search by name"
                className="w-full"
                onChange={({ target: { value } }) => {
                  setBusinessSearch(value);
                }}
                // loading={loadingSearch}
                loading={loading}
                useOptions={false}
                clearable
                onClear={() => {
                  setBusinessSearch("");
                  setPaginatedBusinesses((prev) => assoc("skip", 0, prev));
                }}
              />
            </div>
            <div className="flex items-center space-x-2 mt-2">
              <AiOutlineInfoCircle className="text-gray-500 text-lg" />
              <p className="text-gray-400 text-sm">
                Searching by name will ignore all applied filters
              </p>
            </div>

            <div className="flex flex-wrap items-baseline">
              <button
                className={joinStrings(
                  "px-8 py-3 bg-cyan-900 text-white cursor-pointer flex justify-center space-x-3 items-center mt-10 rounded mr-3",
                  "focus:outline-none"
                )}
                onClick={() => setFilterModalOpen((prev) => !prev)}
                disabled={loadingPageData}
              >
                <span>Filters</span>
                <FiFilter className="text-xl" />
              </button>

              {indexedMap(
                ({ name }, i) => (
                  <span
                    key={`selected-category-${i}`}
                    className={joinStrings(
                      "text-white px-5 py-1 rounded-full mr-3 mt-2",
                      isEmpty(businessSearch)
                        ? "bg-green-600"
                        : "bg-gray-200 cursor-default"
                    )}
                  >
                    {name}
                  </span>
                ),
                paginatedBusinesses.filters.categories
              )}

              {indexedMap(
                ({ name }, i) => (
                  <span
                    key={`selected-category-${i}`}
                    className={joinStrings(
                      "text-white px-5 py-1 rounded-full mr-3 mt-2",
                      isEmpty(businessSearch)
                        ? "bg-green-600"
                        : "bg-gray-200 cursor-default"
                    )}
                  >
                    {name}
                  </span>
                ),
                paginatedBusinesses.filters.features
              )}
            </div>
          </div>

          <div className="rounded-lg">
            <AnimatePresence exitBeforeEnter>
              {loading && <LogoLoader />}
              {!loading && isEmpty(paginatedBusinesses.data) && (
                <motion.div
                  key="not-found-card"
                  className={joinStrings(
                    "flex flex-col items-center space-y-8 px-10 py-10 h-96 bg-white shadow-lg rounded-lg w-full max-w-max mx-auto",
                    "transition-shadow duration-300",
                    "hover:shadow-2xl"
                  )}
                  animate={{
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: "spring",
                      mass: 1,
                      duration: animationTime,
                    },
                  }}
                  initial={{ y: 200, opacity: 0 }}
                >
                  <h4 className="text-cyan-900 max-w-sm text-center">
                    Oops! Looks like we couldn't find any businesses matching
                    your filters.
                  </h4>

                  <UndrawNoData className="h-44 w-44" />
                </motion.div>
              )}
              {!loading && not(isEmpty(paginatedBusinesses.data)) && (
                <motion.div
                  className={joinStrings(
                    "grid grid-cols-1 gap-5",
                    "sm:grid-cols-2",
                    "md:grid-cold-2",
                    "lg:grid-cols-3",
                    "xl:grid-cols-2"
                  )}
                >
                  {paginatedBusinesses.data.map(
                    (
                      {
                        id,
                        name,
                        images,
                        categories,
                        avgRating,
                        phoneNumber,
                        operatingTimes,
                      },
                      i
                    ) => (
                      <motion.div
                        key={"business card " + i}
                        animate={{
                          y: 0,
                          opacity: 1,
                          transition: {
                            type: "spring",
                            mass: 1,
                            duration: animationTime,
                            delay: animationTime * i,
                          },
                        }}
                        initial={{ y: -200, opacity: 0 }}
                        className="flex justify-center"
                      >
                        <FeatureCard1
                          businessId={id}
                          name={name}
                          phoneNumber={phoneNumber}
                          logo={path([0], images).url}
                          categories={categories}
                          operatingTimes={operatingTimes}
                          rating={Math.round(avgRating)}
                        />
                      </motion.div>
                    )
                  )}
                </motion.div>
              )}
            </AnimatePresence>

            {not(isEmpty(paginatedBusinesses.data)) && (
              <div className="flex justify-end mt-10">
                <Pagination
                  skip={paginatedBusinesses.skip}
                  limit={paginatedBusinesses.limit}
                  total={paginatedBusinesses.total}
                  setPaginated={setPaginatedBusinesses}
                  loading={loading}
                  fetch={(skip) => {
                    if (!isEmpty(businessSearch)) {
                      getBusinessesByName({ skip });
                    } else {
                      fetchBusinesses({ skip });
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* </BrowseFilterContext.Provider> */}
    </Fragment>
  );
};
