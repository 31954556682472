import React from "react";

export const Toggle = ({ checked = false, onClick }) => {
  // const [isToggled, setIsToggled] = useState(false);
  return (
    <div
      className="inline-flex w-max focus-within:ring-2 ring-opacity-50 rounded-full focus-within:ring-blue-300 h-5"
      onClick={onClick}
    >
      <input
        type="checkbox"
        className={`appearance-none focus:outline-none w-9 py-2.5 h-auto rounded-full ${
          checked ? "bg-cyan-900" : "bg-gray-500"
        }`}
      />
      <input
        type="checkbox"
        className={`absolute appearance-none focus:outline-none outline-none p-2 bg-white rounded-full w-max transform duration-300 mt-0.5 ${
          checked ? "translate-x-4.5" : "translate-x-0.5"
        }`}
      />
      {/* <div
        className="inline-flex flex-col w-10 py-0.5 pl-0.5 rounded-full bg-gray-500 active:ring active:ring-blue-400"
        onClick={() => setIsToggled(!isToggled)}
      >
        <div
          className={`p-2 bg-white rounded-full w-max transform duration-300 ${
            isToggled ? "translate-x-5" : "translate-x-0"
          }`}
        ></div>
      </div> */}
    </div>
  );
};
