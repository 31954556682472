import { isNil, mergeRight, path } from "ramda";
import React, { Fragment, useEffect, useState } from "react";
import { joinStrings } from "../../utils/util-methods";
import { StepperContext } from "./StepperContext";

export const Stepper = ({
  steps,
  className,
  startStep = 0,
  parentState = null,
}) => {
  const [determiningContent, setDeterminingContent] = useState(true);
  const [activeStep, setActiveStep] = useState(startStep | 0);
  const [activeContent, setActiveContent] = useState(null);
  const [state, setState] = useState(null);

  useEffect(() => {
    if (!isNil(steps) && !isNil(startStep) && activeStep <= startStep) {
      setActiveStep(startStep);
    }
    setDeterminingContent(false);
  }, [startStep, steps, activeStep]);

  useEffect(() => {
    if (!!parentState) {
      setState((prev) => mergeRight(prev, parentState));
    }
  }, [parentState]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  return (
    <div className="flex flex-col space-y-5">
      <div
        className={joinStrings(
          "inline-flex items-center justify-between flex-col",
          "md:flex-row",
          className
        )}
      >
        {steps.map(({ name }, i) => (
          <Fragment key={`stepper-item-${name}-${i}`}>
            <div className={joinStrings("flex items-center")}>
              <div
                className={joinStrings(
                  "flex flex-col px-5 py-3 space-x-2 items-center",
                  "hover:bg-gray-100",
                  "md:flex-row"
                )}
                // onClick={() =>
                //   setActive((prevActive) => ({ step: i, content: active.content[i] }))
                // }
              >
                <span
                  className={joinStrings(
                    "rounded-full text-white p-4 text-lg h-0 w-0 flex items-center justify-center bg-gray-300",
                    activeStep === i ? "bg-cyan-900" : "",
                    i < activeStep ? "bg-green-500" : ""
                  )}
                >
                  {i + 1}
                </span>
                <span>{name}</span>
              </div>
            </div>
            {i < steps.length - 1 && (
              <div className="bg-gray-300 h-0.5 flex-grow"></div>
            )}
          </Fragment>
        ))}
      </div>
      <StepperContext.Provider
        value={{ activeStep, setActiveStep, state, setState, setActiveContent }}
      >
        {!!activeContent && !determiningContent && activeContent}
        {!isNil(path([activeStep], steps)?.content) &&
          !determiningContent &&
          path([activeStep], steps)?.content}
        {!activeContent &&
          isNil(path([activeStep], steps)?.content) &&
          !determiningContent && (
            <div className="p-30">
              <h1>NO CONTENT</h1>
            </div>
          )}
        {determiningContent && <div className="h-screen"></div>}
      </StepperContext.Provider>
    </div>
  );
};
