import { append, defaultTo, includes, indexOf, remove } from "ramda";
import React, { useContext } from "react";
import { AccordionContext } from "./Accordion";
import { AccordionItemContext } from "./AccordionItem";

const appendNameIfNotInActiveItems = ({ prevActiveItems, name }) => {
  const items = defaultTo([], prevActiveItems);
  if (!includes(name, items)) {
    return append(name, items);
  } else {
    return remove(indexOf(name, items), 1)(items);
  }
};

const setNameIfNotActive = ({ prevActiveItem, name }) => {
  return name === prevActiveItem ? "" : name;
};

export const AccordionToggle = ({ children }) => {
  const { setActiveItems, allowAllOpen } = useContext(AccordionContext);
  const { name } = useContext(AccordionItemContext);
  return (
    <span
      onClick={() =>
        setActiveItems((prev) =>
          allowAllOpen
            ? appendNameIfNotInActiveItems({ prevActiveItems: prev, name })
            : setNameIfNotActive({ prevActiveItem: prev, name })
        )
      }
      className="cursor-pointer"
    >
      {children}
    </span>
  );
};
