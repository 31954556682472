import React, { useState } from "react";
import { joinStrings } from "../utils/util-methods";

export const Popup = ({
  text,
  children,
  startRight = false,
  startLeft = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="flex justify-center relative">
      {isHovered && !startRight && !startLeft && (
        <div
          className={joinStrings(
            "absolute py-1 px-3 bg-gray-100 text-black text-xs w-44 max-w-max bottom-7 rounded break-normal"
          )}
        >
          {text}
        </div>
      )}
      {isHovered && startRight && (
        <div
          className={joinStrings(
            "absolute py-1 px-3 bg-gray-100 text-black text-xs w-44 bottom-7 rounded break-normal right-0"
          )}
        >
          {text}
        </div>
      )}
      {isHovered && startLeft && (
        <div
          className={joinStrings(
            "absolute py-1 px-3 bg-gray-100 text-black text-xs w-44 bottom-7 rounded break-normal left-0"
          )}
        >
          {text}
        </div>
      )}
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {children}
      </div>
    </div>
  );
};
