import { motion } from "framer-motion";
import React from "react";
import { HiOutlineMail } from "react-icons/hi";
import { RiLockPasswordLine } from "react-icons/ri";
import { joinStrings } from "../utils/util-methods";

export const ShadowInput = ({
  id,
  name,
  value,
  className,
  placeHolder,
  onChange,
  onBlur,
  type = "text",
  error,
  touched = false,
  maxLength,
  label,
  icon,
}) => {
  return (
    <div className={className}>
      <div className="relative flex items-center">
        {/* {!!label && (
        <label htmlFor={id} className="text-gray-600 text-base">
          {label}
        </label>
      )} */}
        {type === "email" && !icon && (
          <div className="absolute left-3">
            <HiOutlineMail className="text-2xl text-gray-400" />
          </div>
        )}
        {type === "password" && !icon && (
          <div className="absolute left-3">
            <RiLockPasswordLine className="text-2xl text-gray-400" />
          </div>
        )}
        {!!icon && <div className="absolute left-3">{icon}</div>}
        <motion.input
          id={id}
          name={name}
          value={value}
          type={type}
          className={joinStrings(
            "pl-11 pr-2 py-3.5 w-full placeholder-gray-300 bg-white rounded border border-gray-400",
            "transition-colors duration-300",
            "focus:outline-none focus:border-cyan-700"
          )}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={maxLength}
          placeholder={placeHolder}
        />
      </div>
      {!!error && touched && (
        <span className="mt-0 text-red-600 ml-1">{error}</span>
      )}
    </div>
  );
};
