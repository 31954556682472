import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { IoCheckmarkCircle } from "react-icons/io5";
import { withRouter } from "react-router";
import { joinStrings } from "../utils/util-methods";
import { PrimaryButton } from "./PrimaryButton";

export const AdvertiseCard = withRouter(
  ({
    heading,
    monthly,
    cost,
    className,
    history,
    packageId,
    undraw,
    shouldTranslate = true,
    buttonText,
    onClick,
  }) => {
    return (
      <div
        className={joinStrings(
          "rounded-lg px-5 pt-5 pb-5 w-80 shadow-lg border",
          "sm:border-transparent",
          "md:w-96",
          "transform duration-300",
          "group-hover:bg-gray-800",
          `hover:shadow-2xl ${shouldTranslate ? "hover:-translate-y-2" : ""}`,
          className
        )}
      >
        <h3
          className={joinStrings(
            " tracking-widest text-gray-600 text-center",
            "md:text-left",
            "group-hover:text-gray-400"
          )}
        >
          {heading}
        </h3>
        <div
          className={joinStrings(
            "flex flex-col items-center space-x-1 border-b border-gray-300 pb-3",
            "md:flex-row"
          )}
        >
          <h2
            className={joinStrings(
              "text-cyan-900 font-medium tracking-wide",
              "group-hover:text-white"
            )}
          >{`R${cost}.00`}</h2>
          <h4
            className={joinStrings("text-gray-400", "group-hover:text-white")}
          >
            {monthly}
          </h4>
        </div>

        <div
          className={joinStrings(
            "flex flex-col space-y-1 mt-3 text-cyan-900 mb-10",
            "group-hover:text-gray-300"
          )}
        >
          <div className="flex space-x-2 items-center text-lg">
            <IoCheckmarkCircle className="text-xl" />
            <span>1 Subscription</span>
          </div>

          <div className="flex space-x-2 items-center text-lg">
            <IoCheckmarkCircle className="text-xl" />
            <span>1 Daily facebook post</span>
          </div>

          <div className="flex space-x-2 items-center text-lg">
            <IoCheckmarkCircle className="text-xl" />
            <span>1 Business Listing</span>
          </div>
        </div>

        {!!undraw && undraw}
        <PrimaryButton
          className={joinStrings(
            "flex items-center justify-between w-full mt-10",
            "group-hover:bg-gray-600 group-hover:text-white group-hover:border-gray-600"
          )}
          onClick={() => {
            if (!!onClick) {
              onClick();
            } else {
              history.push(`/advertise`);
            }
          }}
        >
          <span>{buttonText || "Advertise"}</span>
          <BsArrowRight />
        </PrimaryButton>
      </div>
    );
  }
);
