import React from 'react'
import { Link } from 'react-router-dom'

export const HamburgerNavItem = ({
    to,
    text
}) => {
    return(
        <Link to={to} className="p-3 transform duration-1000 rounded-md text-cyan-900 bg-gray-50 w-full text-sm">
            {text}
        </Link>
    )
}
