import { AnimatePresence, motion } from "framer-motion";
import { wrap } from "popmotion";
import { isEmpty } from "ramda";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";
import { joinStrings } from "../utils/util-methods";
import { LogoLoader } from "./LogoLoader";

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 535 : -535,
      scale: 0.3,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
    scale: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 535 : -535,
      scale: 0.6,
      opacity: 0,
    };
  },
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

export const RegisterCarousel = ({
  images,
  removeImage,
  removable = false,
  // startingImage = null,
  swipeOnChange = false,
}) => {
  //state
  const [[page, direction], setPage] = useState([0, 0]);
  const [load, setLoad] = useState(false);
  const [startLoad, setStartLoad] = useState(false);

  const imageIndex = wrap(0, images.length, page);

  const paginate = useCallback(
    (newDirection) => {
      setPage([page + newDirection, newDirection]);
    },
    [page]
  );

  // useEffect(() => {
  //   console.log();
  // }, [])

  useEffect(() => {
    if (swipeOnChange) {
      if (images.length > 1) {
        paginate(images.length - 1 - page);
      }
    }
    //eslint-disable-next-line
  }, [images.length]);

  useEffect(() => {
    if (!startLoad) {
      setLoad(false);
    }
    const startLoader = setTimeout(() => {
      if (startLoad) {
        setLoad(true);
      }
    }, 500);

    return () => {
      clearTimeout(startLoader);
    };
  }, [startLoad]);

  return (
    <Fragment>
      {!isEmpty(images) && (
        <Fragment>
          <div className="flex-col space-y-5 flex h-full w-full">
            <div className="flex h-full space-x-5">
              <div
                className={joinStrings(
                  "p-4 bg-white rounded-full my-auto cursor-pointer hidden ml-auto shadow-md border",
                  "transition-shadow duration-300",
                  "hover:shadow-xl",
                  "md:block"
                )}
                onClick={() => {
                  paginate(-1);
                  setStartLoad(true);
                }}
              >
                <FaChevronLeft className="text-gray-400" />
              </div>

              <motion.div className="relative flex justify-center w-full mx-auto overflow-hidden items-center">
                {removable && (
                  <TiDelete
                    className={joinStrings(
                      "absolute right-3 top-3 z-10 text-red-600 cursor-pointer h-10 w-10 p-0 bg-white rounded-full",
                      "focus:outline-none"
                    )}
                    onClick={() => {
                      removeImage(imageIndex);
                      paginate(-1);
                      // setStartLoad(true);
                    }}
                  />
                )}
                <AnimatePresence initial={false} custom={direction}>
                  {load && <LogoLoader />}
                  <motion.img
                    onLoad={() => setStartLoad(false)}
                    key={`carousel-image-${page}`}
                    className={joinStrings(
                      !load
                        ? "absolute w-full h-auto max-h-full object-contain block"
                        : "hidden invisible"
                    )}
                    src={images[imageIndex]}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                      x: { type: "spring", stiffness: 300, damping: 30 },
                      opacity: { duration: 0.2 },
                    }}
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={1}
                    onDragEnd={(e, { offset, velocity }) => {
                      const swipe = swipePower(offset.x, velocity.x);

                      if (swipe < -swipeConfidenceThreshold) {
                        paginate(1);
                        setStartLoad(true);
                      } else if (swipe > swipeConfidenceThreshold) {
                        paginate(-1);
                        setStartLoad(true);
                      }
                    }}
                  />
                </AnimatePresence>
              </motion.div>
              <div
                className={joinStrings(
                  "p-4 bg-white rounded-full my-auto cursor-pointer hidden ml-auto shadow-md border",
                  "transition-shadow duration-300",
                  "hover:shadow-xl",
                  "md:block"
                )}
                onClick={() => {
                  paginate(1);
                  setStartLoad(true);
                }}
              >
                <FaChevronRight className="text-gray-400" />
              </div>
            </div>

            <div className="flex justify-center space-x-5">
              {images.map((_, i) => (
                <div
                  key={`carouselImg${i}`}
                  className={joinStrings(
                    "px-5 py-0.5 block",
                    i === imageIndex ? "bg-black" : "bg-cyan-500"
                  )}
                ></div>
              ))}
            </div>
          </div>
        </Fragment>
      )}
      {/* {isEmpty(images) && (
        <div className="h-full w-full flex justify-center items-center">
          <p>No gallery images to</p>
        </div>
      )} */}
    </Fragment>
  );
};
