import React from "react";
import { joinStrings } from "../../utils/util-methods";
import { CloseButton } from "../CloseButton";
import { Modal } from "../Modal";
import { PrimaryButton } from "../PrimaryButton";

export const ProfileEditModal = ({
  open,
  setOpen,
  header,
  children,
  onSubmit,
  onClose,
  loading = false,
}) => {
  return (
    <Modal open={open} setOpen={setOpen}>
      <div className={joinStrings("bg-white rounded-lg", "sm:w-96")}>
        <div className="flex justify-center items-center h-20 border-b border-gray-200">
          <h3 className="text-cyan-900">{header}</h3>
        </div>

        {children}

        <div className="flex justify-end items-center h-20 px-10 space-x-3 border-t border-gray-200 mt-5">
          <CloseButton
            onClick={() => {
              if (onClose) {
                onClose();
              }
              setOpen(false);
            }}
          >
            Close
          </CloseButton>
          <PrimaryButton
            onClick={onSubmit}
            loading={loading}
            disabled={loading}
          >
            Submit
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};
