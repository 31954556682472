import React from "react";
import { MdModeEdit } from "react-icons/md";
import { joinStrings } from "../../utils/util-methods";

export const ProfileEdit = ({ text, onEditClick, icon, editable = true }) => {
  return (
    <div className="relative flex items-center">
      {!!icon && <div className="absolute left-3">{icon}</div>}
      <div
        className={joinStrings(
          "flex items-center justify-between pl-11 pr-5 py-3 w-full placeholder-gray-300 bg-white rounded border border-gray-200"
          // "transition-colors duration-300",
          // "focus:outline-none focus:border-cyan-700"
        )}
      >
        <p className="overflow-x-auto w-full scrollbar-hidden">{text}</p>
        {/* <Popup text="Edit"> */}
        {editable && (
          <MdModeEdit
            className="text-cyan-600 absolute right-3 w-5 h-5 cursor-pointer"
            onClick={onEditClick}
          />
        )}
        {/* </Popup> */}
      </div>
    </div>
  );
};
