import { getDay } from "date-fns";
import jwt_decode from "jwt-decode";
import { wrap } from "popmotion";
import {
  addIndex,
  converge,

  divide,
  equals,
  filter,
  forEach,
  isEmpty,
  isNil,
  length,
  map,
  pipe,
  sum,
  values
} from "ramda";
import { DayOfWeek } from "./enums/DayOfWeek";

export const joinStrings = (...params) => {
  return params.join(" ");
};

export const getToken = () => {
  const token = localStorage.getItem("token");
  if (isNil(token) || isEmpty(token)) {
    throw Error("No token found");
  }
  return localStorage.getItem("token");
};

export const getTokenUnsafe = () => {
  return localStorage.getItem("token");
};

export const getUserId = () => {
  const { id } = jwt_decode(getToken());
  return id;
};

export const getAverage = (args) => {
  const avg = converge(divide, [sum, length])(args);
  return isNaN(avg) ? 0 : avg;
};

export const isTruthy = pipe(Boolean, equals(true));
export const isFalsey = pipe(Boolean, equals(false));

export const indexedForEach = addIndex(forEach);
export const indexedMap = addIndex(map);
export const indexedFilter = addIndex(filter);

export const getEnvVariable = (variableName) => {
  const envVariable = process.env[`REACT_APP_${variableName}`];
  if (isNil(envVariable)) {
    throw Error(`Env variable: ${variableName} not found`);
  }
  return envVariable;
};

export const getDayOfTheWeek = () => {
  const today = new Date();
  const daysOfTheWeek = values(DayOfWeek);
  const dayOfTheWeek = wrap(0, 7, getDay(today) - 1);
  return daysOfTheWeek[dayOfTheWeek];
};
