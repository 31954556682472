import React from "react";
import { NavLink } from "react-router-dom";
import { joinStrings } from "../utils/util-methods";

export const NavbarItem = ({ to, text }) => {
  return (
    <NavLink
      to={to}
      activeClassName="bg-cyan-600 shadow-lg"
      exact
      className={joinStrings(
        "p-2 px-4 text-white rounded",
        "transition-all duration-300",
        "hover:shadow-2xl hover:bg-cyan-500"
      )}
    >
      {text}
    </NavLink>
  );
};
