import React, { Fragment } from "react";
import { AiFillWarning } from "react-icons/ai";
import { MdError } from "react-icons/md";
import { MessageType } from "../utils/enums/MessageType";
import { joinStrings } from "../utils/util-methods";

export const Message = ({
  type = MessageType.Success,
  children,
  className,
}) => {
  return (
    <Fragment>
      {type === MessageType.Success && (
        <div
          className={joinStrings(
            "px-5 py-2 border border-green-600 bg-green-100 text-green-600 rounded",
            className
          )}
        >
          {children}
        </div>
      )}
      {type === MessageType.Error && (
        <div
          className={joinStrings(
            "px-5 py-2 border border-red-600 bg-red-100 text-red-600 rounded flex items-center space-x-3",
            className
          )}
        >
          <MdError className="text-2xl flex-shrink-0" />
          <p className="">{children}</p>
        </div>
      )}
      {type === MessageType.Warn && (
        <div
          className={joinStrings(
            "px-5 py-2 border border-yellow-600 bg-yellow-100 text-yellow-600 rounded flex items-center space-x-3",
            className
          )}
        >
          <AiFillWarning className="text-2xl flex-shrink-0" />
          <span>{children}</span>
        </div>
      )}
      {type === MessageType.Info && (
        <div
          className={joinStrings(
            "px-5 py-2 border border-cyan-600 bg-cyan-100 text-cyan-600 rounded",
            className
          )}
        >
          {children}
        </div>
      )}
    </Fragment>
  );
};
