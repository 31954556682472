import { motion } from "framer-motion";
import React, { useContext, useState } from "react";
import { GiHamburgerMenu, IoClose } from "react-icons/all";
import { Link } from "react-router-dom";
import { LoggedInContext } from "../contexts/LoggedInContext";
import { getUserId } from "../utils/util-methods";
import { HamburgerNavItem } from "./HamburgerNavItem";
import { Logo } from "./SVG/Logo";

export const HamburgerNav = ({ navbarItems, loginOnClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isLoggedIn, logout } = useContext(LoggedInContext);
  return (
    <div className="flex items-center justify-between w-full bg-cyan-900 px-4 lg:hidden h-14 sticky top-0 z-50">
      <Link to="/" className="flex items-center bg-cyan-600 min-h-full pl-2 pr-5">
        <Logo className="h-14 w-14 p-1" />
        <span className="text-3xl text-white font-semibold transform duration-500">
          Syncvine
        </span>
      </Link>

      <button className="focus:outline-none text-white">
        {!isOpen && (
          <motion.div
            className="text-white"
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <GiHamburgerMenu
              className={`h-8 w-8`}
              onClick={() => setIsOpen(!isOpen)}
            />
          </motion.div>
        )}
        {isOpen && (
          <motion.div
            className="z-30 text-white"
            animate={{ opacity: 1, position: "fixed" }}
            initial={{ opacity: 0, right: 15, top: 15 }}
            transition={{ duration: 0.5 }}
          >
            <IoClose
              className={`h-8 w-8 text-white`}
              onClick={() => setIsOpen(!isOpen)}
            />
          </motion.div>
        )}
      </button>

      {isOpen && (
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          exit={{ opacity: 0 }}
          className={`flex flex-col mx-3 space-y-3 bg-white rounded-lg left-0 right-0 p-3 shadow absolute top-0 mt-12 space-y-3 overflow-y-auto max-h-screen`}
        >
          {navbarItems?.map((x, i) => (
            <HamburgerNavItem {...x} key={"hamburgerNavbarItem " + i} />
          ))}
          {!isLoggedIn && (
            <button
              className="btn p-3 rounded-md text-cyan-900 bg-gray-50 w-full cursor-pointer text-sm text-left"
              onClick={() => {
                setIsOpen(false);
                loginOnClick();
              }}
            >
              Login/Register
            </button>
          )}

          {isLoggedIn && (
            <Link
              to={`/profile/${getUserId()}`}
              className="btn p-3 rounded-md text-cyan-900 bg-gray-50 w-full cursor-pointer text-sm"
            >
              Profile
            </Link>
          )}
          {isLoggedIn && (
            <button
              className="btn p-3 rounded-md text-cyan-900 bg-gray-50 w-full cursor-pointer text-sm text-left"
              onClick={() => logout()}
            >
              Logout
            </button>
          )}
        </motion.div>
      )}
    </div>
  );
};
