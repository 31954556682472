import axios from "axios";
import { getTokenUnsafe } from "../utils/util-methods";

export const createAxios = (url) => {
  const axiosInstance = axios.create({
    baseURL: url,
  });

  axiosInstance.interceptors.request.use(async (config) => {
    const token = getTokenUnsafe();
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  });

  return axiosInstance;
};
