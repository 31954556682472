import format from "date-fns/format";
import React, { Fragment, useState } from "react";
import { AiFillCreditCard, AiOutlineEye, AiOutlineStop } from "react-icons/ai";
import { MdAutorenew, MdModeEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import { joinStrings } from "../../utils/util-methods";
import { CancelSubscriptionModal } from "./Modals/CancelSubscriptionModal";
import { RenewSubscriptionModal } from "./Modals/RenewSubscriptionModal";
import { UpdateSubscriptionModal } from "./Modals/UpdateSubscriptionModal/UpdateSubscriptionModal";
import { ProfileBusinessCardOption } from "./ProfileBusinessCardOption";

export const ProfileBusinessCard = ({
  businessId,
  image,
  name,
  activatedAt,
  createdAt,
  payfastToken,
  userId,
  firstName,
  lastName,
  email,
  // user,
  subscriptionPlan,
  refreshBusinesses,
  activeUntil,
  hasConfirmedCancellation,
  hasOpenCancellation,
  isSpecial,
}) => {
  // const { addToast } = useContext(ToastContext);
  const [isCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] =
    useState(false);
  const [isRenewSubscriptionModalOpen, setIsRenewSubscriptionModalOpen] =
    useState(false);
  const [isUpdateSubscriptionModalOpen, setIsUpdateSubscriptionModalOpen] =
    useState(false);

  return (
    <Fragment>
      <CancelSubscriptionModal
        open={isCancelSubscriptionModalOpen}
        setOpen={setIsCancelSubscriptionModalOpen}
        businessId={businessId}
        refreshBusinesses={refreshBusinesses}
      />
      <RenewSubscriptionModal
        open={isRenewSubscriptionModalOpen}
        setOpen={setIsRenewSubscriptionModalOpen}
        businessId={businessId}
        businessName={name}
        userId={userId}
        firstName={firstName}
        lastName={lastName}
        email={email}
      />
      <UpdateSubscriptionModal
        payfastToken={payfastToken}
        userId={userId}
        open={isUpdateSubscriptionModalOpen}
        setOpen={setIsUpdateSubscriptionModalOpen}
        businessName={name}
        currentPlan={subscriptionPlan}
        businessId={businessId}
        refreshBusinesses={refreshBusinesses}
      />
      <div
        className={joinStrings(
          "flex flex-col space-x-0 space-y-1 rounded-lg overflow-hidden bg-white shadow-md w-96",
          "transform duration-300",
          "hover:shadow-2xl",
          "xl:flex-row xl:space-y-0 xl:w-full xl:h-72"
        )}
      >
        <img
          src={image}
          alt="business_logo"
          className={joinStrings(
            "w-full h-64 object-scale-down bg-black",
            "xl:w-72 xl:h-full"
          )}
        />
        <div
          className={joinStrings(
            "flex flex-col px-5 py-2 w-full",
            "xl:py-3 xl:px-6"
          )}
        >
          {/* header */}
          <div className="flex flex-col space-y-2">
            <div
              className={joinStrings(
                "flex flex-col justify-between items-center",
                // "sm:flex-row",
                "xl:items-baseline"
              )}
            >
              <h4 className="font-semibold">{name}</h4>

              <div className="flex items-baseline space-x-2 text-xs">
                <p className="text-gray-600 text-sm">Subscription:</p>
                {!!activeUntil &&
                  !hasConfirmedCancellation &&
                  !hasOpenCancellation && (
                    <p className="text-green-600 text-sm">Active</p>
                  )}
                {!!activeUntil &&
                  (hasConfirmedCancellation || hasOpenCancellation) && (
                    <p className="text-green-600 text-sm">
                      Active until {format(new Date(activeUntil), "dd-MM-yyyy")}
                    </p>
                  )}
                {!activeUntil && (
                  <p className="text-red-600 text-sm">Cancelled</p>
                )}
              </div>
            </div>
            <div className="h-0.5 w-full bg-gray-200"></div>
          </div>

          {/* body */}
          <div
            className={joinStrings(
              "flex flex-col space-y-2 py-2 h-full overflow-y-auto scrollbar-hidden"
            )}
          >
            {!activeUntil && (
              <ProfileBusinessCardOption
                onClick={() => setIsRenewSubscriptionModalOpen(true)}
              >
                <MdAutorenew className="text-green-600" />
                <span>Renew Subscription</span>
              </ProfileBusinessCardOption>
            )}
            {(!!activeUntil || isSpecial) && (
              <Link
                to={`/business/${businessId}`}
                className={joinStrings(
                  "flex space-x-3 items-center rounded-md px-3 py-2 text-gray-700",
                  "transition-all duration-300",
                  "hover:shadow-lg"
                )}
              >
                <AiOutlineEye />
                <span>View</span>
              </Link>
            )}
            {(!!activeUntil || isSpecial) && (
              <Link
                to={`/edit-business/${businessId}`}
                className={joinStrings(
                  "flex space-x-3 items-center rounded-md px-3 py-2 text-gray-700",
                  "transition-all duration-300",
                  "hover:shadow-lg"
                )}
              >
                <MdModeEdit className="text-cyan-600" />
                <span>Edit</span>
              </Link>
            )}

            {(!!activeUntil || isSpecial) &&
              !hasConfirmedCancellation &&
              !hasOpenCancellation && (
                <ProfileBusinessCardOption
                  onClick={() => setIsUpdateSubscriptionModalOpen(true)}
                >
                  <AiFillCreditCard className="text-green-600" />
                  <span>Update Subscription</span>
                </ProfileBusinessCardOption>
              )}
            {(!!activeUntil || isSpecial) &&
              !hasConfirmedCancellation &&
              !hasOpenCancellation && (
                <ProfileBusinessCardOption
                  onClick={() => setIsCancelSubscriptionModalOpen(true)}
                >
                  <AiOutlineStop className="text-red-600" />
                  <span>Cancel Subscription</span>
                </ProfileBusinessCardOption>
              )}
          </div>

          {/* footer */}
          {/* <div
          className={joinStrings(
            "flex flex-col space-y-2 items-center justify-between pt-1",
            "sm:flex-row sm:space-y-0"
          )}
        >
          <p className="text-sm text-gray-500">
            Created on:{" "}
            <span>
              {!!createdAt && format(new Date(createdAt), "dd/MM/yyyy")}
            </span>
          </p>

          <p className="text-sm text-gray-500 text-right">
            Active since:{" "}
            {!!activatedAt && (
              <span>{format(new Date(activatedAt), "dd/MM/yyyy")}</span>
            )}
            {!activatedAt && <span>N/A</span>}
          </p>
        </div> */}
        </div>
      </div>
    </Fragment>
  );
};
