import React from "react";
import { joinStrings } from "../utils/util-methods";

export const ShadowTextArea = ({
  id,
  name,
  value,
  className,
  placeHolder,
  onChange,
  onBlur,
  error,
  touched = false,
  rows,
  label,
  resizable = false,
}) => {
  return (
    <div className={joinStrings("relative", className)}>
      {/* {!!label && (
        <label htmlFor={id} className="text-gray-600">
          {label}
        </label>
      )} */}
      <textarea
        id={id}
        name={name}
        value={value}
        className={joinStrings(
          "p-3 w-full bg-white placeholder-gray-300 rounded border border-gray-400",
          "transition-colors duration-300",
          "focus:outline-none focus:border-cyan-700",
          !resizable && "resize-none"
        )}
        placeholder={placeHolder}
        onChange={onChange}
        onBlur={onBlur}
        rows={rows}
      />
      {!!error && touched && (
        <span className="mt-0 text-red-600 ml-1">{error}</span>
      )}
    </div>
  );
};
