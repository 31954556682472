import { motion } from "framer-motion";
// import { ActiveContext } from "../contexts/ActiveContext";
import {
  dec,
  defaultTo,
  filter,
  find,
  isEmpty,
  isNil,
  keys,
  map,
  mergeDeepRight,
  path,
  pipe,
  prop,
  propEq,
} from "ramda";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  AiFillCreditCard,
  BiShareAlt,
  BiTimeFive,
  FaDirections,
  FaFacebookF,
  FaPenSquare,
  FaPhone,
  FaTwitter,
  FiGlobe,
  GrInstagram,
  GrPinterest,
  GrYoutube,
  RiGalleryFill,
} from "react-icons/all";
import { MdEmail, MdRateReview, MdWeb } from "react-icons/md";
import { LogoLoader } from "../components/LogoLoader";
import { PrimaryButton } from "../components/PrimaryButton";
import { RegisterCarousel } from "../components/RegisterCarousel";
import { Review } from "../components/Review";
import { ReviewModal } from "../components/ReviewModal";
import { SecondaryButton } from "../components/SecondaryButton";
import { ShareModal } from "../components/ShareModal";
import { StepperContext } from "../components/Stepper/StepperContext";
import { UndrawDescription } from "../components/Undraw/UndrawDescription";
import { LoggedInContext } from "../contexts/LoggedInContext";
import { ToastContext } from "../contexts/ToastContext";
import { createBusinessFromService, query1 } from "../services/graphqlService";
import {
  generateSignature,
  getNextBillingDate,
  getPayfastAmount,
  getSubscriptionFrequency,
} from "../services/payfastService";
import { BusinessImageType } from "../utils/enums/BusinessImageType";
import { BusinessSubscriptionPackage } from "../utils/enums/BusinessSubscriptionPackage";
import { DayOfWeek } from "../utils/enums/DayOfWeek";
import { SocialMediaPlatform } from "../utils/enums/SocialMediaPlatforms";
import { ToastMessage } from "../utils/enums/ToastMessage";
import { ToastType } from "../utils/enums/ToastType";
import { getEnvVariable, getUserId, joinStrings } from "../utils/util-methods";

const convertPackageParamToSubscriptionPlan = (packageParam) => {
  let plan = "";
  // eslint-disable-next-line eqeqeq
  if (packageParam == "1") {
    plan = BusinessSubscriptionPackage.BRONZE;
    // eslint-disable-next-line eqeqeq
  } else if (packageParam == "2") {
    plan = BusinessSubscriptionPackage.SILVER;
    // eslint-disable-next-line eqeqeq
  } else if (packageParam == "3") {
    plan = BusinessSubscriptionPackage.GOLD;
  }
  return plan;
};

export const Business = ({
  isPreview = false,
  name,
  email,
  phoneNumber,
  description,
  operatingTimes,
  categories,
  features,
  socialMedias,
  logo,
  cover,
  gallery,
  website,
  latitude,
  longitude,
  match,
}) => {
  //refs
  const payfastButtonRef = useRef(null);

  //state
  const [loadingBusiness, setLoadingBusiness] = useState(false);
  const [loadingReviews, setLoadingReviews] = useState(false);
  const [loadingCreateBusiness, setLoadingCreateBusiness] = useState(false);
  const [business, setBusiness] = useState(null);
  // const [settingsOpen, setSettingsOpen] = useState(false);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [pfData, setPfData] = useState({});
  const [pfSignature, setPfSignature] = useState("");
  const [createdBusiness, setCreatedBusiness] = useState({});

  //context
  const { addToast } = useContext(ToastContext);
  const { state, setActiveStep, setActiveContent } = useContext(StepperContext);
  const { isLoggedIn } = useContext(LoggedInContext);

  // const animationDuration = 0.1;

  //callbacks
  const getBusiness = useCallback(async () => {
    setLoadingBusiness(true);
    try {
      const {
        data: { business },
      } = await query1(`
        business(id: ${match?.params?.id}) {
          name
          email
          phoneNumber
          description
          latitude
          longitude
          operatingTimes {
            openingTime
            closingTime
            day
          }
          images {
            url
            type
          }
          categories {
            name
          }
          features {
            name
          }
          reviews {
            rating
            comment
            user {
              firstName
              lastName
              profilePictureUrl
            }
          }
          socialMedias {
            website
            platform
          }
        }`);
      setBusiness(business);
    } catch (err) {
      addToast(
        ToastType.Error,
        "Failed to load data. The server may be temporarily down. Please try again in a few minutes."
      );
    } finally {
      setLoadingBusiness(false);
    }
  }, [match?.params?.id, addToast]);

  const refreshReview = useCallback(
    async (business) => {
      setLoadingReviews(true);
      try {
        const { data } = await query1(`
        reviews(paginate: {take: 100, skip: 0}, find: {businessId: ${match?.params?.id}}) {
          rating
          comment
          user {
            firstName
            lastName
            profilePictureUrl
          }
        }
        `);
        setBusiness(mergeDeepRight(business, data));
      } catch (err) {
        addToast(
          ToastType.Error,
          "Failed to refresh reviews. The server may be temporarily down. Please try again in a few minutes."
        );
      } finally {
        setLoadingReviews(false);
      }
    },
    [addToast, match?.params?.id]
  );

  const createBusinessBeforeCheckout = useCallback(async () => {
    if (isLoggedIn && !!state?.formValues) {
      setLoadingCreateBusiness(true);
      try {
        const businessRes = await query1(
          `businessNoFilter(name: "${
            state.formValues.businessName
          }", userId: ${getUserId()}) {
            id
          }`
        );

        if (!isNil(businessRes.data.businessNoFilter)) {
          addToast(
            ToastType.Error,
            "This business already has an active subscription"
          );
          return;
        }

        const {
          data: { createBusiness },
        } = await createBusinessFromService(state.formValues, getUserId());
        setCreatedBusiness(createBusiness);
      } catch (err) {
        console.error(err);
        addToast(ToastType.Error, ToastMessage.FAILURE);
      } finally {
        setLoadingCreateBusiness(false);
      }
    } else {
      addToast(
        ToastType.Warn,
        "You must log in to checkout your business listing"
      );
    }
  }, [isLoggedIn, addToast, state?.formValues]);

  useEffect(() => {
    if (!isPreview) {
      getBusiness();
    }
  }, [getBusiness, isPreview]);

  useEffect(() => {
    if (!!state?.subscriptionPlan && !isEmpty(createdBusiness) && isLoggedIn) {
      const convertedPlan = convertPackageParamToSubscriptionPlan(
        state.subscriptionPlan
      );
      (async () => {
        try {
          const {
            data: { profile },
          } = await query1(`profile(id: ${getUserId()}) {
            firstName
            lastName
            email
          }`);
          setPfData({
            merchant_id: getEnvVariable("PAYFAST_MERCHANT_ID"),
            merchant_key: getEnvVariable("PAYFAST_MERCHANT_KEY"),
            return_url: "https://syncvine.co.za/payment-complete",
            cancel_url: "https://syncvine.co.za/payment-cancelled",
            notify_url: `${getEnvVariable("API_URL")}/pfHook`,
            name_first: profile.firstName,
            name_last: profile.lastName,
            email_address: profile.email,
            amount: getPayfastAmount(convertedPlan),
            item_name: "Business Listing",
            item_description: "Syncvine Business Listing",
            custom_int1: createdBusiness.id, //businessId
            custom_int2: `${getUserId()}`, //userId
            custom_str1: convertedPlan,
            subscription_type: "1", //required for subscriptions
            billing_date: getNextBillingDate(convertedPlan),
            recurring_amount: getPayfastAmount(convertedPlan),
            frequency: getSubscriptionFrequency(convertedPlan),
            cycles: "0", //indefinite subscription
          });
        } catch (err) {
          addToast(ToastType.Error, ToastMessage.FAILURE);
        }
      })();
    }
  }, [state?.subscriptionPlan, createdBusiness, isLoggedIn, addToast]);

  useEffect(() => {
    if (!isEmpty(pfData)) {
      // console.log("pfData: ", pfData);
      setPfSignature(
        generateSignature(pfData, getEnvVariable("PAYFAST_PASSPHRASE"))
      );
    }
  }, [pfData]);

  useEffect(() => {
    if (!!pfSignature && isPreview) {
      payfastButtonRef.current.click();
    }
  }, [pfSignature, isPreview]);

  // useEffect(() => {
  //   console.log("state: ", state);
  // }, [state]);

  return (
    <Fragment>
      <form
        action={getEnvVariable("PAYFAST_DOMAIN")}
        method="post"
        className="hidden"
      >
        {map(
          (key) => (
            <input
              key={key}
              name={key}
              type="hidden"
              value={`${path([key], pfData)?.trim()}`}
            />
          ),
          keys(defaultTo({}, pfData))
        )}
        <input
          key={"signature"}
          name={"signature"}
          type="hidden"
          value={`${pfSignature?.trim()}`}
        />
        <button id="payfastSubmit" type="submit" ref={payfastButtonRef}>
          Pay now
        </button>
      </form>
      {loadingBusiness && <LogoLoader className="h-132" />}
      {!loadingBusiness && (
        <>
          <ReviewModal
            open={isReviewModalOpen}
            setOpen={setIsReviewModalOpen}
            businessId={path(["params", "id"], match)}
            refreshReviews={async () => await refreshReview(business)}
          />
          <ShareModal
            open={isShareModalOpen}
            setOpen={setIsShareModalOpen}
            businessId={path(["params", "id"], match)}
          />
          <motion.div
            // animate={{ translateY: 0 }}
            // initial={{ translateY: 200 }}
            className={joinStrings("w-full pb-10", "md:py-10")}
          >
            <div
              className={joinStrings(
                "container mx-auto",
                isPreview && "px-0",
                !isPreview && "lg:px-4",
                !isPreview && "xl:px-20"
              )}
            >
              <div
                className={joinStrings(
                  "w-full overflow-hidden relative",
                  "md:rounded-t-lg"
                )}
              >
                {isPreview && (
                  <img
                    src={cover}
                    alt="banner"
                    className={joinStrings("h-140 w-full object-contain")}
                  />
                )}
                {!isPreview && (
                  <img
                    src={
                      find(
                        propEq("type", BusinessImageType.Cover),
                        defaultTo([], business?.images)
                      )?.url
                    }
                    alt="banner"
                    className={joinStrings("h-140 w-full object-contain")}
                  />
                )}
                <div className="bg-black w-full opacity-50 absolute inset-0"></div>
              </div>

              <div
                className={joinStrings(
                  "flex flex-col space-y-5 items-center",
                  "lg:mx-10 lg:items-start"
                )}
              >
                <div
                  className={joinStrings(
                    "z-10 max-w-xl h-64 -mt-136",
                    "lg:-mt-108",
                    "xl:-mt-100"
                  )}
                >
                  {isPreview && (
                    <img
                      src={logo}
                      alt="logo"
                      className="rounded w-auto h-full"
                    />
                  )}
                  {!isPreview && (
                    <img
                      src={
                        find(
                          propEq("type", BusinessImageType.Logo),
                          defaultTo([], business?.images)
                        )?.url
                      }
                      alt="logo"
                      className="rounded h-full"
                    />
                  )}
                </div>
                <div
                  className={joinStrings(
                    "flex flex-col z-10 items-center space-y-3 w-full",
                    "lg:flex-row lg:space-y-0"
                  )}
                >
                  <h2
                    className={joinStrings(
                      "font-semibold text-white break-words inline-block mr-3 text-center",
                      "lg:text-left"
                    )}
                  >
                    {isPreview && name}
                    {!isPreview && business?.name}
                  </h2>
                  <div className="flex space-x-3 mr-4">
                    {isPreview && (
                      <>
                        {socialMedias
                          .filter((x) => x?.isToggled)
                          .map(({ platform, website }) => (
                            <motion.a
                              whileHover={{ scale: 1.3 }}
                              className="p-2 rounded-full bg-cyan-600 cursor-pointer"
                              href={website}
                              target="_"
                            >
                              {platform === SocialMediaPlatform.Facebook && (
                                <FaFacebookF className="text-cyan-100" />
                              )}
                              {platform === SocialMediaPlatform.Twitter && (
                                <FaTwitter className="text-cyan-100" />
                              )}
                              {platform === SocialMediaPlatform.Instagram && (
                                <GrInstagram className="text-cyan-100" />
                              )}
                              {platform === SocialMediaPlatform.Pinterest && (
                                <GrPinterest className="text-cyan-100" />
                              )}
                              {platform === SocialMediaPlatform.Youtube && (
                                <GrYoutube className="text-cyan-100" />
                              )}
                            </motion.a>
                          ))}
                        {!!website && (
                          <motion.a
                            whileHover={{ scale: 1.3 }}
                            className="p-2 rounded-full bg-cyan-600 cursor-pointer"
                            href={website}
                            target="_"
                          >
                            <FiGlobe className="text-cyan-100" />
                          </motion.a>
                        )}
                      </>
                    )}

                    {!isPreview &&
                      business?.socialMedias.map(({ platform, website }) => (
                        <motion.a
                          whileHover={{ scale: 1.3 }}
                          className="p-2 rounded-full bg-cyan-600 cursor-pointer"
                          href={website}
                          target="_"
                        >
                          {platform === SocialMediaPlatform.Facebook && (
                            <FaFacebookF className="text-cyan-100" />
                          )}
                          {platform === SocialMediaPlatform.Twitter && (
                            <FaTwitter className="text-cyan-100" />
                          )}
                          {platform === SocialMediaPlatform.Instagram && (
                            <GrInstagram className="text-cyan-100" />
                          )}
                          {platform === SocialMediaPlatform.Pinterest && (
                            <GrPinterest className="text-cyan-100" />
                          )}
                          {platform === SocialMediaPlatform.Youtube && (
                            <GrYoutube className="text-cyan-100" />
                          )}
                        </motion.a>
                      ))}
                  </div>

                  {/* Options */}
                  <div
                    className={joinStrings(
                      "flex flex-wrap justify-center",
                      "lg:ml-auto"
                    )}
                  >
                    <button
                      onClick={() => {
                        if (!isPreview) {
                          setIsShareModalOpen(true);
                        }
                      }}
                      type="button"
                      className="btn flex justify-center items-center space-x-3 px-3 py-1.5 bg-white text-cyan-700 rounded-lg mr-3 mt-3"
                    >
                      <BiShareAlt className="text-2xl" />
                      <span>Share</span>
                    </button>
                    <button
                      onClick={() => {
                        if (!isPreview) {
                          if (isLoggedIn) {
                            setIsReviewModalOpen(true);
                          } else {
                            addToast(
                              ToastType.Warn,
                              "You must log in to leave a review"
                            );
                          }
                        }
                      }}
                      type="button"
                      className="btn flex justify-center items-center space-x-3 px-3 py-1.5 bg-white text-cyan-700 rounded-lg mr-3 mt-3"
                    >
                      <MdRateReview className="text-2xl" />
                      <span>Review</span>
                    </button>
                    {!isPreview && (
                      <a
                        className="btn flex justify-center items-center space-x-3 px-3 py-1.5 bg-white text-cyan-700 rounded-lg mt-3"
                        href={`http://maps.google.com/?q=${business?.latitude},${business?.longitude}`}
                        target="_"
                      >
                        <FaDirections className="text-2xl" />
                        <span>Directions</span>
                      </a>
                    )}
                    {isPreview && (
                      <button
                        type="button"
                        className="btn flex justify-center items-center space-x-3 px-3 py-1.5 bg-white text-cyan-700 rounded-lg mt-3"
                      >
                        <FaDirections className="text-2xl" />
                        <span>Directions</span>
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex flex-col lg:flex-row z-10 relative -mt-10 lg:space-x-5 lg:space-y-0 space-y-5 mx-5">
                <div className="p-10 rounded-lg shadow bg-white flex-1">
                  <div className="flex flex-col space-y-10">
                    <div
                      className={joinStrings(
                        "flex flex-col items-center",
                        "md:items-start"
                      )}
                    >
                      <h5 className="font-semibold text-cyan-900">Email</h5>

                      <div className="pt-0.5 bg-gray-200 w-full mt-1"></div>

                      <div
                        className={joinStrings(
                          "flex space-x-2 items-center break-all bg-gray-100 text-gray-600 rounded pl-5 pr-10 py-2 w-full mt-3",
                          "md:mr-auto"
                        )}
                      >
                        <MdEmail
                          className={joinStrings(
                            "text-gray-600 text-2xl",
                            "md:text-xl"
                          )}
                        />
                        {isPreview && <p>{email}</p>}
                        {!isPreview && <p>{business?.email}</p>}
                      </div>
                    </div>

                    <div
                      className={joinStrings(
                        "flex flex-col items-center",
                        "md:items-start"
                      )}
                    >
                      <h5 className="font-semibold text-cyan-900">Phone</h5>

                      <div className="h-0.5 bg-gray-200 w-full mt-1"></div>

                      <div
                        className={joinStrings(
                          "flex space-x-2 items-center break-all bg-gray-100 text-gray-600 rounded pl-5 pr-10 py-2 w-full mt-3",
                          "md:mr-auto"
                        )}
                      >
                        <FaPhone />
                        {isPreview && <p>{phoneNumber}</p>}
                        {!isPreview && <p>{business?.phoneNumber}</p>}
                      </div>
                    </div>

                    <div
                      className={joinStrings(
                        "flex flex-col items-center",
                        "md:items-start"
                      )}
                    >
                      <h5 className="font-semibold text-cyan-900">
                        Categories
                      </h5>

                      <div className="pt-0.5 bg-gray-200 w-full mt-1"></div>

                      <div
                        className={joinStrings(
                          "flex flex-wrap items-baseline mt-3"
                        )}
                      >
                        {isPreview &&
                          categories?.map(({ name }, i) => (
                            <Fragment>
                              <span
                                key={`business category ${i}`}
                                className="px-5 py-1 bg-gray-100 rounded-full text-sm justify-start mb-2 text-gray-600 mr-3"
                              >
                                {name}
                              </span>
                              {/* <span className="p-1 bg-rose-500 rounded-full mx-2"></span> */}
                            </Fragment>
                          ))}
                        {!isPreview &&
                          business?.categories?.map(({ name }, i) => (
                            <Fragment>
                              <span
                                key={`business category ${i}`}
                                className="px-5 py-1 bg-gray-100 rounded-full text-sm justify-start mb-2 text-gray-600 mr-3"
                              >
                                {name}
                              </span>
                              {/* <span className="p-1 bg-rose-500 rounded-full mx-2"></span> */}
                            </Fragment>
                          ))}
                      </div>
                    </div>

                    <div
                      className={joinStrings(
                        "flex flex-col items-center",
                        "md:items-start"
                      )}
                    >
                      <h5 className="font-semibold text-cyan-900">Features</h5>

                      <div className="pt-0.5 bg-gray-200 w-full mt-1"></div>

                      <div
                        className={joinStrings(
                          "flex flex-wrap items-baseline mt-3"
                        )}
                      >
                        {isPreview &&
                          !!features?.length &&
                          features?.map(({ name }, i) => (
                            <Fragment>
                              <span className="px-5 py-1 bg-gray-100 rounded-full text-sm justify-start mb-2 text-gray-600 mr-3">
                                {name}
                              </span>
                              {/* <span className="p-1 bg-rose-600 rounded-full mx-2"></span> */}
                            </Fragment>
                          ))}
                        {isPreview && !features?.length && <p>None</p>}
                        {!isPreview &&
                          !!business?.features?.length &&
                          business?.features?.map(({ name }, i) => (
                            <Fragment>
                              <span className="px-5 py-1 bg-gray-100 rounded-full text-sm justify-start mb-2 text-gray-600 mr-3">
                                {name}
                              </span>
                              {/* <span className="p-1 bg-rose-600 rounded-full mx-2"></span> */}
                            </Fragment>
                          ))}
                        {!isPreview && !business?.features?.length && (
                          <p>None</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-10 bg-white rounded-lg shadow lg:h-auto h-96 lg:flex-1">
                  <iframe
                    src={`https://maps.google.com/maps/embed/v1/place?&key=AIzaSyAjkPmCv1yHDwML-K63seaAUcMDQej9aPY&q=${
                      isPreview ? latitude : business?.latitude ?? ""
                    },${isPreview ? longitude : business?.longitude ?? ""}`}
                    className="h-full w-full"
                    title="map"
                  ></iframe>
                </div>
              </div>

              <div
                className={joinStrings(
                  "flex flex-col mx-5 mt-10 space-y-5 shadow rounded-lg bg-white",
                  "lg:flex-row lg:space-y-0"
                )}
              >
                <div
                  className={joinStrings("w-full h-128 max-h-128", "md:h-96")}
                >
                  <div className={joinStrings("p-10 h-full flex-col flex")}>
                    <div
                      className={joinStrings(
                        "flex justify-center space-x-2 items-center mb-5",
                        "lg:justify-start"
                      )}
                    >
                      <MdWeb className="text-cyan-500 text-2xl" />
                      <h4 className="">Description</h4>
                    </div>

                    <div className="pt-0.5 bg-gray-200"></div>

                    <div className="flex justify-center items-center h-full w-full overflow-y-auto mt-1">
                      <pre
                        className={joinStrings(
                          "text-justify w-full flex-grow text-gray-600 overflow-x-auto whitespace-pre-wrap break-words max-h-full"
                        )}
                      >
                        {isPreview && description}
                        {!isPreview && business?.description}
                      </pre>
                    </div>
                  </div>
                </div>

                <div className={joinStrings("w-1/3 hidden", "lg:flex")}>
                  <div className="h-full flex p-5">
                    <UndrawDescription className="w-full h-auto my-auto" />
                  </div>
                </div>
              </div>

              <div
                className={joinStrings(
                  "flex flex-col mt-10 mx-5 space-y-5",
                  "lg:flex-row lg:space-x-5 lg:space-y-0"
                )}
              >
                <div
                  className={joinStrings(
                    "p-10 bg-white shadow rounded-lg w-full",
                    "lg:w-1/3"
                  )}
                >
                  <div className="flex items-center justify-center space-x-2 h-8">
                    <BiTimeFive className="text-cyan-500 text-2xl" />
                    <h4>Trading Hours</h4>
                  </div>

                  <div className="pt-0.5 bg-gray-200 mt-5"></div>

                  <div className="flex flex-col mt-5 justify-between h-128">
                    {isPreview &&
                      Object.keys(DayOfWeek).map((key, i) => {
                        const operatingHours = operatingTimes?.filter(
                          ({ day }) => day === DayOfWeek[key]
                        );
                        return (
                          <Fragment>
                            <div
                              className="flex justify-between py-2 px-3 bg-gray-50 rounded-lg text-gray-600"
                              key={"time " + i}
                            >
                              <div>{DayOfWeek[key]}</div>
                              <div className="flex flex-col space-y-1">
                                {!!operatingHours?.length &&
                                  operatingHours?.map(
                                    ({ openingTime, closingTime }) => {
                                      return (
                                        <div className="flex space-x-2">
                                          <p>{openingTime}</p>
                                          <span>-</span>
                                          <p>{closingTime}</p>
                                        </div>
                                      );
                                    }
                                  )}

                                {!operatingHours?.length && <span>Closed</span>}
                              </div>
                            </div>
                          </Fragment>
                        );
                      })}
                    {!isPreview &&
                      Object.keys(DayOfWeek).map((key, i) => {
                        const operatingHours = business?.operatingTimes?.filter(
                          ({ day }) => day === DayOfWeek[key]
                        );
                        return (
                          <Fragment key={`day-of-the-week-business-view-${i}`}>
                            <div
                              className="flex justify-between py-2 px-3 bg-gray-50 rounded-lg text-gray-600"
                              key={"time " + i}
                            >
                              <div>{DayOfWeek[key]}</div>
                              <div className="flex flex-col space-y-1">
                                {!!operatingHours?.length &&
                                  operatingHours?.map(
                                    ({ openingTime, closingTime }, index) => {
                                      return (
                                        <div
                                          className="flex space-x-2"
                                          key={`operating-time-${index}-${i}`}
                                        >
                                          <p>{openingTime}</p>
                                          <span>-</span>
                                          <p>{closingTime}</p>
                                        </div>
                                      );
                                    }
                                  )}

                                {!operatingHours?.length && <span>Closed</span>}
                              </div>
                            </div>
                          </Fragment>
                        );
                      })}
                  </div>

                  {/* <div className="flex lg:space-x-28 space-x-10 justify-center">
              <div className="flex mt-5 flex-col items-start space-y-8 text-lg bg-contain bg-repeat-space"></div>

              <div className="flex mt-5 flex-col items-center space-y-8 text-lg bg-contain bg-repeat-space">
                {daysOfTheWeek.map((x, i) => {
                  return;
                })}
              </div>
            </div> */}
                </div>

                <div className="p-10 bg-white shadow rounded-lg flex-1 w-full">
                  <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 justify-between h-auto lg:h-8">
                    <div className="flex space-x-2 justify-center items-center">
                      <RiGalleryFill className="text-cyan-500 text-2xl" />
                      <h4>Gallery</h4>
                    </div>
                  </div>

                  <div className="pt-0.5 bg-gray-200 mt-5"></div>

                  {/* <SRLWrapper> */}
                  <div
                    className={joinStrings(
                      "h-96 flex justify-center items-center mt-5 w-full",
                      "lg:h-128"
                    )}
                  >
                    {isPreview && <RegisterCarousel images={gallery} />}
                    {!isPreview && (
                      <RegisterCarousel
                        images={pipe(
                          defaultTo([]),
                          filter(propEq("type", BusinessImageType.Gallery)),
                          map(prop("url"))
                        )(business?.images)}
                      />
                    )}
                  </div>
                </div>
              </div>

              {/* Reviews */}
              {loadingReviews && <LogoLoader />}
              {!loadingReviews && (
                <div className="p-10 bg-white shadow rounded-lg mt-10 mx-5">
                  <div
                    className={joinStrings(
                      "flex justify-center items-center space-x-3",
                      "lg:justify-start"
                    )}
                  >
                    <FaPenSquare className="text-2xl rounded-lg text-cyan-500" />
                    <h4>Reviews</h4>
                  </div>

                  <div className="pt-0.5 bg-gray-200 mt-5"></div>

                  <div
                    className={joinStrings(
                      "flex flex-col space-y-10 py-10 px-0",
                      "lg:px-10"
                    )}
                  >
                    {!business?.reviews?.length && (
                      <p className="text-center">No reviews yet</p>
                    )}
                    {!!business?.reviews?.length && (
                      <Fragment>
                        {business?.reviews?.map(
                          (
                            { comment, rating, user: { firstName, lastName } },
                            i
                          ) => (
                            <Review
                              key={`review-${i}-${firstName}-${lastName}`}
                              comment={comment}
                              firstName={firstName}
                              lastName={lastName}
                              rating={rating}
                            />
                          )
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
              )}

              {/* {!isPreview && (
                <div
                  className={joinStrings(
                    "mx-auto bg-cyan-900 text-white w-10 h-10 rounded-full sticky bottom-5 cursor-pointer z-20"
                  )}
                >
                  <AnimatePresence>
                    {settingsOpen && (
                      <Fragment>
                        <motion.button
                          animate={{
                            translateY: -30,
                            translateX: -65,
                            opacity: 1,
                            transition: {
                              duration: animationDuration,
                              type: "spring",
                              mass: 1,
                            },
                          }}
                          initial={{ translateY: 0, translateX: 0, opacity: 0 }}
                          exit={{
                            translateY: 0,
                            translateX: 0,
                            opacity: 0,
                            transition: { duration: animationDuration },
                          }}
                          className={joinStrings(
                            "rounded-full bg-cyan-900 flex justify-center items-center absolute bottom-0 overflow-hidden p-2",
                            "focus:outline-none"
                          )}
                        >
                          <FaDirections className="text-white text-2xl" />
                        </motion.button>
                        <motion.button
                          animate={{
                            translateY: -75,
                            opacity: 1,
                            transition: {
                              duration: animationDuration,
                              delay: animationDuration,
                              type: "spring",
                              mass: 1,
                            },
                          }}
                          initial={{ translateY: 0, translateX: 0, opacity: 0 }}
                          exit={{
                            translateY: 0,
                            translateX: 0,
                            opacity: 0,
                            transition: {
                              duration: animationDuration,
                              delay: animationDuration,
                            },
                          }}
                          className={joinStrings(
                            "rounded-full bg-cyan-900 flex justify-center items-center absolute bottom-0 overflow-hidden p-2",
                            "focus:outline-none"
                          )}
                          onClick={() => setIsShareModalOpen(true)}
                        >
                          <BiShareAlt className="text-white text-2xl" />
                        </motion.button>
                        <motion.button
                          animate={{
                            translateY: -30,
                            translateX: 65,
                            opacity: 1,
                            transition: {
                              duration: 0.1,
                              delay: 0.2,
                              type: "spring",
                              mass: 1,
                            },
                          }}
                          initial={{ translateY: 0, translateX: 0, opacity: 0 }}
                          exit={{
                            translateY: 0,
                            translateX: 0,
                            opacity: 0,
                            transition: {
                              duration: animationDuration,
                              delay: animationDuration * 2,
                            },
                          }}
                          className={joinStrings(
                            "rounded-full bg-cyan-900 flex justify-center items-center absolute bottom-0 overflow-hidden p-2",
                            "focus:outline-none"
                          )}
                          onClick={() => setIsReviewModalOpen(true)}
                        >
                          <MdRateReview className="text-white text-2xl" />
                        </motion.button>
                      </Fragment>
                    )}
                  </AnimatePresence>
                  <button
                    type="button"
                    className="btn flex justify-center items-center w-full h-full mt-10"
                    onClick={() => setSettingsOpen(!settingsOpen)}
                  >
                    <FiSettings className="text-2xl" />
                  </button>
                </div>
              )} */}

              {isPreview && (
                <div className="flex space-x-3 w-full justify-end mt-10">
                  <SecondaryButton
                    onClick={() => {
                      setActiveStep((prev) => dec(prev));
                      setActiveContent(null);
                    }}
                  >
                    Go Back
                  </SecondaryButton>
                  <PrimaryButton
                    onClick={createBusinessBeforeCheckout}
                    className={joinStrings("space-x-3")}
                    loading={loadingCreateBusiness}
                    disabled={loadingCreateBusiness}
                  >
                    <span>Proceed to payfast</span>
                    <AiFillCreditCard className="text-2xl" />
                  </PrimaryButton>
                </div>
              )}
            </div>
          </motion.div>
        </>
      )}
    </Fragment>
  );
};
