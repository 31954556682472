import { useEffect, useState } from "react";

export const useGoogleLogin = () => {
  const [loadedGoogleApi, setLoadedGoogleApi] = useState(false);

  useEffect(() => {
    window.gapi.load("auth2", () => {
      const googleAuth = window.gapi.auth2.getAuthInstance();
      if (!googleAuth) {
        try {
          window.gapi.auth2.init({
            client_id:
              "775975596037-f5chr77849b4lmta8kk0og1qt7e87n9h.apps.googleusercontent.com",
            // cookiepolicy: "single_host_origin",
            ux_mode: "popup", //redirect, popup
            // fetchBasicProfile: true,
            // access_type: 'online',
            redirect_uri: "http://localhost:3002",
          });
        } catch (err) {
          console.error(err);
        } finally {
          setLoadedGoogleApi(true);
        }
      } else {
        setLoadedGoogleApi(true);
      }
    });
  }, []);

  return loadedGoogleApi;
};
