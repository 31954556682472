import { useFormik } from "formik";
import { isEmpty, mergeRight, not, replace } from "ramda";
import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { LoggedInContext } from "../contexts/LoggedInContext";
import { ToastContext } from "../contexts/ToastContext";
import { mutation1, query1 } from "../services/graphqlService";
import { ToastMessage } from "../utils/enums/ToastMessage";
import { ToastType } from "../utils/enums/ToastType";
import { getUserId, joinStrings } from "../utils/util-methods";
import { CloseButton } from "./CloseButton";
import { LogoLoader } from "./LogoLoader";
import { Modal } from "./Modal";
import { PrimaryButton } from "./PrimaryButton";
import { ShadowTextArea } from "./ShadowTextArea";
import { StarRating } from "./StarRating";

export const ReviewModal = ({ open, setOpen, businessId, refreshReviews }) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useContext(ToastContext);
  const { isLoggedIn } = useContext(LoggedInContext);

  const [loadingPrevReview, setloadingPrevReview] = useState(false);
  const [prevReview, setPrevReview] = useState({
    id: 0,
    rating: 0,
    comment: "",
  });

  useEffect(() => {
    if (open) {
      (async () => {
        setloadingPrevReview(true);
        try {
          const {
            data: { reviews },
          } = await query1(
            `reviews(paginate:{take: 1, skip: 0}, find: {businessId: ${businessId}, userId: ${getUserId()}}) {
              id
              rating
              comment
            }`
          );

          if (not(isEmpty(reviews))) {
            setPrevReview((prev) => mergeRight(prev, reviews[0]));
          }
        } catch (err) {
          addToast(ToastType.Error, ToastMessage.FAILURE);
        } finally {
          setloadingPrevReview(false);
        }
      })();
    }
  }, [businessId, addToast, open]);

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    resetForm,
    setValues,
    handleSubmit,
  } = useFormik({
    initialValues: {
      rating: 1,
      comment: "",
    },
    validationSchema: yup.object({
      rating: yup.number().required("A rating is required.").min(1).max(5),
      comment: yup
        .string()
        .required("A comment is required")
        .max(255, "A comment can only contain 255 characters"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (isLoggedIn) {
          if (!!prevReview.id) {
            await mutation1(`
                  updateReview(reviewId: ${prevReview.id}, data: {rating: ${
              values.rating
            }, comment: """${replace(/"/g, "'", values.comment)}"""}) {
                    id
                  }`);
            addToast(ToastType.Success, "Your review has been updated");
          } else {
            await mutation1(`
                  createReview(data: {rating: ${values.rating}, comment: "${
              values.comment
            }", businessId: ${businessId}, userId: ${getUserId()} }) {
                    id
                  }`);
            addToast(ToastType.Success, "Your review has been added");
          }

          resetForm();
          setOpen(false);
          refreshReviews();
        } else {
          addToast(ToastType.Warn, "You must log in to leave a review.");
        }
        setLoading(false);
      } catch (err) {
        addToast(ToastType.Error, ToastMessage.FAILURE);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (!!prevReview.id) {
      setValues((prev) => mergeRight(prev, prevReview));
    }
  }, [prevReview, setValues]);

  const handleRatingChange = (ratingValue) =>
    setFieldValue("rating", ratingValue);

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className={joinStrings("bg-white rounded-lg max-h-128", "sm:w-96")}>
        {loadingPrevReview && <LogoLoader className="h-full" />}
        {!loadingPrevReview && (
          <>
            <div className="flex justify-center items-center h-20 border-b border-gray-200">
              {!prevReview.id && (
                <h3 className="text-cyan-900">Write a review</h3>
              )}
              {!!prevReview.id && (
                <h3 className="text-cyan-900">Update your review</h3>
              )}
            </div>
            <form
              className="flex flex-col space-y-3 px-10 mt-5"
              // onSubmit={reviewForm.handleSubmit}
            >
              <StarRating
                label={"Rating"}
                rating={values.rating}
                setRating={handleRatingChange}
              />
              <ShadowTextArea
                placeHolder="Comment"
                rows="5"
                resizable={false}
                id="reviewComment"
                name="comment"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.comment}
                value={values.comment}
                touched={touched.comment}
              />
            </form>
            <div className="flex justify-end items-center h-20 px-10 space-x-3 border-t border-gray-200 mt-5">
              <CloseButton onClick={() => setOpen(false)}>Close</CloseButton>
              <PrimaryButton
                onClick={handleSubmit}
                loading={loading}
                disabled={loading}
              >
                Submit
              </PrimaryButton>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
