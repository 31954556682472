import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { joinStrings } from "../utils/util-methods";

export const SecondaryButton = ({
  onClick,
  children,
  type = "button",
  className,
  as = "button",
  to = "/",
}) => {
  return (
    <Fragment>
      {as === "button" && (
        <button
          className={joinStrings(
            "btn bg-white border border-cyan-900 text-cyan-900 flex space-x-1 items-center px-7 py-2.5 rounded max-h-14",
            "transition-colors duration-300",
            "hover:bg-cyan-900 hover:text-white",
            className
          )}
          onClick={onClick}
          type={type}
        >
          {children}
        </button>
      )}

      {as === "link" && (
        <Link
          className={joinStrings(
            "btn bg-white border border-cyan-900 text-cyan-900 flex space-x-1 items-center px-7 py-2.5 rounded max-h-14",
            "transition-colors duration-300",
            "hover:bg-cyan-900 hover:text-white",
            className
          )}
          to={to}
          // onClick={onClick}
        >
          {children}
        </Link>
      )}
    </Fragment>
  );
};
