import { getIn } from "formik";
import { debounce } from "lodash";

const google = window.google;

export const handleGoogleMapSearchChange = debounce(
  async ({ setPlaces, placesService, placeSearch, setLoadingGooglePlaces }) => {
    setLoadingGooglePlaces(true);
    try {
      const request = {
        query: placeSearch,
        fields: ["formatted_address", "geometry", "name"],
      };

      await new Promise((resolve, reject) => {
        if (placeSearch.length > 5) {
          placesService?.findPlaceFromQuery(request, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              const formattedResults = results.map((x, i) => ({
                key: `google-map-search-result-${i}`,
                text: x.formatted_address,
                value: x,
              }));
              setPlaces(formattedResults);
              resolve();
            } else if (
              status ===
                google.maps.places.PlacesServiceStatus.INVALID_REQUEST ||
              status === google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR ||
              status ===
                google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT ||
              status === google.maps.places.PlacesServiceStatus.REQUEST_DENIED
            ) {
              reject("Something went wrong");
            } else {
              resolve();
            }
          });
        } else {
          resolve();
        }
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingGooglePlaces(false);
    }
  },
  1000
);

export const handlePlaceSelect = ({
  map,
  markers,
  setFieldValue,
  setFieldTouched,
}) => async (place) => {
  markers[markers.length - 1]?.setMap(null);
  const { location } = place.geometry;
  map.setCenter(location);
  markers.push(
    new window.google.maps.Marker({
      position: location,
      title: `Address: ${
        place.formatted_address
      } \r\nLatitude: ${location.lat()}; Longitude: ${location.lng()}`,
      map,
    })
  );

  await setFieldValue("latitude", location.lat(), false);
  await setFieldTouched("latitude", true, false);
  await setFieldValue("longitude", location.lng(), false);
  await setFieldTouched("longitude", true, true);
};

export const placeMarkerAndPanTo = async ({
  latlng,
  map,
  markers,
  setFieldValue,
  setFieldTouched,
}) => {
  markers[markers.length - 1]?.setMap(null);
  markers.push(
    new google.maps.Marker({
      position: latlng,
      title: `Latitude: ${latlng.lat()}; Longitude: ${latlng.lng()}`,
      map: map,
    })
  );
  map.panTo(latlng);
  await setFieldValue("latitude", latlng.lat(), false);
  await setFieldTouched("latitude", true, false);
  await setFieldValue("longitude", latlng.lng(), false);
  await setFieldTouched("longitude", true, true);
};

export const isAddOperatingHourDisabled = ({
  form,
  dayIndex,
  idOfHighestOperatingHour,
}) => {
  return (
    // !getIn(
    //   form.touched,
    //   `daysOfTheWeek[${dayIndex}].operatingTimes[${idOfHighestOperatingHour}].openingTime`
    // ) ||
    // !getIn(
    //   form.touched,
    //   `daysOfTheWeek[${dayIndex}].operatingTimes[${idOfHighestOperatingHour}].closingTime`
    // ) ||
    !!getIn(
      form.errors,
      `daysOfTheWeek[${dayIndex}].operatingTimes[${idOfHighestOperatingHour}].openingTime`
    ) ||
    !!getIn(
      form.errors,
      `daysOfTheWeek[${dayIndex}].operatingTimes[${idOfHighestOperatingHour}].closingTime`
    ) ||
    !getIn(form.values, `daysOfTheWeek[${dayIndex}].isToggled`) ||
    !getIn(
      form.values,
      `daysOfTheWeek[${dayIndex}].operatingTimes[${idOfHighestOperatingHour}].openingTime`
    ) ||
    !getIn(
      form.values,
      `daysOfTheWeek[${dayIndex}].operatingTimes[${idOfHighestOperatingHour}].closingTime`
    )
  );
};
