import { AnimatePresence, motion } from "framer-motion";
import { indexOf, pluck, remove } from "ramda";
import React, { useContext } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { ToastContext } from "../contexts/ToastContext";
import { ToastType } from "../utils/enums/ToastType";
import { joinStrings } from "../utils/util-methods";

export const ToastContainer = () => {
  const { toasts, setToasts } = useContext(ToastContext);
  return (
    <div
      className={joinStrings(
        "flex items-center fixed bottom-10 z-70 w-screen px-2",
        "sm:right-10 sm:w-96"
      )}
    >
      <div className="flex flex-col space-y-6 w-full break-all text-white">
        <AnimatePresence>
          {toasts.map(({ id, text, type }) => (
            <motion.div
              key={id}
              animate={{ opacity: 1, translateX: 0, scale: 1 }}
              initial={{
                opacity: 0,
                // translateX: -20,
                scale: 1.3,
              }}
              exit={{
                opacity: 0,
                // translateX: -20,
                scale: 0.3,
              }}
              className={joinStrings(
                "flex space-x-3 items-center px-3 py-4 shadow rounded-md relative bg-gray-800 border",
                type === ToastType.Success && "text-green-400 border-green-400",
                type === ToastType.Info && "text-blue-300 border-blue-300",
                type === ToastType.Warn && "text-yellow-300 border-yellow-300",
                type === ToastType.Error && "text-red-500 border-red-500"
                // type === ToastType.Success && "bg-green-500",
                // type === ToastType.Info && "bg-blue-400",
                // type === ToastType.Warn && "bg-yellow-400",
                // type === ToastType.Error && "bg-red-600"
              )}
            >
              <div
                className="w-5 h-full cursor-pointer"
                onClick={() =>
                  setToasts(remove(indexOf(id, pluck("id")(toasts)), 1)(toasts))
                }
              >
                <AiOutlineClose className="text-2xl text-white" />
              </div>

              <span
                className={joinStrings(
                  "break-normal text-xs border-l-2 pl-3",
                  "sm:text-sm",
                  type === ToastType.Success && "border-green-400",
                  type === ToastType.Info && "border-blue-300",
                  type === ToastType.Warn && "border-yellow-300",
                  type === ToastType.Error && "border-red-600"
                )}
              >
                {text}
              </span>
              {/* <AiOutlineClose
                className="ml-auto text-2xl absolute -top-7 right-2 text-black cursor-pointer"
                onClick={() =>
                  setToasts(remove(indexOf(id, pluck("id")(toasts)), 1)(toasts))
                }
              /> */}
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
};
