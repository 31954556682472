import React from "react";
import { AiFillHome } from "react-icons/ai";
import { PrimaryButton } from "../components/PrimaryButton";
import { UndrawCreditCard } from "../components/Undraw/UndrawCreditCard";
import { joinStrings } from "../utils/util-methods";

export const PaymentComplete = ({ history }) => {
  return (
    <div className="my-20 max-w-3xl mx-auto">
      <div
        className={joinStrings(
          "flex flex-col space-y-10 justify-center items-center shadow-md py-20 px-10 bg-white rounded-lg",
          "transition-shadow duration-300",
          "hover:shadow-2xl"
        )}
      >
        <div className="flex flex-col items-center">
          <h2 className="text-cyan-900 text-center font-medium">
            Thank you for your purchase
          </h2>
          <p className="text-gray-400 mt-3">
            Your business listing will be up in the next few minutes once we've confirmed
            the payment.
          </p>
          <p className="text-gray-400">
            We'll send you an email when this happens... until then sit tight :)
          </p>
        </div>

        {/* <div
          className={joinStrings(
            "rounded-full bg-white w-40 h-40 flex justify-center items-center p-10",
            "md:h-52 md:w-52"
          )}
        > */}
        <UndrawCreditCard className="h-32 w-full" />
        {/* </div> */}
        <div className="flex flex-col space-y-5 px-10 pt-10 items-end">
          <PrimaryButton onClick={() => history.push("/")} className="space-x-2">
            <span>Return Home</span>
            <AiFillHome />
          </PrimaryButton>
          {/* <PrimaryButton onClick={() => history.push("/business/2")}>
            View your business
          </PrimaryButton> */}
        </div>
      </div>
    </div>
  );
};
