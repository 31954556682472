import React, { useContext, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { GoogleApiLoadContext } from "../App";
import { LoggedInContext } from "../contexts/LoggedInContext";
import { signInGoogle } from "../services/googleService";
import { joinStrings } from "../utils/util-methods";

export const GoogleLogin = () => {
  const [loadingGoogleLogIn, setLoadingGoogleLogIn] = useState(false);
  const { loadedGoogleApi } = useContext(GoogleApiLoadContext);
  const { login } = useContext(LoggedInContext);

  return (
    <button
      disabled={loadingGoogleLogIn}
      className={joinStrings(
        "py-2 w-full bg-blue-500 space-x-2 inline-flex items-center justify-center space-x-3 rounded",
        "focus:outline-none"
      )}
      onClick={() =>
        signInGoogle({
          loadedGoogleApi,
          setLoadingGoogleLogIn,
          login,
        })
      }
    >
      {/* <div className="p-1 bg-blue-900 rounded-full inline-flex items-center space-x-3"> */}
      <FcGoogle className="bg-blue-900 text-2xl rounded-full" />
      {/* </div> */}
      <span className="text-white">Sign in with google</span>
    </button>
  );
};
