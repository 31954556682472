import React, { useContext, useEffect, useState } from "react";
import { LogoLoader } from "../components/LogoLoader";
import RegisterBusiness from "../components/RegisterBusiness";
import { LoggedInContext } from "../contexts/LoggedInContext";
import { ToastContext } from "../contexts/ToastContext";
import { query1 } from "../services/graphqlService";
import { ToastType } from "../utils/enums/ToastType";

export const EditBusiness = ({ match, history }) => {
  const [business, setBusiness] = useState(null);
  const [loading, setLoading] = useState(true);
  const { addToast } = useContext(ToastContext);
  const { isLoggedIn } = useContext(LoggedInContext);

  useEffect(() => {
    if (!isLoggedIn) {
      history.push("/");
    }
  }, [isLoggedIn, history]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const businessId = match?.params?.businessId;
        const {
          data: { business },
        } = await query1(`
            business(id: ${businessId}) {
              id
              name,
              email,
              phoneNumber,
              website,
              description,
              address,
              latitude,
              longitude,
              categories {
                id
                name
              }
              features {
                id
                name
              }
              operatingTimes {
                id,
                day,
                openingTime,
                closingTime
              }
              socialMedias {
                platform,
                website
              }
              images {
                url,
                type
              }
            }`);

        setBusiness(business);
      } catch (err) {
        addToast(ToastType.Error, "Something went wrong");
      } finally {
        setLoading(false);
      }
    })();
  }, [match?.params?.businessId, addToast]);
  return (
    <div className="container mx-auto">
      {loading && <LogoLoader className="h-128 mt-20" />}
      {!loading && <RegisterBusiness business={business} />}
    </div>
  );
};
