import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { BiMailSend } from "react-icons/bi";
import { HiOutlineUserCircle } from "react-icons/hi";
import * as yup from "yup";
import { ToastContext } from "../contexts/ToastContext";
import { mutation1 } from "../services/graphqlService";
import { ToastType } from "../utils/enums/ToastType";
import { joinStrings } from "../utils/util-methods";
import { PrimaryButton } from "./PrimaryButton";
import { ShadowInput } from "./ShadowInput";
import { ShadowTextArea } from "./ShadowTextArea";
import { UndrawMail } from "./Undraw/UndrawMail";

export const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useContext(ToastContext);
  const contactForm = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      message: "",
    },
    validationSchema: yup.object({
      firstName: yup.string().required("A first name is required"),
      lastName: yup.string().required("A last name is required"),
      email: yup
        .string()
        .required("An email is required")
        .email("Must be a legitimate email"),
      message: yup
        .string()
        .required("A message is required")
        .max(255, "The message can not exceed 255 characters."),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await mutation1(
          `sendMail(data: { firstName: "${values.firstName}", lastName: "${
            values.lastName
          }", email: "${values.email}", message: """${values.message.replace(
            /"/g,
            "'"
          )}""" })`
        );
        addToast(
          ToastType.Success,
          "Mail has been sent. Thank you for getting in touch :)"
        );
        contactForm.resetForm();
      } catch (err) {
        addToast(
          ToastType.Error,
          "Action Failed. The server may be temporarily down, Please try again in a few minutes."
        );
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <div
      className={joinStrings(
        "flex flex-col space-y-10 shadow-lg rounded-lg p-10 bg-white w-full max-w-2xl",
        "transition-shadow duration-300",
        "hover:shadow-2xl"
      )}
    >
      <div className="flex flex-col space-y-6 items-center">
        <h2 className="font-bold text-cyan-900">Contact Us</h2>
        <UndrawMail className="h-24 w-full" />

        {/* <div className="h-0.5 bg-gray-200 w-full"></div> */}
      </div>

      <form
        className="flex flex-col space-y-6"
        onSubmit={contactForm.handleSubmit}
      >
        <ShadowInput
          id="contactFirstName"
          name="firstName"
          placeHolder="First Name"
          // label="First Name"
          onChange={contactForm.handleChange}
          onBlur={contactForm.handleBlur}
          error={contactForm.errors.firstName}
          value={contactForm.values.firstName}
          touched={contactForm.touched.firstName}
          icon={<HiOutlineUserCircle className="text-2xl text-gray-400" />}
        />
        <ShadowInput
          id="contactLastName"
          name="lastName"
          placeHolder="Last Name"
          // label="Last Name"
          onChange={contactForm.handleChange}
          onBlur={contactForm.handleBlur}
          error={contactForm.errors.lastName}
          value={contactForm.values.lastName}
          touched={contactForm.touched.lastName}
          icon={<HiOutlineUserCircle className="text-2xl text-gray-400" />}
        />
        <ShadowInput
          id="contactEmail"
          name="email"
          placeHolder="Email"
          // label="Email"
          onChange={contactForm.handleChange}
          onBlur={contactForm.handleBlur}
          error={contactForm.errors.email}
          value={contactForm.values.email}
          touched={contactForm.touched.email}
          type="email"
        />
        <ShadowTextArea
          id="contactMessage"
          name="message"
          placeHolder="Message"
          // label="Message"
          onChange={contactForm.handleChange}
          onBlur={contactForm.handleBlur}
          error={contactForm.errors.message}
          value={contactForm.values.message}
          touched={contactForm.touched.message}
          rows="6"
        />
        <PrimaryButton loading={loading} type="submit" className="space-x-2">
          <span>Send Mail</span>
          <BiMailSend className="text-xl" />
        </PrimaryButton>
      </form>
    </div>
  );
};
