import { isNil } from "ramda";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { joinStrings } from "../utils/util-methods";

export const RegisterSelect = ({
  name,
  options,
  className,
  text,
  onSelect,
  onBlur,
  error,
  touched,
  disabled = false,
  icons = [],
  textIcon = null,
  value,
  useChildren = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef(null);

  const clickListener = useCallback((e) => {
    if (!ref?.current?.contains(e.target)) {
      setIsFocused(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", clickListener);

    return () => {
      document.removeEventListener("click", clickListener);
    };
  }, [clickListener]);

  return (
    <div ref={ref} className={"relative inline-block text-left " + className}>
      <button
        type="button"
        // name={name}
        onClick={() => setIsFocused(!isFocused)}
        className={joinStrings(
          `inline-flex w-full rounded-md border shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700`,
          "focus:outline-none",
          disabled
            ? "border-transparent shadow-none cursor-default"
            : "hover:bg-gray-50",
          (() => {
            if (!disabled) {
              if (!!error && touched) {
                return "border-red-600";
              } else if (!error && !touched) {
                return "border-gray-300";
              }
            }
          })()
        )}
        disabled={disabled}
      >
        <div className="flex items-center space-x-3">
          {!isNil(textIcon) && textIcon}
          <span>{value || text}</span>
        </div>

        {!disabled && (
          <div className="ml-auto flex items-center">
            <svg
              className="-mr-1 ml-2 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                className="w-full h-auto ml-auto"
              />
            </svg>
          </div>
        )}
      </button>
      {!!error && touched && typeof error === "string" && (
        <span className="mt-0 text-red-600">{error}</span>
      )}

      {isFocused && (
        <div
          className={joinStrings(
            "absolute left-0 mt-2 w-32 min-w-full max-w-5xl rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-y-auto max-h-52 h-auto z-10",
            "transition-shadow duration-300",
            "hover:shadow-2xl",
            "lg:w-full"
          )}
        >
          <div className="py-1 cursor-pointer">
            {options?.map(({ text, key, value, children }, i) => (
              <div
                key={key}
                onClick={() => {
                  setIsFocused(false);
                  if (onSelect) {
                    onSelect({ name, value, text });
                    if (onBlur) {
                      onBlur({ name });
                    }
                  }
                }}
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 space-x-2"
              >
                {icons[i % icons.length]}
                {!!children?.length && useChildren && <span>{text}</span>}
                {!children?.length && useChildren && <span>{`- ${text}`}</span>}
                {!useChildren && <span>{text}</span>}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
