import React from "react";
import { joinStrings } from "../utils/util-methods";
import { LoadingCircle } from "./SVG/LoadingCircle";

export const CloseButton = ({
  className,
  onClick,
  children,
  loading = false,
  disabled= false
}) => {
  return (
    <button
      className={joinStrings(
        "flex items-center space-x-2 outline-none px-4 py-2.5 transition-colors duration-300 border rounded  text-white",
        "focus:outline-none",
        !loading ? "hover:bg-white hover:text-red-600" : "",
        loading
          ? "border-gray-300 bg-gray-300 cursor-default"
          : "border-red-600 bg-red-600",
        className
      )}
      onClick={onClick}
      type="button"
      disabled={loading || disabled}
    >
      {loading && (
        <>
          <span className="text-gray-600">Loading...</span> <LoadingCircle />
        </>
      )}
      {!loading && children}
    </button>
  );
};
